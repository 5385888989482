import { RolesAPI } from "src/infrastructure/api/api-roles.js";
import { fetchStartLoading } from "../loading/loadingAction";
import { fetchStopLoading } from "./../loading/loadingAction";

//#region Roles Const
export const SET_ROLES_LIST = "SET_ROLES_LIST";
export const SET_ROLES_ID = "GET_ROLES_ID";
//#endregion

//#region Roles Actions

export const setRolesList = (payload) => ({
  type: SET_ROLES_LIST,
  payload,
});

export const setRolesById = (payload) => ({
  type: SET_ROLES_ID,
  payload,
});

//#endregion

const rolesAPI = new RolesAPI();

//#region Roles Dispach

// Dispatch get roles list
export const getRolesList = (specification) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await rolesAPI.getRolesApi(specification);
    dispatch(setRolesList(response.data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get roles by id
export const getRolesById = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(setRolesById(await rolesAPI.getRolesByIdApi(id)));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion
