export const dataCategoryDetail = {
    columns: [
        {
            dataField: "id",
            text: "id",
            sort: true,
        },
        {
            dataField: "name",
            text: "denominacion",
            sort: true,
        },
        {
            dataField: "valueType",
            text: "Tipo de valor",
            sort: true,
        },
        {
            dataField: "maxValue",
            text: "Valor maximo",
            sort: true,
        },
        {
            dataField: "minValue",
            text: "Valor minimo",
            sort: true,
        },
        {
            dataField: "enable",
            text: "Habilitado",
            sort: true,
        },
        {
            dataField: "length",
            text: "Cantidad de caracteres",
            sort: true,
        }
    ]
}