import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class ProvincesAPI {
  getProvincesByCountryID = async (id) => {
    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/provinces?countryId=${id}&limit=30`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-provinces: get by id => Error: ", error);
      throw new Error(error);
    }
  };
}
