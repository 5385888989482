import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateProcessTemplateCategoryDetail } from "src/containers/processTemplateCategoryDetail/CreateProcessTemplateCategoryDetail";
import { UpdateProcessTemplateCategoryDetail } from "src/containers/processTemplateCategoryDetail/UpdateProcessTemplateCategoryDetail";

import {
  deleteProcessTemplateCategoryDetail,
  getProcessTemplateCategoryDetailById,
  getProcessTemplateCategoryDetailList,
} from "src/infrastructure/redux/store/actions/processTemplateCategoryDetail/processTemplateCategoryDetail";

import { ProcessTemplateCategoryDetail } from "src/views/pages/processTemplateCategoryDetail/ProcessTemplateCategoryDetail";
import { UpdateProcessTable } from "src/containers/processTemplateCategory/context/UpdateProcessDetailTable";

export const ListProcessTemplateCategoryDetail = ({ nameForm, categoryId }) => {
  const [modal, setModal] = React.useState(false);
  const dispatch = useDispatch();
  const { setUpdateProcess } = useContext(UpdateProcessTable);

  const processTemplateCategoryFound = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryFound
  );

  const [pTCategoryDetailId, setPTCategoryDetailId] = useState();

  const processTemplateCategoryDetailToDelete = useCallback(
    async (id) => {
      await dispatch(deleteProcessTemplateCategoryDetail(id));
      setUpdateProcess(true);
    },
    [dispatch]
  );

  const getPTCDetailListById = useCallback(
    async (id) => {
      await dispatch(getProcessTemplateCategoryDetailById(id));
    },
    [dispatch]
  );

  const processTemplateCategoryCreated = useSelector(
    (store) => store.processTemplateCategory?.processTemplateCategoryCreated
  );

  const pTCDetails = useSelector(
    (store) =>
      store.processTemplateCategory.processTemplateCategoryFound
        ?.processTemplateCategoryDetails
  );

  const loading = useSelector((store) => store.loading);

  const toggleModal = (id) => {
    if (Number.isInteger(id)) {
      setPTCategoryDetailId(id);
    } else {
      setPTCategoryDetailId(null);
    }
    setModal(!modal);
  };

  const pTCategoryDetail = React.useMemo(() => {
    if (pTCategoryDetailId) {
      const detail =
        processTemplateCategoryFound?.processTemplateCategoryDetails?.find(
          (element) => element.id === pTCategoryDetailId
        );
      return detail;
    }
    return null;
  }, [pTCategoryDetailId, processTemplateCategoryFound]);

  // useEffect(() => {
  //   if (!categoryId) return;
  //   getPTCDetailListById(categoryId);
  // }, [categoryId]);

  return (
    <>
      <ProcessTemplateCategoryDetail
        loading={loading.loading}
        processTemplateCategoryDetail={pTCDetails}
        toggleModal={toggleModal}
        processTemplateCategoryDetailToDelete={
          processTemplateCategoryDetailToDelete
        }
      />
      {!pTCategoryDetailId && (
        <CreateProcessTemplateCategoryDetail
          modal={modal}
          toggleModal={toggleModal}
          categoryCreated={processTemplateCategoryCreated}
          categoryId={categoryId}
        />
      )}
      <UpdateProcessTemplateCategoryDetail
        loading={loading.loading}
        modal={modal}
        toggleModal={toggleModal}
        pTCategoryDetail={pTCategoryDetail}
        categoryId={categoryId}
      />
    </>
  );
};
