export const dataClients = {
    columns : [
        {
            dataField: "businessName",
            text: "Razón social",
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: "Número de inscripto",
            sort: true,
        },
        {
            dataField: "address",
            text: "Domicilio",
            sort: true,
        },
        {
            dataField: "locality.name",
            text: "Localidad",
            sort: true,
        },
        {
            dataField: "type",
            text: "Tipo de establecimiento",
            sort: true,
        },
        {
            dataField: "marketType",
            text: "Tipo de mercado",
            sort: true,
        },
        {
            dataField: "observations",
            text: "Observaciones",
            sort: true,
        }
    ]
};