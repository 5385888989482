import axios from 'axios';
import { authenticatedRequest } from './authentication/authentication.js';
import { BASE_URL } from 'src/environment/env.js';

const statusTextAvailabilities = ['OK', 'Created'];

/**
 * Generic request handler
 *
 * @param requestUrl string url for request the api endpoint
 * @param options options for specify the request parameters
 * @returns the resolved promise of the request in json format
 * @throws Generic error
 */
const sdkRequest = async (requestUrl, options) => {

    let response;

    try {
        response = await (options ? axios(requestUrl, options) : axios(requestUrl));
        
        if (statusTextAvailabilities[response.statusText]) {
            return Promise.reject(response);
        }

        return response;
    
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('Token expirado, renovándolo...');
            return renewTokenAndRetryRequest(error);
        }
        return Promise.reject(error.response.data)

    }
};

const renewTokenAndRetryRequest = async (error) => {
    const requestUrl = `${BASE_URL}/users/refresh-token`;

    const response = axios.post(requestUrl)
        .then(response => {
            const newAccessToken = response.data.token;
            error.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(error.response.config.url, error.response.config);
        })
        .catch(error => {
            console.error('Error al renovar el token:', error);
            if (statusTextAvailabilities[response.statusText]) {
                return Promise.reject(response);
            }
        });

  }

const getTokenFromCookie = async() => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('refresh-token=')) {
        return cookie.split('=')[1];
        }
    }
    return null;
}

export const sdkNoAuthRequest = sdkRequest;

export const sdkAuthRequest = async (requestUrl, options) => sdkRequest(requestUrl, await authenticatedRequest(options));
