import { ProviderAPI } from "src/infrastructure/api/api-provider";
import { fetchStopLoading, fetchStartLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region Provider Const
export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const SET_PROVIDER_LIST = "SET_PROVIDER_LIST";
export const SET_PROVIDER_FOUND = "SET_PROVIDER_FOUND";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const PROVIDER_ERRORS = "PROVIDER_ERRORS";
//#endregion

//#region Provider Actions

// Create Provider
export const fetchCreateProvider = (response) => ({
  type: CREATE_PROVIDER,
  payload: response.data,
});

// Get list of providers
export const setProviderList = (response) => {
  return {
    type: SET_PROVIDER_LIST,
    payload: response,
  };
};

export const setProviderFound = (response) => ({
  type: SET_PROVIDER_FOUND,
  payload: response,
});

export const fetchUpdateProvider = (response) => ({
  type: UPDATE_PROVIDER,
  payload: response,
});

export const fetchDeleteProvider = (response) => ({
  type: DELETE_PROVIDER,
  payload: response,
});

//#endregion

const providerAPI = new ProviderAPI();

//#region Providers Dispatch

// Dispatch create provider
export const createProvider = (providerBody) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchCreateProvider(await providerAPI.createProviderApi(providerBody))
    );
  } catch (error) {
    dispatch(setErrorMessages(PROVIDER_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get provider list
export const getProviderList = (specification) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading())
    const response = await providerAPI.getProvidersApi(specification);
    dispatch(setProviderList(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PROVIDER_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProviderList = () => async (dispatch) => {
  dispatch(setProviderList(null));
};

// Dispatch get provider by id
export const getProviderById = (id, expand) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await providerAPI.getProviderByIdApi(id, expand);
    dispatch(setProviderFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PROVIDER_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProviderFound = () => async (dispatch) => {
  dispatch(setProviderFound(null));
};

// Dispatch update provider
export const updateProvider = (body, id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchUpdateProvider(await providerAPI.updateProviderApi(body, id))
    );
  } catch (error) {
    dispatch(setErrorMessages(PROVIDER_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch delete provider
export const deleteProvider = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchDeleteProvider(await providerAPI.deleteProviderApi(id)));
  } catch (error) {
    dispatch(setErrorMessages(PROVIDER_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion
