import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataProductType } from "src/variables/productType";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
};
export const ProductType = ({
  loading,
  getProductType,
  deleteProductType,
  resetErrors,
  productTypesList,
  createdProductTypes,
  foundProductTypes,
  updatedProductTypes,
  deletedProductTypes,
  productTypesErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(
    productTypesList?.totalCount / queryParams.limit
  );
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getProductType(queryParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    createdProductTypes,
    foundProductTypes,
    updatedProductTypes,
    deletedProductTypes,
    queryParams,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    productTypesErrors ? setAlert(true) : setAlert(false);
  }, [productTypesErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={productTypesErrors} state={alert} />
      <SimpleHeader
        name="Tipo de producto"
        parentName="Admin"
        create="create"
      />
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Tipo de producto"
            dataTable={productTypesList?.data ?? []}
            columnsTable={dataProductType.columns}
            deleteElement={deleteProductType}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <ProductTypeFilters
                denomination={queryParams.denomination}
                onDenominationChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    denomination: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const ProductTypeFilters = ({ denomination, onDenominationChange }) => {
  return (
    <>
      <input
        className="col-3 ml-4 form-control"
        placeholder="Denominación"
        type="denomination"
        value={denomination ?? ""}
        onChange={onDenominationChange}
      />
    </>
  );
};
