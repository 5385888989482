import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "src/views/pages/clients/Client";
import { getClientList } from "src/infrastructure/redux/store/actions/client/clientAction";
import { deleteClient } from "src/infrastructure/redux/store/actions/client/clientAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";

export const ListClient = () => {
  const dispatch = useDispatch();

  const getClients = useCallback(
    async (specification) => {
      await dispatch(getClientList(specification));
    },
    [dispatch]
  );

  const clientToDelete = useCallback(
    async (id) => {
      await dispatch(deleteClient(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("CLIENT_ERRORS"));
  }, [dispatch]);

  const clientsList = useSelector((store) => store.client.clientList);
  const deletedClient = useSelector((store) => store.client.clientDeleted);
  const updatedClient = useSelector((store) => store.client.clientUpdated);
  const createdClient = useSelector((store) => store.client.clientCreated);
  const foundClient = useSelector((store) => store.client.clientFound);
  const loading = useSelector((store) => store.loading.loading);

  const clientsErrors = useSelector((store) => store.errors.clientErrors);

  const types = {
    1: "Bodega",
    2: "Viñedo",
    3: "Error",
  };

  const finalClientList = clientsList?.data?.map((client) => {
    return {
      ...client,
      type: types[client.type],
    };
  });

  return (
    <Client
      getClients={getClients}
      deleteClients={clientToDelete}
      resetErrors={resetErrors}
      loading={loading}
      clientsList={{ ...clientsList, data: finalClientList }}
      deletedClient={deletedClient}
      updatedClient={updatedClient}
      createdClient={createdClient}
      foundClient={foundClient}
      clientsErrors={clientsErrors}
    />
  );
};
