import React from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProductTypeForm } from "src/views/pages/productType/ProductTypeForm";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProductTypeCreate = ({ create, loading }) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader name="Tipo de producto" parentName="Admin"></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        <h2>{"Agregar Tipo de producto"}</h2>
        {loading ? (
          <LoadingComponent />
        ) : (
          <ProductTypeForm
            elementData={null}
            nameForm={"create"}
            id={null}
            create={create}
            update={null}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
