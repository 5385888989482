import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UnitOfMeasurement } from 'src/views/pages/unitOfMeasurement/UnitOfMeasurement'
import { getUnitOfMeasurementList } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';
import { deleteUnitOfMeasurement } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';
import { resetErrorMessages } from 'src/infrastructure/redux/store/actions/errors/errorsAction';

export const ListUnitOfMeasurement = () => {
    const dispatch = useDispatch();

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const unitOfMeasurementToDelete = useCallback(async (id) => {
        await dispatch(deleteUnitOfMeasurement(id));
    }, [dispatch]);

    const resetErrors = useCallback(async () => {
        await dispatch(resetErrorMessages('UNIT_OF_MOVEMENT_ERRORS'));
    }, [dispatch]);

    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)
    const unitOfMeasurementsCreated = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementCreated)
    const unitOfMeasurementsUpdated = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementUpdated)
    const unitOfMeasurementsDeleted = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementDeleted)
    const unitOfMeasurementsFound = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementFound)
    const unitOfMeasurementsErrors = useSelector(store => store.errors.UnitOfMeasurementErrors)
    const loading = useSelector(store => store.loading)

    return <UnitOfMeasurement
        getUnitOfMeasurement={getUnitOfMeasurement}
        deleteUnitOfMeasurement={unitOfMeasurementToDelete}
        resetErrors={resetErrors}
        loading={loading.loading}
        unitOfMeasurementsList={unitOfMeasurementsList}
        unitOfMeasurementsCreated={unitOfMeasurementsCreated}
        unitOfMeasurementsUpdated={unitOfMeasurementsUpdated}
        unitOfMeasurementsDeleted={unitOfMeasurementsDeleted}
        unitOfMeasurementsFound={unitOfMeasurementsFound}
        unitOfMeasurementsErrors={unitOfMeasurementsErrors}
    />
}
