import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ProviderType } from 'src/views/pages/providerType/ProviderType'
import { deleteProviderType, getProviderTypeList } from 'src/infrastructure/redux/store/actions/providerType/providerTypeAction';
import { resetErrorMessages } from 'src/infrastructure/redux/store/actions/errors/errorsAction';

export const ListProviderType = () => {
    const dispatch = useDispatch();

    const getProviderType = useCallback(async (specification) => {
        await dispatch(getProviderTypeList(specification));
    }, [dispatch]);

    const providerTypeToDelete = useCallback(async (id) => {
        await dispatch(deleteProviderType(id));
    }, [dispatch]);

    const resetErrors = useCallback(async () => {
        await dispatch(resetErrorMessages('PROVIDER_TYPE_ERRORS'));
    }, [dispatch]);

    const providerTypeList = useSelector(store => store.providerType.providerTypeList)
    const providerTypeCreated = useSelector(store => store.providerType.providerTypeCreated)
    const providerTypeUpdated = useSelector(store => store.providerType.providerTypeUpdated)
    const providerTypeDeleted = useSelector(store => store.providerType.providerTypeDeleted)
    const providerTypeFound = useSelector(store => store.providerType.providerTypeFound)
    const providerTypeErrors = useSelector(store => store.errors.providerTypeErrors)
    const loading = useSelector(store => store.loading.loading)

    return <ProviderType
        getProviderType={getProviderType}
        deleteProviderType={providerTypeToDelete}
        resetErrors={resetErrors}
        loading={loading}
        providerTypeList={providerTypeList}
        providerTypeCreated={providerTypeCreated}
        providerTypeUpdated={providerTypeUpdated}
        providerTypeDeleted={providerTypeDeleted}
        providerTypeFound={providerTypeFound}
        providerTypeErrors={providerTypeErrors}
    />
}