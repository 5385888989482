import React, { useContext } from "react";
import { Button, FormGroup, Form, Input, Modal } from "reactstrap";
import { useFormik } from "formik";
import { UpdateProcessTable } from "src/containers/processTemplateCategory/context/UpdateProcessDetailTable";

export const CategoryDetailModal = ({
  loading,
  create,
  update,
  elementData,
  modalState,
  toggleModal,
  nameForm,
  unitOfMeasurementModal,
  processTemplateCategoryId,
}) => {
  const { setUpdateProcess } = useContext(UpdateProcessTable);

  const formik = useFormik({
    initialValues: {
      name: elementData?.name ?? null,
      processTemplateCategoryId: processTemplateCategoryId,
      unitOfMeasurementId: elementData?.unitOfMeasurementId ?? null,
      valueType: elementData?.valueType ?? null,
      maxValue: elementData?.maxValue ?? undefined,
      minValue: elementData?.minValue ?? undefined,
      length: elementData?.length ?? null,
      enable: elementData?.enable ?? false,
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        toggleModal();
        setUpdateProcess(true);
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(elementData.id, values);
        /*  setChange(true)   */
        toggleModal();
        setUpdateProcess(true);
      }
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={modalState}
      toggle={() => toggleModal()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Detalle de categoria
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      {!loading && (
        <div className="modal-body">
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label htmlFor="name">Denominacion</label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name ?? ""}
                required={!elementData ? true : false}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="unitOfMeasurementId">Unidad de medida</label>
              <Input
                id="unitOfMeasurementId"
                name="unitOfMeasurementId"
                type="select"
                onChange={formik.handleChange}
                defaultValue={
                  !elementData
                    ? formik.values.unitOfMeasurementId
                    : elementData.unitOfMeasurementId
                }
              >
                {!elementData && <option value={""}>Seleccionar</option>}
                {unitOfMeasurementModal?.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <label htmlFor="valueType">Tipo de valor</label>
              <Input
                type="select"
                id="valueType"
                name="valueType"
                onChange={formik.handleChange}
                defaultValue={
                  !elementData ? formik.values.valueType : elementData.valueType
                }
                required={!elementData ? true : false}
              >
                {!elementData && <option value={""}>Seleccionar</option>}
                <option value={"Alfanumerico"}>Alfanumerico</option>
                <option value={"Entero"}>Entero</option>
                <option value={"Fecha"}>Fecha</option>
                <option value={"Decimal"}>Decimal</option>
                <option value={"Booleano"}>Booleano</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <label htmlFor="maxValue">Máximo valor</label>
              <Input
                id="maxValue"
                name="maxValue"
                type="number"
                step={0.01}
                onChange={formik.handleChange}
                value={formik.values.maxValue ?? ""}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="minValue">Minimo valor</label>
              <Input
                id="minValue"
                name="minValue"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.minValue ?? ""}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="length">Cantidad de caracteres</label>
              <Input
                id="length"
                name="length"
                type="number"
                min={0}
                onChange={formik.handleChange}
                value={formik.values.length ?? ""}
              />
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-checkbox mb-3">
                <Input
                  type="checkbox"
                  name="enable"
                  onChange={formik.handleChange}
                  value={formik.values.enable}
                  defaultChecked={formik.values.enable}
                />
                <label htmlFor="enable">Habilitado</label>
              </div>
            </FormGroup>
            <Button classID="btn-icon btn-3" color="primary" type="submit">
              <span classID="btn-inner--text">Guardar</span>
            </Button>
            <Button
              classID="btn-icon btn-3"
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal()}
            >
              <span classID="btn-inner--text">Cancelar</span>
            </Button>
          </Form>
        </div>
      )}
    </Modal>
  );
};
