import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { StorageUnitForm } from "./StorageUnitForm";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const StorageUnitUpdate = ({
  StorageUnitById,
  StorageUnitFound,
  update,
  getUnitOfMeasurement,
  unitOfMeasurementsList,
  loading,
}) => {
  const { id } = useParams();

  useEffect(() => {
    StorageUnitById(id);
    getUnitOfMeasurement();
  }, [id, StorageUnitById]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Unidad de almacenamiento" parentName="Admin" />
      <Container fluid>
        <h2>{"Editar unidad de almacenamiento"}</h2>
        {loading ? (
          <LoadingComponent />
        ) : (
          <StorageUnitForm
            elementData={StorageUnitFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
            unitOfMeasurements={unitOfMeasurementsList}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
