import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createProviderType } from 'src/infrastructure/redux/store/actions/providerType/providerTypeAction';
import { ProviderTypeCreate } from 'src/views/pages/providerType/ProviderTypeCreate';


export const CreateProviderType = () => {
    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createProviderType(body));
    }, [dispatch]);

    const providerTypeCreated = useSelector(store => store.providerType.providerTypeCreated)

    return <ProviderTypeCreate
        create={create}
        providerTypeCreated={providerTypeCreated}
    />
}