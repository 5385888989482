import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class LocalityAPI {

    getLocalitiesApi = async (specification = {}) => {
        try {
            let url = new URL(`${BASE_URL}/localities`);
            Object.entries(specification).forEach(([key, value]) => {
                url.searchParams.append(key, value);
            })

            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url.toString(), options)
            return response
        } catch (error) {
            console.log('api-locality: get all => Error: ', error)
            throw new Error(error)
        }
    }

    getLocalityByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/localities?provinceId=${id}&limit=200`, options)

            return response

        } catch (error) {
            console.log('api-locality: get by id => Error: ', error)
            throw new Error(error)
        }
    }

}


