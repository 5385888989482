// nodejs library to set properties for components
import { go } from "connected-react-router";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";

const SimpleHeader = ({ name, parentName, subComponent, create }) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    navigate(create);
  };

  return (
    <header className="header header-dark bg-sencondary content__title content__title--calendar">
      <Container fluid>
        <header className="header-body align-items-center pt-4">
          <h6 className="fullcalendar-title h2 text-dark d-inline-block mb-0">
            {name}
          </h6>{" "}
          <Breadcrumb
            className="d-none d-md-inline-block ml-lg-4"
            listClassName="breadcrumb-links breadcrumb-light"
          >
            <BreadcrumbItem>
              <a href="#" onClick={(e) => e.preventDefault()}>
                <i className="fas fa-home" />
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="#" onClick={(e) => e.preventDefault()}>
                {parentName}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem aria-current="page" className="active">
              {name}
            </BreadcrumbItem>
          </Breadcrumb>
          {(!!subComponent || !!create) && (
            <footer className="py-4">
              {subComponent && subComponent}
              {!subComponent && create && (
                <Button
                  className="btn-icon btn-3"
                  color="primary"
                  type="button"
                  onClick={() => goToCreate()}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-plus" />
                  </span>
                  <span className="btn-inner--text">Agregar</span>
                </Button>
              )}
            </footer>
          )}
        </header>
      </Container>
    </header>
  );
};

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default SimpleHeader;
