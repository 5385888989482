/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataMovements } from "src/variables/movements";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

let offset = 0;
const limit = 10;

const initQueryParams = {
  offset,
  limit,
  expand: 'sourceStorageUnit, destinationStorageUnit'
};

export const Movements = ({
  loading,
  getMovements,
  resetErrors,
  deleteMovements,
  approveMovements,
  movementsList,
  createdMovements,
  foundMovements,
  updatedMovements,
  deletedMovements,
  approvedMovements,
  movementsErrors,
}) => {
  const [alert, setAlert] = useState();
  const totalPages = Math.ceil(movementsList?.totalCount / limit);

  const metadata = {
    totalPages,
    page: offset / limit + 1,
  };

  const onPageChange = (newPage) => {
    offset = ((newPage || 1) - 1) * limit;
    getMovements({
      offset,
      limit,
    });
  };

  useEffect(() => {
    getMovements(initQueryParams);
  }, [
    deletedMovements,
    updatedMovements,
    createdMovements,
    foundMovements,
    approvedMovements,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    movementsErrors ? setAlert(true) : setAlert(false);
  }, [movementsErrors]);

  useEffect(() => () => (offset = 0), []);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={movementsErrors} state={alert} />
      <SimpleHeader
        //name="Movimientos de unidades de almacenamiento"
        name="Movimientos"
        parentName="Admin"
        create="create"
      />
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Movimientos"
            dataTable={movementsList?.data ?? []}
            columnsTable={dataMovements.columns}
            deleteElement={deleteMovements}
            approve={"approve"}
            approveElement={approveMovements}
            metadata={metadata}
            onPageChange={onPageChange}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
