import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createBatchNumbers } from "src/infrastructure/redux/store/actions/batchNumbers/batchNumbersAction";
import { BatchNumbersCreate } from "src/views/pages/batchNumbers/BatchNumbersCreate";

export const CreateBatchNumber = () => {
  const dispatch = useDispatch();

  const create = useCallback(
    async (body) => {
      await dispatch(createBatchNumbers(body));
    },
    [dispatch]
  );

  return <BatchNumbersCreate create={create} />;
};
