import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import { CrudTable } from "../tables/CrudTable";
import { StorageUnitAPI } from "src/infrastructure/api/api-storageUnit";
import { dataStorageUnitMovement } from "src/variables/storageUnitMovement";
import moment from "moment";

const { getStorageUnitMovementsByIdApi } = new StorageUnitAPI();

let offset = 0;
const limit = 10;

const initQueryParams = {
  offset,
  limit,
};

export const StorageUnitMovements = ({ storageUnitId }) => {
  const navigate = useNavigate();
  const [storageUnitMovements, setStorageUnitMovements] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const totalPages = Math.ceil(storageUnitMovements?.totalCount / limit);

  const metadata = {
    totalPages,
    page: offset / limit + 1,
  };

  const onPageChange = (newPage) => {
    offset = ((newPage || 1) - 1) * limit;
    getStorageUnitMovementsByIdApi(storageUnitId, {
      offset,
      limit,
    });
  };

  const handleGetStorageUnitMovements = (id) => {
    setIsLoading(true);

    getStorageUnitMovementsByIdApi(id, initQueryParams)
      .then(({ data }) => {
        const formatted = data?.data?.map((item) => ({
          ...item,
          date: moment(item.date).format("DD-MM-YYYY"),
        }));

        setStorageUnitMovements({
          ...data,
          data: formatted,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    navigate("/admin/storage-units");
  };

  useEffect(() => {
    if (!storageUnitId) return;
    handleGetStorageUnitMovements(storageUnitId);
  }, [storageUnitId]);

  useEffect(() => () => (offset = 0), []);

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <div style={{ height: "500px" }}>
        <CrudTable
          name="Movimientos en Unidad de Almacenammiento"
          dataTable={storageUnitMovements?.data ?? []}
          columnsTable={dataStorageUnitMovement.columns}
          metadata={metadata}
          onPageChange={onPageChange}
        />
      </div>
      <Button
        onClick={handleClose}
        classID="btn-icon btn-3"
        className="align-self-start mt-3"
        color="primary"
        type="submit"
      >
        <span classID="btn-inner--text">Cerrar</span>
      </Button>
    </>
  );
};
