import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";

export const UnitOfMeasurementForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
}) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: elementData?.name ?? "",
      abbreviation: elementData?.abbreviation ?? "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        navigate("/admin/unit-of-measurement");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/unit-of-measurement");
      }
    },
  });

  
  const cancelForm = () => {
    navigate("/admin/unit-of-measurement");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="name">Denominación</label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              required={!elementData}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="abbreviation">Abreviación</label>
            <Input
              id="abbreviation"
              name="abbreviation"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.abbreviation ?? "Mg"}
              required={!elementData}
            />
          </FormGroup>
          <Button classID="btn-icon btn-3" color="primary" type="submit">
            <span classID="btn-inner--text">Guardar</span>
          </Button>
          <Button
            classID="btn-icon btn-3"
            color="warning"
            type="button"
            onClick={(e) => cancelForm(e)}
          >
            <span classID="btn-inner--text">Cancelar</span>
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
