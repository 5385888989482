import { ConfigurationAPI } from "src/infrastructure/api/api-configuration";

export const CONFIGURATION = "CONFIGURATION";
export const IS_LOADING_CONFIGURATION = "IS_LOADING_CONFIGURATION";
export const IS_LOADING_CONFIGURATION_UPDATE =
  "IS_LOADING_CONFIGURATION_UPDATE";

export const setConfiguration = (payload) => ({
  type: CONFIGURATION,
  payload,
});

export const setIsLoadingConfiguration = (payload) => ({
  type: IS_LOADING_CONFIGURATION,
  payload,
});

export const setIsLoadingConfigurationUpdate = (payload) => ({
  type: IS_LOADING_CONFIGURATION_UPDATE,
  payload,
});

export const fetchGetConfiguration = () => async (dispatch) => {
  try {
    dispatch(setIsLoadingConfiguration(true));
    const api = new ConfigurationAPI();
    const response = await api.getConfigurationApi();
    dispatch(setConfiguration(response.data));
  } catch (error) {
    console.log("configurationAction => Error: ", error);
    throw error;
  } finally {
    dispatch(setIsLoadingConfiguration(false));
  }
};

export const fetchUpdateConfiguration = (data) => async (dispatch) => {
  try {
    dispatch(setIsLoadingConfigurationUpdate(true));
    const api = new ConfigurationAPI();
    await api.updateConfigurationApi(data);
  } catch (error) {
    console.log("configurationAction => Error: ", error);
    throw error;
  } finally {
    dispatch(setIsLoadingConfigurationUpdate(false));
  }
};
