import React from 'react'

export const RecentActivities = () => {

    return (
        <>
            <div className="card main-table">
                <div className="table-responsive">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-xs font-weight-bolder ">Actividades Recientes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p className="text-xs font-weight-bold mb-0">Clientes</p>
                                    <p className="text-xs mb-0">Crear</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="text-xs font-weight-bold mb-0">Clientes</p>
                                    <p className="text-xs mb-0">Editar</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="text-xs font-weight-bold mb-0">Clientes</p>
                                    <p className="text-xs mb-0">Borrar</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}