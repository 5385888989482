import React from "react";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { ButtonGroup, Card } from "reactstrap";
import { usePagination } from "./usePaginationCustom";

export const CrudTable = ({
  name,
  dataTable,
  columnsTable,
  deleteElement,
  toggleModal,
  approveElement,
  approve,
  onPageChange,
  metadata,
  filtersComponent,
}) => {
  const navigate = useNavigate();

  const { ComponentPagination } = usePagination({
    totalPages: metadata?.totalPages,
    page: metadata?.page,
    onPageChange,
  });

  const handleDelete = (id) => {
    if (window.confirm("¿Eliminar elemento?")) {
      deleteElement(id);
    }
  };

  const handleApprove = (id) => {
    if (window.confirm("¿Aprobar?")) {
      approveElement(id);
    }
  };

  const handleUpdate = (id) => {
    switch (name) {
      case "Detalle de categoria":
        toggleModal(id);
        break;
      case "Detalles de proceso":
        toggleModal(id);
        break;
      default:
        navigate(`edit/${id}`);
        break;
    }
  };

  const handleViewDetail = (id) => {
    console.log("ver");
    if (name === "Detalle de categoria") {
      toggleModal(id);
    } else {
      navigate(`view/${id}`);
    }
  };

  const approveButtons = {
    dataField: "actions",
    text: "Acciones",
    formatter: (cellContent, row) => {
      return (
        <ButtonGroup>
          {row.status === 1 ? (
            <>
              <button
                className="btn btn-success btn-xs mr-1"
                onClick={() => handleApprove(row.id)}
              >
                Aprobar
              </button>
              {"  "}
              <button
                className="btn btn-info btn-xs mr-1"
                onClick={() => handleUpdate(row.id)}
              >
                Editar
              </button>
              {"  "}
              <button
                className="btn btn-danger btn-xs mr-1"
                onClick={() => handleDelete(row.id)}
              >
                Eliminar
              </button>
            </>
          ) : (
            // <button
            //   className="btn btn-info btn-xs mr-1"
            //   onClick={() => handleViewDetail(row.id)}
            // >
            //   Ver
            // </button>
            <button
              className="btn btn-secondary btn-xs mr-1"
              onClick={() => handleViewDetail(row.id)}
            >
              Ver
            </button>
          )}
        </ButtonGroup>
      );
    },
  };

  const actions = {
    dataField: "actions",
    text: "Acciones",
    formatter: (cellContent, row) => {
      const urlParts = window.location?.href?.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      const hasDetailPage =
        lastPart === "storage-units" || lastPart === "traceability-reports";

      return (
        <ButtonGroup>
          <>
            {/* //TODO: boton detalle a nueva pantalla con la misma info pero sin edicion */}
            {hasDetailPage && (
              <button
                className="btn btn-secondary btn-xs mr-3"
                onClick={() => navigate(`${row.id}`)}
              >
                Detalle
              </button>
            )}
            <button
              className="btn btn-info btn-xs mr-3"
              onClick={() => handleUpdate(row.id)}
            >
              Editar
            </button>
            {""}
            <button
              className="btn btn-danger btn-xs"
              onClick={() => handleDelete(row.id)}
            >
              Eliminar
            </button>
          </>
        </ButtonGroup>
      );
    },
  };

  if (
    columnsTable.slice(-1)[0].dataField !== "actions" &&
    approve === "approve"
  ) {
    columnsTable.push(approveButtons);
  }
  if (columnsTable.slice(-1)[0].dataField !== "actions") {
    columnsTable.push(actions);
  }

  return (
    <section className="h-100">
      <Card className="d-flex flex-column h-100 m-0">
        <header className="m-4">
          <h3 className="mb-0">Listado de {name} </h3>
        </header>
        {filtersComponent && (
          <section
            id="datatable-basic_filter"
            className="pb-4 d-flex align-items-center"
          >
            {filtersComponent}
          </section>
        )}
        <section
          className="flex-grow-1 overflow-auto w-100"
          style={{
            height: "0px",
          }}
        >
          <BootstrapTable
            className
            keyField="id"
            bootstrap4={true}
            bordered={false}
            data={dataTable.map((d) => ({ ...d, handleUpdate, handleDelete }))}
            columns={columnsTable}
          />
        </section>
        {ComponentPagination() && (
          <footer className="py-4">
            <ComponentPagination />
          </footer>
        )}
      </Card>
    </section>
  );
};
