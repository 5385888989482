import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { UnitOfMeasurementForm } from "src/views/pages/unitOfMeasurement/UnitOfMeasurementForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const UnitOfMeasurementUpdate = ({
  UnitOfMeasurementById,
  UnitOfMeasurementFound,
  update,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    UnitOfMeasurementById(id);
    setLoading(false);
  }, [id, UnitOfMeasurementById]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Unidad de medida" parentName="Admin"></SimpleHeader>
      {!loading && (
        <Container fluid>
          <h2>{"Editar Unidad de medida"}</h2>
          <UnitOfMeasurementForm
            elementData={UnitOfMeasurementFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
