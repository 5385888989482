import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export const getBatchNumbersApi = async (specification = {}) => {
  try {
    let url = new URL(`${BASE_URL}/batch-numbers`);
    url.searchParams.append("deletedAt", "null");
    Object.entries(specification).forEach(([key, value]) => {
      if (!value) return;
      url.searchParams.append(key, value);
    });

    const options = {
      method: "GET",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
    };

    const response = await sdkAuthRequest(url.toString(), options);

    return response;
  } catch (error) {
    console.log("api-BatchNumber: get all => Error: ", error);
    throw error;
  }
};

export const getBatchNumberByIdApi = async (id) => {
  try {
    const options = {
      method: "GET",
      headers: {
        ...headers,
        //
        authorization: "Bearer " + getUserToken(),
      },
    };

    const response = await sdkAuthRequest(
      `${BASE_URL}/batch-numbers/${id}`,
      options
    );

    return response;
  } catch (error) {
    console.log("api-batch-numbers:18 => Error: ", error);
    throw error;
  }
};

export const updateBatchNumberApi = async (id, body) => {
  try {
    const options = {
      method: "PUT",
      headers: {
        ...headers,
        //
        authorization: "Bearer " + getUserToken(),
      },
      data: body,
    };

    const response = await sdkAuthRequest(
      `${BASE_URL}/batch-numbers/${id}`,
      options
    );

    return response;
  } catch (error) {
    console.log("api-batch-numbers:42 => Error: ", error);
    throw error;
  }
};

export const createBatchNumbersApi = async (body) => {
  try {
    const options = {
      method: "POST",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
      data: body,
    };

    const response = await sdkAuthRequest(`${BASE_URL}/batch-numbers`, options);

    return response;
  } catch (error) {
    console.log("api-batch-numbers:18 => Error: ", error);
    throw error;
  }
};

export const deleteBatchNumberApi = async (id) => {
  try {
    const options = {
      method: "DELETE",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
    };

    const response = await sdkAuthRequest(
      `${BASE_URL}/batch-numbers/${id}`,
      options
    );

    return response;
  } catch (error) {
    console.log("api-batch-numbers:42 => Error: ", error);
    throw error;
  }
};
