import {configureStore} from '@reduxjs/toolkit'

export const buildStore = (reducers, history) => configureStore(
    {
        reducer: reducers(history),
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    }
);
