import { CREATE_LOCALITY, GET_LOCALITY_LIST, GET_LOCALITY_ID, UPDATE_LOCALITY, DELETE_LOCALITY } from "../../actions/locality/localityAction"


const initialState = {
    localityCreated: null,
    localityList: [],
    localityFound: null,
    localityUpdated: null,
    localityDeleted: null
}

export const localityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_LOCALITY:
            return { ...state, localityCreated: payload }
        case GET_LOCALITY_LIST:
            return { ...state, localityList: payload.data }
        case GET_LOCALITY_ID:
            return { ...state, localityFound: payload.data }
        case UPDATE_LOCALITY:
            return { ...state, localityUpdated: payload }
        case DELETE_LOCALITY:
            return { ...state, localityDeleted: payload }
        default:
            return state
    }
}