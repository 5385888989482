export const dataUnitOfMeasurement = {
    columns : [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "name",
            text: "Denominación",
            sort: true,
        },
        {
            dataField: "abbreviation",
            text: "Abreviación",
            sort: true,
        }
    ]
};