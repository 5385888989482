import React, { useEffect, useState } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProductsForm } from "./ProductsForm";
import AdminFooter from "src/components/Footers/AdminFooter";
import { useDispatch, useSelector } from "react-redux";
import { getBatchNumbersList } from "src/infrastructure/redux/store/actions/batchNumbers/batchNumbersAction";

export const ProductsCreate = ({
  create,
  getProductsType,
  productTypesList,
  getUnitOfMeasurement,
  unitOfMeasurementsList,
}) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      getProductsType();
      getUnitOfMeasurement();
      if (
        productTypesList?.data?.length > 0 &&
        unitOfMeasurementsList?.data?.length > 0
      ) {
        setLoading(false);
      }
    }
  }, [
    getProductsType,
    getUnitOfMeasurement,
    productTypesList,
    unitOfMeasurementsList,
    loading,
  ]);

  const batchNumbersList = useSelector(
    (state) => state.batchNumbers.batchNumbersList
  );

  const handleGetBatchNumbersList = () => {
    dispatch(
      getBatchNumbersList({
        limit: 1000,
      })
    );
  };

  const cleanBatchNumberList = () => {
    dispatch(cleanBatchNumberList());
  };

  useEffect(() => {
    handleGetBatchNumbersList();
    return cleanBatchNumberList;
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Productos" parentName="Admin"></SimpleHeader>
      <Container fluid>
        <h2>{"Crear Producto"}</h2>
        {!loading && (
          <ProductsForm
            elementData={null}
            nameForm={"create"}
            id={null}
            create={create}
            update={null}
            batchNumbers={batchNumbersList?.data ?? []}
            productTypes={
              productTypesList?.data?.filter(
                (element) => element.deletedAt === null
              ) ?? []
            }
            unitOfMeasurement={
              unitOfMeasurementsList?.data?.filter(
                (element) => element.deletedAt === null
              ) ?? []
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
