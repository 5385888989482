import { UsersAPI } from "src/infrastructure/api/api-users.js";
import { fetchStartLoading } from "../loading/loadingAction";
import { fetchStopLoading } from './../loading/loadingAction';
import { setErrorMessages } from "../errors/errorsAction";

//#region Users Const
export const CREATE_USER = 'CREATE_USER'
export const GET_USER_LIST = 'GET_USER_LIST'
export const GET_USER_ID = 'GET_USER_ID'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const USER_ERRORS = 'USER_ERRORS'
//#endregion

//#region Users Actions

export const fetchCreateUsers = response => ({
    type: CREATE_USER,
    payload: response.data
})

export const fetchGetUsersList = response => ({
    type: GET_USER_LIST,
    payload: response.data
})

export const fetchUsersById = response => ({
    type: GET_USER_ID,
    payload: response
})

export const fetchUpdateUsers = response => ({
    type: UPDATE_USER,
    payload: response
})

export const fetchDeleteUsers = response => ({
    type: DELETE_USER,
    payload: response
})

//#endregion

const usersAPI = new UsersAPI()

//#region Users Dispach

// Dispatch create users
export const createUsers = (usersBody) => async (dispatch) => {
    try {
        dispatch(fetchCreateUsers(await usersAPI.createUsersApi(usersBody)));
    } catch (error) {
        // email in use alert
        dispatch(setErrorMessages(USER_ERRORS, error))
    } finally {
        console.log('finally')
    }
}

// Dispatch get users list
export const getUsersList = (specification) => async (dispatch) => {
    try {
        // dispatch(fetchStartLoading())
        dispatch(fetchGetUsersList(await usersAPI.getUsersApi(specification)))
    } catch (error) {
        dispatch(setErrorMessages(USER_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get users by id
export const getUsersById = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchUsersById(await usersAPI.getUsersByIdApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(USER_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update users
export const updateUsers = (id, body) => async (dispatch) => {
    try {
        dispatch(fetchUpdateUsers(await usersAPI.updateUsersApi(id, body)))
    } catch (error) {
        dispatch(setErrorMessages(USER_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete users
export const deleteUsers = (id) => async (dispatch) => {
    try {
        dispatch(fetchDeleteUsers(await usersAPI.deleteUsersApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(USER_ERRORS, error))
    }
}
//#endregion
