import { AUTHENTICATION_URL } from "src/environment/env";
import { sdkNoAuthRequest } from "src/infrastructure/request";
import { isUserLoggedin } from "src/infrastructure/request/utils/request.helpers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";
import { storeAccessToken } from "src/infrastructure/storage/localStorage";
import { headers } from 'src/infrastructure/request/utils/headers';
import { createProfile } from 'src/infrastructure/redux/store/actions/profile/profileAction';

export class AuthAPI {

    /**
     * Login an user and store its token data in LS
     * @param credentials  username & passowrd
     */

    LoginUser = async (credentials, dispatch) => {

        const userToken = getUserToken();

        if (userToken && isUserLoggedin(userToken) === 'OK') {
            return Promise.resolve(userToken);
        }

        try {
            const rawAccessToken = await sdkNoAuthRequest(
                `${AUTHENTICATION_URL}`,
                {
                    method: 'POST',
                    headers: headers,
                    data: credentials
                }
            );

            dispatch(createProfile(rawAccessToken))

            return Promise.resolve(storeAccessToken(rawAccessToken));

        } catch (error) {
            return Promise.reject(error);
        }
    };

    ForgotPasswordApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: headers,
                data: body
            }

            const response = await sdkNoAuthRequest(`${AUTHENTICATION_URL}/password-recovery`, options)

            return response

        } catch (error) {
            console.log('api-client:forgotPassword => Error: ', error)
            throw new Error(error)
        }
    }

    PasswordRecoveryAuthApi = async (body) => {
        try {
            const options = {
                method: 'PUT',
                headers: headers,
                data: body
            }

            const response = await sdkNoAuthRequest(`${AUTHENTICATION_URL}/change-password`, options)

            return response

        } catch (error) {
            console.log('api-client:passwordRecovery => Error: ', error)
            throw new Error(error)
        }
    }
}