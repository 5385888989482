import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Modal
} from "reactstrap";
import { useFormik } from 'formik'

export const PasswordRecoveryModal = ({ modalState, toggleModal, recovery }) => {
    
    const formik = useFormik({
        initialValues : {
            "email" : '',
        },
        onSubmit: values => {
            recovery(values)
            toggleModal()
        }
    })
    return(
        <Modal 
            className="modal-dialog-centered"
            isOpen={modalState}
            toggle={ () => toggleModal()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="ModalLabel">
                    Recuperar contraseña
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Form onSubmit={formik.handleSubmit}>
                    <FormGroup>
                        <label htmlFor="email">Email</label> 
                        <Input id="email" name='email' type="email" required onChange={formik.handleChange} value={formik.values.email} />
                    </FormGroup>
                    <Button classID="btn-icon btn-3" color="primary" type="submit">
                        <span classID="btn-inner--text">Recuperar</span>
                    </Button>
                    <Button color="secondary" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                        <span classID="btn-inner--text">Cancelar</span>
                    </Button>
                </Form>
            </div>
        </Modal>
    )
}