import React from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessTemplateForm } from "src/views/pages/processTemplate/ProcessTemplateForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProcessTemplateCreate = ({ create }) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader name="Plantilla Proceso" parentName="Admin"></SimpleHeader>
      <Container fluid>
        <h2>{"Agregar plantilla de proceso"}</h2>
        <ProcessTemplateForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
        />
      </Container>
      <AdminFooter />
    </main>
  );
};
