/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

// Components
import { dataProviders } from "src/variables/providers";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import { LocalityAPI } from "src/infrastructure/api/api-localities.js";
import { ProvincesAPI } from "src/infrastructure/api/api- provinces.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
  expand: 'locality, providerType'
};

export const Provider = ({
  getProviders,
  deleteProvider,
  resetErrors,
  loading,
  providersList,
  providerCreated,
  providerUpdated,
  providerDeleted,
  providerFound,
  providerErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(providersList?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getProviders(queryParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    providerFound,
    providerUpdated,
    providerCreated,
    providerDeleted,
    queryParams,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    providerErrors ? setAlert(true) : setAlert(false);
  }, [providerErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={providerErrors} state={alert} />
      <SimpleHeader
        name="Proveedores"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Proveedores"
            dataTable={providersList?.data ?? []}
            columnsTable={dataProviders.columns}
            deleteElement={deleteProvider}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <ProviderFilters
                businessName={queryParams.businessName}
                onBusinessNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    businessName: e.target.value,
                  });
                }}
                registrationNumber={queryParams.registrationNumber}
                onRegistrationNumberChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    registrationNumber: e.target.value,
                  });
                }}
                onLocalyChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    localityId: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const ProviderFilters = ({
  businessName,
  registrationNumber,
  onBusinessNameChange,
  onRegistrationNumberChange,
  onLocalyChange,
}) => {
  const { getLocalitiesApi } = new LocalityAPI();
  const { getProvincesByCountryID } = new ProvincesAPI();

  const [provinces, setProvinces] = useState([]);
  const [localities, setLocalities] = useState([]);

  const onProvincesChange = (e) => {
    getLocalitiesApi({
      provinceId: e.target.value,
      limit: 200,
    }).then((result) => {
      setLocalities(result.data.data);
    });
  };

  useEffect(() => {
    getProvincesByCountryID(1).then((result) => {
      setProvinces(result.data.data);
    });
  }, []);

  return (
    <div className="col-12 d-flex justify-content-between">
      <input
        className="form-control"
        placeholder="Razón Social"
        type="businessName"
        value={businessName ?? ""}
        onChange={onBusinessNameChange}
      />
      <input
        className="ml-4 form-control"
        placeholder="Número de Inscripto"
        type="registrationNumber"
        value={registrationNumber ?? ""}
        onChange={onRegistrationNumberChange}
      />
      <select
        name="provincia"
        className="ml-4 form-control"
        onChange={onProvincesChange}
      >
        <option value="">Provincia</option>
        {provinces.map((p) => (
          <option key={p.id} value={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      <select
        name="localidad"
        className="ml-4 form-control"
        onChange={onLocalyChange}
      >
        <option value="">Localidad</option>
        {localities.map((l) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>
    </div>
  );
};
