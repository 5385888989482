import moment from "moment";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { getBatchDetailsApi } from "src/infrastructure/api/api-batches";

export const BATCH_DETAIL_LIST = "BATCH_DETAIL_LIST";
export const IS_LOADING_DETAIL_LIST = "IS_LOADING_BATCH_DETAIL_LIST";

export const setBatchDetailList = (payload) => ({
  type: BATCH_DETAIL_LIST,
  payload,
});

export const setIsLoadingBatchDetailList = (payload) => ({
  type: IS_LOADING_DETAIL_LIST,
  payload,
});

const formatBatchDetailList = (list) => {
  const listFormatted = list?.map((item) => {
    const process = item.process;
    const sourceStorageUnitNumber = process?.storageUnit?.number;
    const destinationStorageUnitNumber =
      process?.destinationStorageUnit?.number;

    //This filter is to be able to use both values in the same column ("movement.sourceStorageUnit.number")
    if (process) {
      return {
        ...item,
        process: {
          ...item.process,
          storageUnit: {
            ...item.process?.storageUnit,
            number: `${sourceStorageUnitNumber} / ${
              destinationStorageUnitNumber ? destinationStorageUnitNumber : ""
            }`,
          },
        },
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    }

    return {
      ...item,
      createdAt: moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    };
  });

  return listFormatted ?? [];
};

export const getBatchDetailList = (id) => async (dispatch) => {
  let expand = "process.storageUnit, process.destinationStorageUnit";
  try {
    const {
      data: { data: batches },
    } = await getBatchDetailsApi(id, expand);
    const batchesFormatted = formatBatchDetailList(batches);
    dispatch(setBatchDetailList(batchesFormatted));
  } catch (error) {
    console.log(error);
  } finally {
  }
};
