import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProviderTypeAPI {

    createProviderTypeApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/provider-type`, options)

            return response

        } catch (error) {
            console.log('api-providerType: create => Error: ', error)
            throw (error)
        }
    };

    getProviderTypeApi = async (specification = {}) => {
        try {
            let url = `${BASE_URL}/provider-type`
            url += '?deletedAt=null' + (new URLSearchParams(specification)).toString();
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
            }

            const response = await sdkAuthRequest(url, options)

            return response
        } catch (error) {
            console.log('api-providerType: get all => Error: ', error)
            throw (error)
        }
    }

    getProviderTypeByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/provider-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-providerType: get by id => Error: ', error)
            throw (error)
        }
    }

    updateProviderTypeApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/provider-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-providerType: update => Error: ', error)
            throw (error)
        }
    };

    deleteProviderTypeApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/provider-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-providerType: delete => Error: ', error)
            throw (error)
        }
    }

}


