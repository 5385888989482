import { CREATE_PROCESS_DETAIL, GET_PROCESS_DETAIL_LIST, GET_PROCESS_DETAIL_ID, UPDATE_PROCESS_DETAIL, DELETE_PROCESS_DETAIL } from "../../actions/processDetail/processDetailAction"


const initialState = {
    processDetailCreated: null,
    processDetailList: [],
    processDetailFound: null,
    processDetailUpdated: null,
    processDetailDeleted: null
}

export const processDetailReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_PROCESS_DETAIL:
            return { ...state, processDetailCreated: payload }
        case GET_PROCESS_DETAIL_LIST:
            return { ...state, processDetailList: payload.data }
        case GET_PROCESS_DETAIL_ID:
            return { ...state, processDetailFound: payload.data }
        case UPDATE_PROCESS_DETAIL:
            return { ...state, processDetailUpdated: payload }
        case DELETE_PROCESS_DETAIL:
            return { ...state, processDetailDeleted: payload }
        default:
            return state
    }
}