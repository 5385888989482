/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataStorageUnit } from "src/variables/storageUnit";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
};

export const StorageUnit = ({
  loading,
  getStorageUnit,
  deleteStorageUnit,
  resetErrors,
  storageUnitList,
  createdStorageUnit,
  foundStorageUnit,
  updatedStorageUnit,
  deletedStorageUnit,
  storageUnitErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(storageUnitList?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStorageUnit(queryParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    deletedStorageUnit,
    updatedStorageUnit,
    createdStorageUnit,
    foundStorageUnit,
    queryParams,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    storageUnitErrors ? setAlert(true) : setAlert(false);
  }, [storageUnitErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={storageUnitErrors} state={alert} />
      <SimpleHeader
        name="Unidad de almacenamiento"
        parentName="Admin"
        create="create"
      />
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Unidad de almacenamiento"
            dataTable={storageUnitList?.data ?? []}
            columnsTable={dataStorageUnit.columns}
            deleteElement={deleteStorageUnit}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <StorageUnitFilters
                number={queryParams.number}
                onNumberChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    number: e.target.value,
                  });
                }}
                capacity={queryParams.capacity}
                onCapacityChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    capacity: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const StorageUnitFilters = ({
  number,
  capacity,
  onNumberChange,
  onCapacityChange,
}) => {
  return (
    <>
      <input
        className="col-3 ml-4 form-control"
        placeholder="Nombre"
        type="text"
        value={number ?? ""}
        onChange={onNumberChange}
      />
      <input
        className="col-3 ml-4 form-control"
        placeholder="Capacidad"
        type="text"
        value={capacity ?? ""}
        onChange={onCapacityChange}
      />
    </>
  );
};
