export const dataStorageUnitMovement = {
    columns: [
        {
            dataField: "date",
            text: "Fecha",
            sort: true,
        },
        {
            dataField: "process.name",
            text: "Proceso",
            sort: true,
        },
        {
            dataField: "process.product.name",
            text: "Producto",
            sort: true,
        },
        {
            dataField: "increases",
            text: "Incrementa",
            sort: true,
        },
        {
            dataField: "decreases",
            text: "Decrementa",
            sort: true,
        },
        {
            dataField: "balance",
            text: "Saldo",
            sort: true,
        },
        {
            dataField: "actions",
            text: "Acciones",
            sort: false,
        }
    ]
};
