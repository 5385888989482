import React, { useEffect } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { StorageUnitForm } from "./StorageUnitForm";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const StorageUnitCreate = ({
  create,
  getUnitOfMeasurement,
  unitOfMeasurementsList,
  loading,
}) => {
  useEffect(() => {
    getUnitOfMeasurement();
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Unidad de almacenamiento" parentName="Admin" />
      <Container fluid>
        <h2>{"Agregar unidad de almacenamiento"}</h2>
        {loading ? (
          <LoadingComponent />
        ) : (
          <StorageUnitForm
            elementData={null}
            nameForm={"create"}
            id={null}
            create={create}
            update={null}
            unitOfMeasurements={unitOfMeasurementsList}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
