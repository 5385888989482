import { UnitOfMeasurementAPI } from "src/infrastructure/api/api-unitOfMeasurement.js";
import { fetchStopLoading, fetchStartLoading } from '../loading/loadingAction';
import { setErrorMessages } from "../errors/errorsAction";

//#region UnitOfMeasurement Const
export const CREATE_UNIT_OF_MEASUREMENT = 'CREATE_UNIT_OF_MEASUREMENT'
export const GET_UNIT_OF_MEASUREMENT_LIST = 'GET_UNIT_OF_MEASUREMENT_LIST'
export const GET_UNIT_OF_MEASUREMENT_ID = 'GET_UNIT_OF_MEASUREMENT_ID'
export const UPDATE_UNIT_OF_MEASUREMENT = 'UPDATE_UNIT_OF_MEASUREMENT'
export const DELETE_UNIT_OF_MEASUREMENT = 'DELETE_UNIT_OF_MEASUREMENT'
export const UNIT_OF_MEASUREMENT_ERRORS = 'UNIT_OF_MEASUREMENT_ERRORS'
//#endregion

//#region UnitOfMeasurement Actions

export const fetchCreateUnitOfMeasurement = response => ({
    type: CREATE_UNIT_OF_MEASUREMENT,
    payload: response.data
})

export const fetchGetUnitOfMeasurementList = response => ({
    type: GET_UNIT_OF_MEASUREMENT_LIST,
    payload: response
})

export const fetchUnitOfMeasurementById = response => ({
    type: GET_UNIT_OF_MEASUREMENT_ID,
    payload: response
})

export const fetchUpdateUnitOfMeasurement = response => ({
    type: UPDATE_UNIT_OF_MEASUREMENT,
    payload: response
})

export const fetchDeleteUnitOfMeasurement = response => ({
    type: DELETE_UNIT_OF_MEASUREMENT,
    payload: response
})

//#endregion

const unitOfMeasurementAPI = new UnitOfMeasurementAPI()

//#region UnitOfMeasurements Dispach

// Dispatch create unitOfMeasurement
export const createUnitOfMeasurement = (unitOfMeasurementBody) => async (dispatch) => {
    try {
        dispatch(fetchCreateUnitOfMeasurement(await unitOfMeasurementAPI.createUnitOfMeasurementApi(unitOfMeasurementBody)));
        dispatch(fetchStartLoading())
    } catch (error) {
        dispatch(setErrorMessages(UNIT_OF_MEASUREMENT_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get unitOfMeasurement list
export const getUnitOfMeasurementList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchGetUnitOfMeasurementList(await unitOfMeasurementAPI.getUnitOfMeasurementApi(specification)))
        dispatch(fetchStartLoading())
    } catch (error) {
        dispatch(setErrorMessages(UNIT_OF_MEASUREMENT_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get unitOfMeasurement by id
export const getUnitOfMeasurementById = (id) => async (dispatch) => {
    try {
        dispatch(fetchUnitOfMeasurementById(await unitOfMeasurementAPI.getUnitOfMeasurementByIdApi(id)))
        dispatch(fetchStartLoading())
    } catch (error) {
        dispatch(setErrorMessages(UNIT_OF_MEASUREMENT_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update unitOfMeasurement
export const updateUnitOfMeasurement = (id, body) => async (dispatch) => {
    try {
        dispatch(fetchUpdateUnitOfMeasurement(await unitOfMeasurementAPI.updateUnitOfMeasurementApi(id, body)))
        dispatch(fetchStartLoading())
    } catch (error) {
        dispatch(setErrorMessages(UNIT_OF_MEASUREMENT_ERRORS, error))
    }
    finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete unitOfMeasurement
export const deleteUnitOfMeasurement = (id) => async (dispatch) => {
    try {
        dispatch(fetchDeleteUnitOfMeasurement(await unitOfMeasurementAPI.deleteUnitOfMeasurementApi(id)))
        dispatch(fetchStartLoading())
    } catch (error) {
        dispatch(setErrorMessages(UNIT_OF_MEASUREMENT_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}
//#endregion
