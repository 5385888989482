import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "src/components/Headers/AuthHeader.js";
import { useNavigate } from "react-router-dom";

import { PasswordRecoveryModal } from "./PasswordRecoveryModal";
import { useFormik } from "formik";

import { Alerts } from "src/components/Alerts/Alerts.js";

export const Login = ({ login, recovery, loginErrors }) => {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [canSubmit, setSubmitting] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, [setSubmitting]);

  const [modal, setModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    return () => {
      setDisableBtn(false); // This worked for me
    };
  }, []);

  const toggleModal = (event) => {
    setModal(!modal);
  };

  async function validateUser(values, btnLogin) {
    await login(values);
    let token = localStorage.getItem("AUTH_TOKEN");
    if (token && token !== "{}") {
      navigate("/admin/home");
    } else {
      setSubmitting(false);
      setDisableBtn(false);
      btnLogin.classList.remove("btn-light");
      btnLogin.classList.add("btn-info");
      btnLogin.removeAttribute("disabled");
    }
  }

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      let btnLogin = document.getElementById("btn-login");
      validateUser(values, btnLogin);
      btnLogin.classList.remove("btn-info");
      btnLogin.classList.add("btn-light");
      btnLogin.setAttribute("disabled", "true");
      setDisableBtn(true);
    },
  });

  return (
    <section className="Auth-bg">
      <AuthHeader
        title="Bienvenido!"
        lead="Bienvenido! Somos Envero, y estamos contentos de que estés aquí"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4 fs-m">
                  <small>PLATAFORMA DE TRAZABILIDAD</small>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <Alerts login={true} data={loginErrors} />
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={!canSubmit ? { border: "1px solid red" } : null}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="username"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={!canSubmit ? { border: "1px solid red" } : null}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4 fs-m btn-info"
                      type="submit"
                      id="btn-login"
                    >
                      {!disableBtn ? (
                        <span>Iniciar Sesión</span>
                      ) : (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </Button>
                  </div>
                </Form>
                <button
                  style={{ border: "none" }}
                  className="text-muted fs-m "
                  onClick={(e) => toggleModal(e)}
                >
                  <small>¿Olvidaste la contraseña?</small>
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <PasswordRecoveryModal
        modalState={modal}
        toggleModal={toggleModal}
        recovery={recovery}
      />
    </section>
  );
};
