import {
  CREATE_STORAGE_UNIT,
  SET_STORAGE_UNIT_LIST,
  SET_STORAGE_UNIT_FOUND,
  UPDATE_STORAGE_UNIT,
  DELETE_STORAGE_UNIT,
  GET_STORAGE_UNIT_LAST_MOVEMENT,
  STORAGE_UNIT_ERRORS,
  IS_LOADING_STORAGE_UNIT,
} from "../../actions/storageUnit/storageUnitAction";

const initialState = {
  StorageUnitCreated: null,
  StorageUnitList: null,
  StorageUnitFound: null,
  StorageUnitUpdated: null,
  StorageUnitDeleted: null,
  StorageUnitLastMovement: null,
  StorageUnitErrors: null,
  isLoadingStorageUnit: true,
};

export const storageUnitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_STORAGE_UNIT:
      return { ...state, StorageUnitCreated: payload };
    case SET_STORAGE_UNIT_LIST:
      return { ...state, StorageUnitList: payload };
    case SET_STORAGE_UNIT_FOUND:
      return { ...state, StorageUnitFound: payload };
    case UPDATE_STORAGE_UNIT:
      return { ...state, StorageUnitUpdated: payload };
    case DELETE_STORAGE_UNIT:
      return { ...state, StorageUnitDeleted: payload };
    case GET_STORAGE_UNIT_LAST_MOVEMENT:
      return { ...state, StorageUnitLastMovement: payload };
    case STORAGE_UNIT_ERRORS:
      return { ...state, StorageUnitErrors: payload };
    case IS_LOADING_STORAGE_UNIT:
      return { ...state, isLoadingStorageUnit: payload };
    default:
      return state;
  }
};
