import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { UnitOfMeasurementCreate } from "src/views/pages/unitOfMeasurement/UnitOfMeasurementCreate";
import { createUnitOfMeasurement } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';

export const CreateUnitOfMeasurement = () => {

    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createUnitOfMeasurement(body));
    }, [dispatch]);

    return (
        <UnitOfMeasurementCreate
            create={create}
        />
    )

}


