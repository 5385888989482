import React, { useState, useEffect } from "react";
import { Card, Container } from "reactstrap";
import { CrudTable } from "../tables/CrudTable.js";
import { dataProcesoDetail } from "src/variables/ProcessDetail";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent.js";

export const ProcessDetailList = ({
  getProcessDetail,
  deleteProcessDetail,
  loading,
  toggleModal,
  processId,
  processDetailList = [],
  processDetailDeleted,
  processDetailFound,
  processDetailUpdated,
  processDetailCreated,
  tableData,
}) => {
  useEffect(() => {
    getProcessDetail(processId);
    tableData.getProcess();
    tableData.getProcessTemplateCategory();
    tableData.getProcessTemplateCategoryDetail();
  }, [
    processDetailDeleted,
    processDetailCreated,
    processDetailFound,
    processDetailUpdated,
  ]);

  const renderTable = (finalList) => {
    return (
      <CrudTable
        name="Detalles de proceso"
        dataTable={finalList}
        columnsTable={dataProcesoDetail.columns}
        deleteElement={deleteProcessDetail}
        toggleModal={toggleModal}
      />
    );
  };

  return (
    <Card id="detailTable" className="mt-4">
      <Container style={{ height: "500px" }} className="pb-4 mt-4" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Detalles de proceso"
            dataTable={processDetailList}
            columnsTable={dataProcesoDetail.columns}
            deleteElement={deleteProcessDetail}
            toggleModal={toggleModal}
          />
        )}
      </Container>
    </Card>
  );
};
