/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

// Components
import { dataProviderType } from "src/variables/providerType";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";

export const ProviderType = ({
  getProviderType,
  deleteProviderType,
  resetErrors,
  loading,
  providerTypeList = [],
  providerTypeCreated,
  providerTypeUpdated,
  providerTypeDeleted,
  providerTypeFound,
  providerTypeErrors,
}) => {
  const [alert, setAlert] = useState();

  useEffect(() => {
    resetErrors();
    getProviderType();
  }, []);

  useEffect(() => {
    providerTypeErrors ? setAlert(true) : setAlert(false);
  }, [providerTypeErrors]);

  const renderTable = (providerTypeList) => {
    return (
      <CrudTable
        name="Tipo de Proveedores"
        dataTable={providerTypeList}
        columnsTable={dataProviderType.columns}
        deleteElement={deleteProviderType}
      />
    );
  };

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={providerTypeErrors} state={alert} />
      <SimpleHeader
        name="Tipo de Proveedores"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? <LoadingComponent /> : renderTable(providerTypeList)}
      </Container>
    </main>
  );
};
