
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// react library for routing
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "src/assets/vendor/nucleo/css/nucleo.css";
// core styles
import "src/assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import AdminLayout from "./layouts/Admin.js";
import { AuthLayout } from "./layouts/Auth.js";
import { AuthRecoverPassword } from 'src/containers/auth/AuthRecoverPassword'
import { getHistory } from "./infrastructure/react-router";
import { buildStore } from "./infrastructure/redux";
import reducers from "./infrastructure/redux/store";

const Init = () => {
  const history = getHistory();

  useEffect(() => {
    const callback = ([entry]) => {
      const iframeLicense = document.querySelector('iframe')
      if (document.body.lastChild.nodeName === 'IFRAME')
        iframeLicense.remove()
    }
    
    const observer = new MutationObserver(callback, { root: document.body })
    const config = { childList: true };

    observer.observe(document.body, config)
  }, [])
  

  return (
    <Provider store={buildStore(reducers, history)}>
      <HashRouter>
        <Routes>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/recovery" element={<AuthRecoverPassword />} />
          <Route
            path="*"
            element={<Navigate to="/auth/login" replace />}
          />
        </Routes>
      </HashRouter>
    </Provider>
  );
};

ReactDOM.render(<Init />, document.getElementById("root"));
