import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UnitOfMeasurementUpdate } from 'src/views/pages/unitOfMeasurement/UnitOfMeasurementUpdate';
import { getUnitOfMeasurementById, updateUnitOfMeasurement } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';

export const UpdateUnitOfMeasurement = () => {

    const dispatch = useDispatch();

    const unitOfMeasurementById = useCallback(async (idClient) => {
        await dispatch(getUnitOfMeasurementById(idClient));
    }, [dispatch]);

    const unitOfMeasurementFound = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementFound)

    const update = useCallback(async (id, body) => {
        await dispatch(updateUnitOfMeasurement(id, body));
    }, [dispatch]);

    return (
        <UnitOfMeasurementUpdate
            UnitOfMeasurementById={unitOfMeasurementById}
            UnitOfMeasurementFound={unitOfMeasurementFound}
            update={update}
        />
    )
}
