/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataUnitOfMeasurement } from "src/variables/unitOfMeasurement";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

let offset = 0;
const limit = 10;

const initQueryParams = {
  offset,
  limit,
};

export const UnitOfMeasurement = ({
  getUnitOfMeasurement,
  deleteUnitOfMeasurement,
  resetErrors,
  loading,
  unitOfMeasurementsList,
  unitOfMeasurementsCreated,
  unitOfMeasurementsUpdated,
  unitOfMeasurementsDeleted,
  unitOfMeasurementsFound,
  unitOfMeasurementsErrors,
}) => {
  const [alert, setAlert] = useState();
  const totalPages = Math.ceil(unitOfMeasurementsList?.totalCount / limit);

  const metadata = {
    totalPages,
    page: offset / limit + 1,
  };

  const onPageChange = (newPage) => {
    offset = ((newPage || 1) - 1) * limit;
    getUnitOfMeasurement({
      offset,
      limit,
    });
  };

  useEffect(() => {
    getUnitOfMeasurement(initQueryParams);
  }, [
    unitOfMeasurementsCreated,
    unitOfMeasurementsUpdated,
    unitOfMeasurementsDeleted,
    unitOfMeasurementsFound,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    unitOfMeasurementsErrors ? setAlert(true) : setAlert(false);
  }, [unitOfMeasurementsErrors]);

  useEffect(() => () => (offset = 0), []);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={unitOfMeasurementsErrors} state={alert} />
      <SimpleHeader
        name="Unidad de medida"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Unidad de medida"
            dataTable={unitOfMeasurementsList?.data ?? []}
            columnsTable={dataUnitOfMeasurement.columns}
            deleteElement={deleteUnitOfMeasurement}
            metadata={metadata}
            onPageChange={onPageChange}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
