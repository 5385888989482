import { createContext, useState } from "react";

export const UpdateProcessTable = createContext();

export const UpdateProcessDetailTable = ({ children }) => {
  const [updateProcess, setUpdateProcess] = useState(false);
  return (
    <UpdateProcessTable.Provider value={{ updateProcess, setUpdateProcess }}>
      {children}
    </UpdateProcessTable.Provider>
  );
};
