import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class StorageUnitAPI {

    createStorageUnitApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/storage-units`, options)

            return response

        } catch (error) {
            console.log('api-storageUnit:18 => Error: ', error)
            throw (error)
        }
    };

    getStorageUnitApi = async (specification = {}) => {
        try {
            let url = new URL(`${BASE_URL}/storage-units`);
            url.searchParams.append("deletedAt", "null");
            Object.entries(specification).forEach(([key, value]) => {
                if (!value) return;
                url.searchParams.append(key, value);
            })

            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url.toString(), options)
            return response
        } catch (error) {
            console.log('api-storageUnit:35 => Error: ', error)
            throw (error)
        }
    }

    getStorageUnitByIdApi = async (id, expand) => {
        if (expand) {
            expand = '?expand=' + expand
          }
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/storage-units/${id}${expand}`, options)

            return response

        } catch (error) {
            console.log('api-storageUnit:51 => Error: ', error)
            throw (error)
        }
    }

    getStorageUnitMovementsByIdApi = async (id, specification = {}) => {
        try {
            let url = new URL(`${BASE_URL}/storage-unit-movement/storage-unit/${id}`);
            url.searchParams.append("deletedAt", "null");
            Object.entries(specification).forEach(([key, value]) => {
                if (!value) return;
                url.searchParams.append(key, value);
            })


            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url.toString(), options)
            return response
        } catch (error) {
            console.log('api-storageUnit:51 => Error: ', error)
            throw (error)
        }
    }

    updateStorageUnitApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/storage-units/${id}`, options)

            return response

        } catch (error) {
            console.log('api-storageUnit:70 => Error: ', error)
            throw (error)
        }
    };

    deleteStorageUnitApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/storage-units/${id}`, options)

            return response

        } catch (error) {
            console.log('api-storageUnit:87 => Error: ', error)
            throw (error)
        }
    }

    getStorageUnitLastMovement = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/storage-unit-movement/${id}/last?expand=storageUnit.unitOfMeasurement`, options)

            return response

        } catch (error) {
            console.log('api-storageUnit:108 => Error: ', error)
            throw (error)
        }
    }

}


