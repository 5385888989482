import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcessList,
  deleteProcess,
  approve,
  cleanProcessCreated,
} from "src/infrastructure/redux/store/actions/process/processAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";
import { Process } from "src/views/pages/process/Process";

export const ListProcess = () => {
  const dispatch = useDispatch();

  const processToDelete = useCallback(
    async (id) => {
      await dispatch(deleteProcess(id));
    },
    [dispatch]
  );

  const getProcess = useCallback(
    async (specification) => {
      await dispatch(getProcessList(specification));
    },
    [dispatch]
  );

  const approveProcess = useCallback(
    async (id) => {
      await dispatch(approve(id));
      await dispatch(getProcessList());
    },
    [dispatch]
  );

  const restoreProcessCreated = useCallback(
    async (specification) => {
      await dispatch(cleanProcessCreated());
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("PROCESS_ERRORS"));
  }, [dispatch]);

  const processList = useSelector((store) => store.process.processList);
  const processDeleted = useSelector((store) => store.process.processDeleted);
  const processUpdated = useSelector((store) => store.process.processUpdated);
  const processCreated = useSelector((store) => store.process.processCreated);
  const processFound = useSelector((store) => store.process.processFound);
  const processErrors = useSelector((store) => store.errors.processErrors);
  const loading = useSelector((store) => store.loading.loading);

  return (
    <Process
      getProcess={getProcess}
      deleteProcess={processToDelete}
      resetErrors={resetErrors}
      loading={loading}
      processList={processList}
      processDeleted={processDeleted}
      processFound={processFound}
      processUpdated={processUpdated}
      processCreated={processCreated}
      processErrors={processErrors}
      approveProcess={approveProcess}
      restoreProcessCreated={restoreProcessCreated}
    />
  );
};
