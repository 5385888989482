export const AUTH_TOKEN = 'AUTH_TOKEN';
export const USER_TYPE = 'USER_TYPE';

export const storeAccessToken = (data) => {
  const accessToken = {
    accessToken: data.data.token
  };
  writeToLocalStorage(accessToken, AUTH_TOKEN);
  return accessToken;
};

/**
 * Store data in ls if it is available
 * @param data object data to be stored
 * @param key
 */
export const writeToLocalStorage = (data, key) => {
  if (window && window.localStorage) {
    return localStorage.setItem(key, JSON.stringify(data));

  }
  throw new Error('No window object detected');
};

/**
 * 
 * @param {*} key 
 * @returns data stored in localStorage or undefined
 *
 */
export const readFromLocalStorage = key => {
  if (window.localStorage) {
    const result = localStorage.getItem(key);
    return result ? (JSON.parse(result)) : undefined;
  }
  throw new Error('No window object detected');
};

export const deleteFromLocalStorage = (key) => {
  if (window && window.localStorage) {
    return localStorage.removeItem(key);
  }
  throw new Error(`No window object detected`);
};

export const invalidateLocal = () => {
  deleteFromLocalStorage(AUTH_TOKEN);
  deleteFromLocalStorage(USER_TYPE);
}