import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProviderForm } from "src/views/pages/providers/ProviderForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProviderUpdate = ({
  providerById,
  providerFound,
  update,
  getLocalities,
  getCountries,
  getProvinces,
  localitySelected,
  getLocality,
  getProvincesByID,
  getProviderType,
  providersTypeList,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      providerById(id);
      getLocalities();
      getProviderType();
      setLoading(false);
      getProviderType();
    }
  }, [id, providerById, getLocalities, loading]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Proveedor" parentName="Admin"></SimpleHeader>
      {!loading && (
        <Container fluid>
          <h2>{"Editar Proveedor"}</h2>
          <ProviderForm
            elementData={providerFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
            getCountries={getCountries}
            getProvinces={getProvinces}
            localitySelected={localitySelected}
            getLocality={getLocality}
            getProvincesByID={getProvincesByID}
            providersType={providersTypeList}
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
