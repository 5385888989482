import React from "react";
// react library for routing
import { useLocation, Route, Routes } from "react-router-dom";

// core components
import AuthFooter from "src/components/Footers/AuthFooter.js";

import { AuthLogin } from "src/containers/auth/AuthLogin";

import "src/assets/css/Auth.css";

export const AuthLayout = () => {
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <main className="main-content overflow-auto" ref={mainContentRef}>
      <Routes>
        <Route path="/login" element={<AuthLogin />} />
      </Routes>
    </main>
  );
};
