import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

export const ProviderTypeForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [valueInput, setValueInput] = useState({
    name: "",
  });

  useEffect(() => {
    if (elementData) {
      setValueInput({
        name: elementData.name,
      });
    }
  }, [elementData]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        navigate("/admin/providers-type");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/providers-type");
      }
    },
  });

  const cancelForm = (event) => {
    navigate("/admin/providers-type");
  };

  return (
    <Card>
      <CardBody>
        {loading && (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label htmlFor="name">denominación</label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value);
                  setValueInput({
                    ...valueInput,
                    name: e.target.value,
                  });
                }}
                value={valueInput.name}
                required={!elementData}
              />
            </FormGroup>
            <Button classID="btn-icon btn-3" color="primary" type="submit">
              <span classID="btn-inner--text">Guardar</span>
            </Button>
            <Button
              classID="btn-icon btn-3"
              color="warning"
              type="button"
              onClick={(e) => cancelForm(e)}
            >
              <span classID="btn-inner--text">Cancelar</span>
            </Button>
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
