import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProductsForm } from "./ProductsForm";
import AdminFooter from "src/components/Footers/AdminFooter";
import { getBatchNumbersList } from "src/infrastructure/redux/store/actions/batchNumbers/batchNumbersAction";
import { useDispatch, useSelector } from "react-redux";

export const ProductsUpdate = ({
  productsById,
  productsFound,
  update,
  getProductsType,
  productTypesList,
  getUnitOfMeasurement,
  unitOfMeasurementsList,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      productsById(id);
      getProductsType();
      getUnitOfMeasurement();
      if (
        productsFound &&
        productTypesList?.data?.length > 0 &&
        unitOfMeasurementsList?.data?.length > 0
      ) {
        setLoading(false);
      }
    }
  }, [
    id,
    productsById,
    getProductsType,
    getUnitOfMeasurement,
    productsFound,
    productTypesList,
    unitOfMeasurementsList,
    loading,
  ]);

  const batchNumbersList = useSelector(
    (state) => state.batchNumbers.batchNumbersList
  );

  const handleGetBatchNumbersList = () => {
    dispatch(
      getBatchNumbersList({
        limit: 1000,
      })
    );
  };

  const cleanBatchNumberList = () => {
    dispatch(cleanBatchNumberList());
  };

  useEffect(() => {
    handleGetBatchNumbersList();
    return cleanBatchNumberList;
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Producto" parentName="Admin"></SimpleHeader>
      {!loading && productsFound.id && (
        <Container fluid>
          <h2>{"Editar Producto"}</h2>
          <ProductsForm
            key={Math.random()}
            elementData={productsFound}
            nameForm={"update"}
            id={id}
            create={null}
            update={update}
            batchNumbers={batchNumbersList?.data ?? []}
            productTypes={
              productTypesList?.data?.filter(
                (element) => element.deletedAt === null
              ) ?? []
            }
            unitOfMeasurement={
              unitOfMeasurementsList?.data?.filter(
                (element) => element.deletedAt === null
              ) ?? []
            }
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
