import React, { useEffect } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProviderTypeForm } from "src/views/pages/providerType/ProviderTypeForm";

export const ProviderTypeCreate = ({ create }) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader name="Tipo de Proveedor" parentName="Admin"></SimpleHeader>
      <Container fluid>
        <h2>{"Agregar Tipo de Proveedor"}</h2>
        <ProviderTypeForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
        />
      </Container>
    </main>
  );
};
