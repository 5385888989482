import { fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";
import { TraceabilityReportsAPI } from "src/infrastructure/api/api-traceability-reports";

//#region traceabilityReports Const
export const CREATE_TRANCEABILITY_REPORTS = "CREATE_TRANCEABILITY_REPORTS";
export const TRANCEABILITY_REPORTS_LIST = "TRANCEABILITY_REPORTS_LIST";
export const TRANCEABILITY_REPORT_FOUND = "TRANCEABILITY_REPORT_FOUND";
export const TRANCEABILITY_REPORT_PUBLISHED = "TRANCEABILITY_REPORT_PUBLISHED";
export const UPDATE_TRANCEABILITY_REPORTS = "UPDATE_TRANCEABILITY_REPORTS";
export const DELETE_TRANCEABILITY_REPORTS = "DELETE_TRANCEABILITY_REPORTS";
export const TRANCEABILITY_REPORTS_ERRORS = "TRANCEABILITY_REPORTS_ERRORS";
export const IS_LOADING_TRANCEABILITY_REPORTS =
  "IS_LOADING_traceabilityReports";
export const IS_LOADING_TRANCEABILITY_REPORT_BY_ID =
  "IS_LOADING_TRANCEABILITY_REPORT_BY_ID";
export const IS_LOADING_TRANCEABILITY_REPORT_PUBLISHED =
  "IS_LOADING_TRANCEABILITY_REPORT_PUBLISHED";
export const IS_LOADING_TRANCEABILITY_REPORT_PDF =
  "IS_LOADING_TRANCEABILITY_REPORT_PDF";
export const TRANCEABILITY_REPORT_PDF = "TRANCEABILITY_REPORT_PDF";

//#region traceabilityReports Actions

export const setCreatetraceabilityReports = (response) => ({
  type: CREATE_TRANCEABILITY_REPORTS,
  payload: response.data,
});

export const setTraceabilityReportList = (response) => ({
  type: TRANCEABILITY_REPORTS_LIST,
  payload: response,
});

export const setTraceabilityReportFound = (payload) => ({
  type: TRANCEABILITY_REPORT_FOUND,
  payload,
});

export const setUpdatetraceabilityReport = (response) => ({
  type: UPDATE_TRANCEABILITY_REPORTS,
  payload: response,
});

export const setDeletetraceabilityReports = (response) => ({
  type: DELETE_TRANCEABILITY_REPORTS,
  payload: response,
});

export const setIsLoadingtraceabilityReports = (isLoading) => ({
  type: IS_LOADING_TRANCEABILITY_REPORTS,
  payload: isLoading,
});

export const setIsLoadingtraceabilityReportById = (isLoading) => ({
  type: IS_LOADING_TRANCEABILITY_REPORT_BY_ID,
  payload: isLoading,
});

const setIsLoadingPublishTraceabilityReport = (isLoading) => ({
  type: IS_LOADING_TRANCEABILITY_REPORT_PUBLISHED,
  payload: isLoading,
});

const setIsLoadingTraceabilityReportPdf = (isLoading) => ({
  type: IS_LOADING_TRANCEABILITY_REPORT_PDF,
  payload: isLoading,
});

const setTraceabilityReportPdf = (response) => ({
  type: TRANCEABILITY_REPORT_PDF,
  payload: response,
});

const traceabilityReportsAPI = new TraceabilityReportsAPI();

// Dispatch create traceabilityReports
export const createTraceabilityReports =
  (traceabilityReportsBody) => async (dispatch) => {
    try {
      dispatch(
        setCreatetraceabilityReports(
          await traceabilityReportsAPI.createTraceabilityReportsApi(
            traceabilityReportsBody
          )
        )
      );
    } catch (error) {
      dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
    } finally {
      dispatch(fetchStopLoading());
    }
  };

// traceabilityReportsAPI.publishTraceabilityReportsApi(id)

export const publishTraceabilityReport = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoadingPublishTraceabilityReport(true));
    const response = await traceabilityReportsAPI.publishTraceabilityReportsApi(
      id
    );
    dispatch(setTraceabilityReportFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
  } finally {
    dispatch(setIsLoadingPublishTraceabilityReport(false));
  }
};

export const getTraceabilityReportPDF = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoadingTraceabilityReportPdf(true));
    const response = await traceabilityReportsAPI.getTraceabilityReportPDFApi(
      id
    );
    dispatch(setTraceabilityReportPdf(response.data));
  } catch (error) {
    dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
  } finally {
    dispatch(setIsLoadingTraceabilityReportPdf(false));
  }
};

// Dispatch get traceabilityReports list
export const getTraceabilityReportsList =
  (specification) => async (dispatch) => {
    try {
      // dispatch(fetchStartLoading());
      setIsLoadingtraceabilityReports(true);
      const response = await traceabilityReportsAPI.getTraceabilityReportsApi(
        specification
      );
      dispatch(setTraceabilityReportList(response.data));
    } catch (error) {
      dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
    } finally {
      setIsLoadingtraceabilityReports(false);
      dispatch(fetchStopLoading());
    }
  };

export const cleanTraceabilityReportList = () => async (dispatch) => {
  dispatch(setTraceabilityReportList(null));
};

// Dispatch get traceabilityReports by id
export const getTraceabilityReportById = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoadingtraceabilityReportById(true));
    const response = await traceabilityReportsAPI.getTraceabilityReportByIdApi(
      id
    );
    dispatch(setTraceabilityReportFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
  } finally {
    dispatch(setIsLoadingtraceabilityReportById(false));
    dispatch(fetchStopLoading());
  }
};

export const emptyTraceabilityReportFound = () => async (dispatch) => {
  dispatch(setTraceabilityReportFound(null));
};

export const cleanTraceabilityReportFound = () => async (dispatch) => {
  dispatch(setTraceabilityReportFound(null));
};

// Dispatch update traceabilityReports
export const updateTraceabilityReport = (id, body) => async (dispatch) => {
  try {
    dispatch(
      setUpdatetraceabilityReport(
        await traceabilityReportsAPI.updateTraceabilityReportApi(id, body)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
  }
};

// Dispatch delete traceabilityReports
export const deletetraceabilityReports = (id) => async (dispatch) => {
  try {
    dispatch(
      setDeletetraceabilityReports(
        await traceabilityReportsAPI.deleteTraceabilityReportsApi(id)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(TRANCEABILITY_REPORTS_ERRORS, error));
  }
};
//#endregion
