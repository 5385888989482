import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Button, Container } from "reactstrap";
import { ProcessForm } from "src/views/pages/process/ProcessForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProcessView = ({
  processById,
  processFound,
  updateProcess,
  selectData,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    navigate("/admin/process");
  };

  useEffect(() => {
    if (loading) {
      processById(id);
      if (processFound) {
        setLoading(false);
      }
    }
  }, [id, loading, processById, processFound]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Proceso" parentName="Admin"></SimpleHeader>
      {!loading && (
        <>
          <Container className="mt-4" fluid>
            <ProcessForm
              elementData={processFound}
              nameForm={"edit"}
              id={id}
              create={null}
              update={updateProcess}
              processTemplate={processFound.processTemplate}
              selectData={selectData}
              readOnly
            />
            <Button
              onClick={handleClose}
              classID="btn-icon btn-3"
              className="align-self-start mt-3"
              color="primary"
              type="submit"
            >
              <span classID="btn-inner--text">Cerrar</span>
            </Button>
          </Container>
        </>
      )}

      <AdminFooter />
    </main>
  );
};
