import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUnitOfMeasurementList } from "src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction";
import { updateProcessTemplateCategoryDetail } from "src/infrastructure/redux/store/actions/processTemplateCategoryDetail/processTemplateCategoryDetail";
import { CategoryDetailModal } from "src/views/pages/processTemplateCategoryDetail/CategoryDetailModal";

export const UpdateProcessTemplateCategoryDetail = ({
  modal,
  toggleModal,
  pTCategoryDetail,
  categoryId,
}) => {
  const dispatch = useDispatch();

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateProcessTemplateCategoryDetail(id, body));
    },
    [dispatch]
  );

  const getUnitOfMeasurement = useCallback(
    async (specification) => {
      await dispatch(getUnitOfMeasurementList(specification));
    },
    [dispatch]
  );

  const unitOfMeasurementsList = useSelector(
    (store) => store.unitOfMeasurement.UnitOfMeasurementList
  );

  React.useEffect(() => {
    if (modal && pTCategoryDetail) {
      getUnitOfMeasurement();
    }
  }, [modal, pTCategoryDetail]);

  return (
    <>
      {modal && pTCategoryDetail && (
        <CategoryDetailModal
          create={null}
          update={update}
          elementData={pTCategoryDetail}
          modalState={modal}
          toggleModal={toggleModal}
          nameForm={"edit"}
          unitOfMeasurementModal={
            unitOfMeasurementsList?.data?.filter(
              (element) => element.deletedAt === null
            ) ?? []
          }
          processTemplateCategoryId={categoryId}
        />
      )}
    </>
  );
};
