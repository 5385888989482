import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class MovementsAPI {
  createMovementsApi = async (body) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(`${BASE_URL}/movement`, options);

      return response;
    } catch (error) {
      console.log("api-movements:18 => Error: ", error);
      throw error;
    }
  };

  getMovementsApi = async (specification = {}) => {
    try {
      let url = new URL(`${BASE_URL}/movement`);
      url.searchParams.append("deletedAt", "null");
      Object.entries(specification).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      })

      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);
      return response;
    } catch (error) {
      console.log("api-movements:35 => Error: ", error);
      throw error;
    }
  };

  getMovementsByIdApi = async (id, expand) => {
    if (expand) {
      expand = '?expand=' + expand
    }
    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/movement/${id}${expand}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-movements:51 => Error: ", error);
      throw error;
    }
  };

  updateMovementsApi = async (id, body) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/movement/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-movements:70 => Error: ", error);
      throw error;
    }
  };

  deleteMovementsApi = async (id) => {
    try {
      const options = {
        method: "DELETE",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/movement/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-movements:87 => Error: ", error);
      throw error;
    }
  };

  approveMovementsApi = async (id) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: {
          movementId: id
        },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/movement/approve`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-movements:70 => Error: ", error);
      throw error;
    }
  };
}
