import { CREATE_USER, GET_USER_LIST, GET_USER_ID, UPDATE_USER, DELETE_USER } from "../../actions/users/usersAction"


const initialState = {
    UsersCreated: null,
    UsersList: null,
    UsersFound: null,
    UsersUpdated: null,
    UsersDeleted: null,
}

export const usersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_USER:
            return { ...state, UsersCreated: payload }
        case GET_USER_LIST:
            return { ...state, UsersList: payload }
        case GET_USER_ID:
            return { ...state, UsersFound: payload.data }
        case UPDATE_USER:
            return { ...state, UsersUpdated: payload }
        case DELETE_USER:
            return { ...state, UsersDeleted: payload }
        default:
            return state
    }
}