import React, { useEffect } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ClientForm } from "src/views/pages/clients/ClientForm";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ClientCreate = ({
  create,
  loading,
  countryList,
  provinceList,
  localitiesList,
  getProvincesByID,
  getLocalities,
  getLocality,
  localitySelected,
}) => {
  useEffect(() => {
    getLocalities();
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Cliente" parentName="Admin"></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        <h2>{"Agregar Cliente"}</h2>
        {loading ? (
          <LoadingComponent />
        ) : (
          <ClientForm
            id={null}
            create={create}
            nameForm={"create"}
            elementData={null}
            update={null}
            countryList={countryList}
            provinceList={provinceList}
            localitiesList={localitiesList}
            getProvincesByID={getProvincesByID}
            getLocality={getLocality}
            localitySelected={localitySelected}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
