import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class ConfigurationAPI {
  getConfigurationApi = async () => {
    try {
      let url = new URL(`${BASE_URL}/configurations/1`);

      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);
      return response;
    } catch (error) {
      console.log("api-configuration => Error: ", error);
      throw error;
    }
  };

  updateConfigurationApi = async (data) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/configurations/1`,
        options
      );

      return response.data;
      // return new Promise((res) => {
      //   setTimeout(() => {
      //     console.log({
      //       endpoint: `/configurations/1`,
      //       data,
      //       method: "PUT",
      //     });
      //     res({ data });
      //   }, 3000);
      // });
    } catch (error) {
      console.log("api-configuration => Error: ", error);
      throw error;
    }
  };
}
