import { StorageUnitAPI } from "src/infrastructure/api/api-storageUnit";
import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Card, CardBody, Container } from "reactstrap";
import moment from "moment";

export const StorageUnits = () => {
  const [storageUnits, setStorageUnits] = React.useState([]);
  const [storageUnit, setStorageUnit] = React.useState();
  const [storageUnitsMovement, setStorageUnitsMovement] = React.useState([]);
  const { getStorageUnitApi, getStorageUnitMovementsByIdApi } = useMemo(
    () => new StorageUnitAPI(),
    []
  );

  const handleSelectStorageUnit = (id) => {
    getStorageUnitMovementsByIdApi(id).then(({ data: { data } }) => {
      setStorageUnit(storageUnits?.data?.find((storage) => storage.id === id));
      setStorageUnitsMovement(data);
    });
  };

  React.useEffect(() => {
    getStorageUnitApi().then(({ data }) => {
      setStorageUnits(data);
    });
  }, []);

  useEffect(() => {
    if (storageUnits?.data?.length === 0) return;

    getStorageUnitMovementsByIdApi(storageUnits?.data?.at(0).id).then(
      ({ data: { data } }) => {
        setStorageUnit(storageUnits?.data?.at(0));
        setStorageUnitsMovement(data);
      }
    );
  }, [storageUnits]);

  const dataFormatted = useMemo(() => {
    const dates = storageUnitsMovement.map(({ date }) =>
      moment(date).format("DD-MM-YYYY")
    );
    const balance = storageUnitsMovement.map(({ balance }) => balance);
    const names = storageUnitsMovement.map(({ process, movement }) => {
      if (process?.name) return process?.name;
      else return movement?.name ?? "";
    });

    return {
      labels: dates,
      datasets: [
        {
          label: "Balance",
          data: balance,
          processNames: names,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    };
  }, [storageUnitsMovement]);

  const options = {
    tooltips: {
      callbacks: {
        // label: function () {},
        title: function () {
          return "";
        },

        footer: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem[0].datasetIndex];
          var index = tooltipItem[0].index;
          return `${data.labels[index]}\n${dataset.processNames[index]}`;
        },
      },
    },
  };

  return (
    <>
      <Container fluid>
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-xs font-weight-bolder">
                    Unidades de Almacenamiento
                  </th>
                </tr>
              </thead>
              <tbody>
                {storageUnits?.data?.map((storage) => (
                  <tr key={storage.id}>
                    <td>
                      <p className="text-xs font-weight-bold mb-0">
                        {storage.number}
                      </p>
                    </td>
                    <td className="align-middle">
                      <span
                        onClick={() => handleSelectStorageUnit(storage.id)}
                        className="font-weight-bold text-xs"
                        style={{ cursor: "pointer" }}
                      >
                        Ver
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
          <Card>
            <CardBody>
              <div className="chart">
                <Line
                  data={dataFormatted}
                  options={options}
                  id="chart-sales"
                  className="chart-canvas"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};
