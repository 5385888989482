import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProcessTemplateAPI {

    createProcessTemplateApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template`, options)

            return response

        } catch (error) {
            console.log('api-processTemplate:18 => Error: ', error)
            throw (error)
        }
    };

    getProcessTemplatesApi = async (specification = {}) => {
        try {
            let url = new URL(`${BASE_URL}/process-template`);
            url.searchParams.append("deletedAt", "null");
            Object.entries(specification).forEach(([key, value]) => {
                url.searchParams.append(key, value);
            })

            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url.toString(), options)
            return response;
        } catch (error) {
            console.log('api-processTemplate:35 => Error: ', error)
            throw (error)
        }
    }

    getProcessTemplateByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplate:51 => Error: ', error)
            throw (error)
        }
    }

    updateProcessTemplateApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplate:70 => Error: ', error)
            throw (error)
        }
    };

    deleteProcessTemplateApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplate:87 => Error: ', error)
            throw (error)
        }
    }

}


