import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProcessTemplateCategoryUpdate } from "src/views/pages/processTemplateCategory/ProcessTemplateCategoryUpdate";
import {
  getProcessTemplateCategoryById,
  updateProcessTemplateCategory,
  resetCreateProcessTemplateCategory,
} from "src/infrastructure/redux/store/actions/processTemplateCategory/processTemplateCategoryAction";
import { getProcessTemplateList } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { UpdateProcessDetailTable } from "./context/UpdateProcessDetailTable";

export const UpdateProcessTemplateCategory = () => {
  const dispatch = useDispatch();

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateProcessTemplateCategory(id, body));
    },
    [dispatch]
  );

  const getFcProcessTemplateCategoryById = useCallback(
    async (idProcessTemplate) => {
      await dispatch(getProcessTemplateCategoryById(idProcessTemplate));
    },
    [dispatch]
  );

  const processTemplateCategoryFound = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryFound
  );

  const getProcessTemplate = useCallback(
    async (specification) => {
      await dispatch(getProcessTemplateList(specification));
    },
    [dispatch]
  );

  const processTemplateListReducer = useSelector(
    (store) => store.processTemplate.processTemplateList
  );

  const resetCreate = useCallback(async () => {
    await dispatch(resetCreateProcessTemplateCategory());
  }, [dispatch]);

  return (
    <UpdateProcessDetailTable>
      <ProcessTemplateCategoryUpdate
        update={update}
        resetCreate={resetCreate}
        getProcessTemplateCategoryById={getFcProcessTemplateCategoryById}
        processTemplateCategory={processTemplateCategoryFound}
        getProcessTemplate={getProcessTemplate}
        processTemplateList={processTemplateListReducer}
        isLoadingProcessTemplate={false}
      />
    </UpdateProcessDetailTable>
  );
};
