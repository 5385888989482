/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataUsers } from "src/variables/Users";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
};

export const Users = ({
  getUsers,
  deleteUsers,
  resetErrors,
  loading,
  usersList,
  usersCreated,
  usersUpdated,
  usersDeleted,
  usersFound,
  usersErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(usersList?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getUsers(queryParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [usersCreated, usersUpdated, usersDeleted, usersFound, queryParams]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    usersErrors ? setAlert(true) : setAlert(false);
  }, [usersErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={usersErrors} state={alert} />
      <SimpleHeader
        name="Usuarios"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Usuarios"
            dataTable={usersList?.data ?? []}
            columnsTable={dataUsers.columns}
            deleteElement={deleteUsers}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <UsersFilters
                name={queryParams.name}
                onNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    name: e.target.value,
                  });
                }}
                lastName={queryParams.lastName}
                onLastNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    lastName: e.target.value,
                  });
                }}
                email={queryParams.email}
                onEmailChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    email: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const UsersFilters = ({
  email,
  name,
  lastName,
  onEmailChange,
  onNameChange,
  onLastNameChange,
}) => {
  return (
    <>
      <input
        className="col-3 ml-4 form-control"
        placeholder="Email"
        type="email"
        value={email ?? ""}
        onChange={onEmailChange}
      />
      <input
        className="col-3 ml-4 form-control"
        placeholder="Nombre"
        type="name"
        value={name ?? ""}
        onChange={onNameChange}
      />
      <input
        className="col-3 ml-4 form-control"
        placeholder="Apellido"
        type="lastName"
        value={lastName ?? ""}
        onChange={onLastNameChange}
      />
    </>
  );
};
