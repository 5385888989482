import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import Selector from "../components/Selector";
import { batchNumberTypes } from "src/variables/batchNumbers";

export const BatchNumbersForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
}) => {
  const [Loading, setLoading] = useState();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      type: elementData?.type ?? "",
      generateByDispatch: elementData?.generateByDispatch ?? false,
      subfix: elementData?.subfix ?? "",
      prefix: elementData?.prefix ?? "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        setLoading(false);
        if (!Loading) {
          navigate("/admin/batch-numbers");
        }
      } else if (nameForm === "update") {
        update(id, values);
        navigate("/admin/batch-numbers");
      }
    },
  });

  const cancelForm = (e) => {
    navigate("/admin/batch-numbers");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="type">Tipo</label>
            <Selector
              items={batchNumberTypes}
              defaultValue={
                batchNumberTypes.find((l) => l.id === formik.values.type)?.name
              }
              onValueChange={(v) => formik.setFieldValue("type", v)}
              itemsTotalCount={2}
            />
          </FormGroup>
          <FormGroup>
            <label
              htmlFor="generateByDispatch"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                id="generateByDispatch"
                name="generateByDispatch"
                type="checkbox"
                onChange={formik.handleChange}
                defaultChecked={!!formik.values.generateByDispatch}
              />
              Generar por Despacho
            </label>
          </FormGroup>
          <FormGroup>
            <label htmlFor="subfix">Subfijo</label>
            <Input
              id="subfix"
              name="subfix"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.subfix}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="prefix">Prefijo</label>
            <Input
              id="prefix"
              name="prefix"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.prefix}
            />
          </FormGroup>
          <Button classID="btn-icon btn-3" color="primary" type="submit">
            <span classID="btn-inner--text">Guardar</span>
          </Button>
          <Button
            classID="btn-icon btn-3"
            color="warning"
            type="button"
            onClick={(e) => cancelForm(e)}
          >
            <span classID="btn-inner--text">Cancelar</span>
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
