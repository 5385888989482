import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createTraceabilityReports } from "src/infrastructure/redux/store/actions/traceabilityReports/traceabilityReportsAction";
import { TraceabilityReportsCreate } from "src/views/pages/traceabilityReports/TraceabilityReportsCreate";

export const CreateTraceabilityReports = () => {
  const dispatch = useDispatch();

  const create = useCallback(
    async (body) => {
      await dispatch(createTraceabilityReports(body));
    },
    [dispatch]
  );

  return <TraceabilityReportsCreate create={create} />;
};
