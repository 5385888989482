import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { useFormik } from "formik";
import "../../../assets/css/StorageInfoCard.css";
import { ListProcessDetail } from "src/containers/processDetail/ListProcessDetail";
import Selector from "../components/Selector";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanStorageUnitList,
  getStorageUnitList,
} from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { getProductsList } from "src/infrastructure/redux/store/actions/products/productsAction";
import { ProviderAPI } from "src/infrastructure/api/api-provider";
import { ClientAPI } from "src/infrastructure/api/api-client";

export const ProcessForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  processTemplate,
  selectData,
  dataNewProcess,
  readOnly = false,
}) => {
  const navigate = useNavigate();
  const [providersList, setProvidersList] = useState([]);
  const [ClientList, setClientList] = useState([]);
  // const [ProductList, setProductList] = useState([]);
  const [StorageUnitList, setStorageUnitList] = useState([]);
  const [storageUnitInfo, setStorageUnitInfo] = useState(false);
  const [storageUnitMovement, setStorageUnitMovement] = useState(false);
  const [btnDisabled, setBtn] = useState(false);

  const processTemplateName = processTemplate.name;

  useEffect(() => {
    if (processTemplate) {
      if (processTemplate.requireProvider) {
        selectData.getProviders();
      }
      if (processTemplate.requireClient) {
        selectData.getClient();
      }
    }
  }, []);

  useEffect(() => {
    setProvidersList(selectData.ProvidersList);
    setClientList(selectData.ClientList);
    setStorageUnitList(selectData.StorageUnitList);
    setStorageUnitMovement(selectData.StorageUnitLastMovement);
  }, [
    selectData.ProvidersList,
    selectData.ClientList,
    selectData.ProductList,
    selectData.StorageUnitList,
    selectData.StorageUnitLastMovement,
  ]);

  useEffect(() => {
    if (dataNewProcess !== null && dataNewProcess !== undefined) {
      navigate(`/admin/process/edit/${dataNewProcess?.id}`, {
        state: {
          change: true,
        },
      });
    }
  }, [dataNewProcess]);

  const formik = useFormik({
    initialValues: {
      name: elementData?.name?.trim() ? elementData.name : processTemplateName,
      processTemplateId: elementData
        ? elementData.processTemplateId
        : processTemplate.id,
      providerId: elementData ? elementData.providerId : null,
      clientId: elementData ? elementData.clientId : null,
      productId: elementData ? elementData.productId : null,
      storageUnitId: elementData ? elementData.storageUnitId : null,
      increases: elementData?.increases ?? "0",
      decreases: elementData?.decreases ?? "0",
      destinationStorageUnitId: elementData?.destinationStorageUnitId || null,
      transferNumber: elementData ? elementData.transferNumber : null,
      status: 1,
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        setBtn(true);
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/process");
      }
    },
  });

  const cancelForm = (event) => {
    navigate("/admin/process");
  };

  useEffect(() => {
    elementData && setStorageUnitInfo(elementData.storageUnit);
    elementData &&
      selectData.getStorageUnitLastMovement(elementData.storageUnit.id);
    elementData && setStorageUnitMovement(selectData.StorageUnitLastMovement);
  }, [elementData]);

  useEffect(() => {
    if (StorageUnitList?.data) {
      setStorageUnitInfo(
        StorageUnitList?.data?.find((element) => {
          return String(element.id) === formik.values.storageUnitId && element;
        })
      );
    }
    formik.values.storageUnitId &&
      selectData.getStorageUnitLastMovement(formik.values.storageUnitId);
  }, [formik.values.storageUnitId]);

  return (
    <>
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            {processTemplateName && (
              <>
                <label>Plantilla</label>
                <p>{processTemplateName}</p>
              </>
            )}
            {processTemplate.requireTransferNumber === 1 && (
              <FormGroup>
                <label htmlFor="transferNumber">Numero de Traslado</label>
                {readOnly ? (
                  <p>{formik.values.transferNumber}</p>
                ) : (
                  <Input
                    id="transferNumber"
                    name="transferNumber"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.transferNumber}
                    required={!elementData}
                  />
                )}
              </FormGroup>
            )}
            <FormGroup>
              <label htmlFor="name">Denominacion</label>
              {readOnly ? (
                <p>{formik.values.name}</p>
              ) : (
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  required={!elementData}
                />
              )}
            </FormGroup>
            {processTemplate?.requireProvider === 1 && (
              <FormGroup>
                <label htmlFor="providerId">Proveedor</label>
                {readOnly ? (
                  <p>{elementData?.provider?.businessName}</p>
                ) : (
                  <ProviderSelector
                    defaultValue={elementData?.provider?.businessName}
                    onValueChange={(value) => {
                      formik.setFieldValue("providerId", value);
                    }}
                  />
                )}
              </FormGroup>
            )}

            {processTemplate?.requireClient === 1 && (
              <FormGroup>
                <label htmlFor="clientId">Cliente</label>
                {readOnly ? (
                  <p>{elementData?.client?.businessName}</p>
                ) : (
                  <ClientSelector
                    defaultValue={elementData?.client?.businessName}
                    onValueChange={(value) => {
                      formik.setFieldValue("clientId", value);
                    }}
                  />
                )}
              </FormGroup>
            )}

            {processTemplate?.requireProduct === 1 && (
              <FormGroup>
                <label htmlFor="productId">Producto</label>
                {readOnly ? (
                  <p>{elementData?.product?.name}</p>
                ) : (
                  <ProductSelector
                    defaultValue={elementData?.product?.name}
                    onValueChange={(value) => {
                      formik.setFieldValue("productId", value);
                    }}
                  />
                )}
              </FormGroup>
            )}
            {processTemplate?.requireStorageUnit === 1 && (
              <>
                <FormGroup>
                  <label htmlFor="storageUnitId">
                    {processTemplate.requireDestinationStorageUnitId
                      ? "Unidad de Almacenamiento de Origen"
                      : "Unidad de Almacenamiento"}
                  </label>
                  {readOnly ? (
                    <p>{elementData?.storageUnitId}</p>
                  ) : (
                    <StorageUnitIdSelector
                      onValueChange={(value) => {
                        formik.setFieldValue("storageUnitId", value);
                      }}
                      defaultValue={elementData?.storageUnitId}
                    />
                  )}
                </FormGroup>
                <Card>
                  {storageUnitInfo && storageUnitMovement ? (
                    <CardBody>
                      <h4 className="">
                        Informacion de unidad de almacenamiento :
                      </h4>
                      <div className="info-card">
                        <div className="info-card-item" id="state">
                          <small>Estado : </small>
                          {storageUnitInfo.capacity ===
                          storageUnitMovement.balance ? (
                            <Badge color={"warning"} className="ml-2">
                              LLENO
                            </Badge>
                          ) : (
                            storageUnitInfo.capacity >
                              storageUnitMovement.balance && (
                              <Badge color={"success"} className="ml-2">
                                VACIO
                              </Badge>
                            )
                          )}
                        </div>
                        <div className="info-card-item" id="capacity">
                          <small>
                            Capacidad :{" "}
                            {`${storageUnitInfo.capacity} ${storageUnitInfo.unitOfMeasurement.abbreviation}`}
                          </small>
                        </div>
                        <div className="info-card-item" id="balance">
                          <small>
                            Contenido : {storageUnitMovement.balance}
                          </small>
                        </div>
                        <div className="info-card-item" id="unitOfMeasurement">
                          <small>
                            Unidad de medida :{" "}
                            {storageUnitInfo.unitOfMeasurement.name}
                          </small>
                        </div>
                      </div>
                    </CardBody>
                  ) : storageUnitInfo ? (
                    <CardBody>
                      <h4 className="">
                        Informacion de unidad de almacenamiento :
                      </h4>
                      <div className="info-card">
                        <div className="info-card-item">
                          <small>Estado : Error</small>
                        </div>
                        <div className="info-card-item">
                          <small>
                            Capacidad :{" "}
                            {`${storageUnitInfo.capacity} ${storageUnitInfo.unitOfMeasurement.abbreviation}`}
                          </small>
                        </div>
                        <div className="info-card-item">
                          <small>Contenido : Error</small>
                        </div>
                        <div className="info-card-item">
                          <small>
                            Unidad de medida :{" "}
                            {storageUnitInfo.unitOfMeasurement.name}
                          </small>
                        </div>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <h4 className="">
                        Informacion de unidad de almacenamiento :
                      </h4>
                      <p>
                        Seleccionar una unidad de almacenamiento para ver su
                        informacion.
                      </p>
                    </CardBody>
                  )}
                </Card>
              </>
            )}
            <FormGroup>
              {(processTemplate ? processTemplate.decreases === 1 : false) && (
                <div>
                  <label htmlFor="decreases">Disminuye</label>
                  {readOnly ? (
                    <p>{formik.values.decreases}</p>
                  ) : (
                    <Input
                      type="number"
                      min={1}
                      name="decreases"
                      onChange={formik.handleChange}
                      value={formik.values.decreases}
                      required
                    />
                  )}
                </div>
              )}
            </FormGroup>
            {processTemplate.requireDestinationStorageUnitId === 1 && (
              <FormGroup>
                <label htmlFor="destinationStorageUnitId">
                  Unidad de Almacenamiento de Destino
                </label>
                {readOnly ? (
                  <p>{elementData?.destinationStorageUnitId}</p>
                ) : (
                  <StorageUnitIdSelector
                    onValueChange={(value) => {
                      formik.setFieldValue("destinationStorageUnitId", value);
                    }}
                    defaultValue={elementData?.destinationStorageUnitId}
                  />
                )}
              </FormGroup>
            )}
            <FormGroup>
              {(processTemplate ? processTemplate.increases === 1 : false) && (
                <div>
                  <label htmlFor="increases">Incrementa</label>
                  {readOnly ? (
                    <p>{formik.values.increases}</p>
                  ) : (
                    <Input
                      type="number"
                      min={1}
                      name="increases"
                      onChange={formik.handleChange}
                      value={formik.values.increases}
                      required
                    />
                  )}
                </div>
              )}
            </FormGroup>
            {!readOnly && (
              <>
                <Button
                  classID="btn-icon btn-3"
                  color="primary"
                  type="submit"
                  disabled={btnDisabled}
                >
                  {!btnDisabled ? (
                    <span classID="btn-inner--text">Guardar</span>
                  ) : (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </Button>
                <Button
                  classID="btn-icon btn-3"
                  color="warning"
                  type="button"
                  onClick={(e) => cancelForm(e)}
                >
                  <span classID="btn-inner--text">Cancelar</span>
                </Button>
              </>
            )}
          </Form>
        </CardBody>
      </Card>
      {id && <ListProcessDetail nameForm={nameForm} processId={id} />}
    </>
  );
};

function StorageUnitIdSelector({ onValueChange, defaultValue }) {
  const [storageUnits, setStorageUnits] = useState(null);
  const initQueryParams = {
    offset: 0,
    limit: 10,
  };
  const queryParamsRef = useRef(initQueryParams);
  const dispatch = useDispatch();

  const storageUnitList = useSelector(
    (store) => store.storageUnit.StorageUnitList
  );

  const handleGetStorageUnitList = useCallback(
    async (specification) => {
      await dispatch(getStorageUnitList(specification));
    },
    [dispatch]
  );

  const handleOpenSelector = () => {
    setStorageUnits(null);
    queryParamsRef.current = initQueryParams;
    handleGetStorageUnitList(queryParamsRef.current);
  };

  const fetchMoreStorageUnits = () => {
    const qp = queryParamsRef.current;
    queryParamsRef.current.offset = qp.offset + qp.limit;
    handleGetStorageUnitList(queryParamsRef.current);
  };

  useEffect(() => {
    if (queryParamsRef.current.offset === 0) {
      setStorageUnits(storageUnitList?.data);
      return;
    }
    setStorageUnits((state) => [
      ...(state ?? []),
      ...(storageUnitList?.data ?? []),
    ]);
  }, [storageUnitList]);

  return (
    <Selector
      items={storageUnits?.map((element) => ({
        id: element.id,
        name: element.number,
      }))}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      onFetchMoreData={fetchMoreStorageUnits}
      handleClose={() => {
        dispatch(cleanStorageUnitList());
        setStorageUnits(null);
        queryParamsRef.current = initQueryParams;
      }}
      itemsTotalCount={storageUnitList?.totalCount ?? 0}
      handleOpen={handleOpenSelector}
    />
  );
}

function ProductSelector({ onValueChange, defaultValue }) {
  const [products, setProducts] = useState([]);
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 10,
  });
  const dispatch = useDispatch();

  const productsList = useSelector((store) => store.products.productsList);

  const handleGetProductsList = useCallback(
    async (specification) => {
      await dispatch(getProductsList(specification));
    },
    [dispatch]
  );

  const fetchMoreProducts = (e) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    handleGetProductsList(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.offset === 0) {
      setProducts(productsList?.data);
      return;
    }
    setProducts((state) => [...state, ...productsList?.data]);
  }, [productsList]);

  return (
    <Selector
      items={products}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      onFetchMoreData={fetchMoreProducts}
      itemsTotalCount={productsList?.totalCount ?? 0}
    />
  );
}

function ProviderSelector({ onValueChange, defaultValue }) {
  const proderApi = new ProviderAPI();
  const [providers, setProviders] = useState(null);
  const [providersMetadata, setProvidersMetadata] = useState();
  const initialParams = {
    offset: 0,
    limit: 10,
  };
  const [queryParams, setQueryParams] = useState(initialParams);

  const handleGetProductsList = async (params) => {
    try {
      const { data: resData } = await proderApi.getProvidersApi(params);

      if (params.offset === 0) setProviders(resData.data);
      else setProviders((state) => [...state, ...resData.data]);

      setProvidersMetadata({
        totalCount: resData.totalCount,
        count: resData.count,
      });
    } catch (error) {}
  };

  const fetchMoreProducts = (e) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    if (providers === null) return;
    handleGetProductsList(queryParams);
  }, [queryParams]);

  return (
    <Selector
      items={
        providers?.map((element) => ({
          id: element.id,
          name: element.businessName,
        })) ?? []
      }
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      onFetchMoreData={fetchMoreProducts}
      handleOpen={() => handleGetProductsList(queryParams)}
      handleClose={() => {
        setProviders(null);
        setQueryParams(initialParams);
      }}
      itemsTotalCount={providersMetadata?.totalCount ?? 0}
    />
  );
}

function ClientSelector({ onValueChange, defaultValue }) {
  const clientApi = new ClientAPI();
  const [clients, setClients] = useState(null);
  const [clientsMetadata, setClientsMetadata] = useState();
  const initialParams = {
    offset: 0,
    limit: 10,
  };
  const [queryParams, setQueryParams] = useState(initialParams);

  // const [defaultVal, setDefaultVal] = useState("");

  const handleGetClientsList = async (params) => {
    try {
      const { data: resData } = await clientApi.getClientsApi(params);

      if (params.offset === 0) setClients(resData.data);
      else setClients((state) => [...state, ...resData.data]);

      setClientsMetadata({
        totalCount: resData.totalCount,
        count: resData.count,
      });
    } catch (error) {}
  };

  const fetchMoreClients = (e) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    if (clients === null) return;
    handleGetClientsList(queryParams);
  }, [queryParams]);

  // useEffect(() => {
  //   if (defaultValue) {
  //     // const { data: resData } =
  //     clientApi
  //       .getProvidersApi({
  //         offset: 0,
  //         limit: 10000,
  //       })
  //       .then(({ data: resData }) => {
  //         setDefaultVal(
  //           resData.data.find((element) => element.id === defaultValue)
  //             ?.businessName
  //         );
  //       });
  //   }
  // }, [defaultValue]);

  // {id: 1, name: "name"}
  return (
    <Selector
      items={
        clients?.map((element) => ({
          id: element.id,
          name: element.businessName,
        })) ?? []
      }
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      onFetchMoreData={fetchMoreClients}
      handleOpen={() => handleGetClientsList(queryParams)}
      handleClose={() => {
        setClients(null);
        setQueryParams(initialParams);
      }}
      itemsTotalCount={clientsMetadata?.totalCount ?? 0}
    />
  );
}
