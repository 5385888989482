import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProcessTemplateList } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";

import { ProcessTemplateCategoryCreate } from "src/views/pages/processTemplateCategory/ProcessTemplateCategoryCreate";
import { createProcessTemplateCategory } from "src/infrastructure/redux/store/actions/processTemplateCategory/processTemplateCategoryAction";

export const CreateProcessTemplateCategory = () => {
  const dispatch = useDispatch();

  const create = useCallback(
    async (body) => {
      await dispatch(createProcessTemplateCategory(body));
    },
    [dispatch]
  );

  const getProcessTemplate = useCallback(
    async (specification) => {
      await dispatch(getProcessTemplateList(specification));
    },
    [dispatch]
  );

  const processTemplateList = useSelector(
    (store) => store.processTemplate.processTemplateList
  );

  const isLoadingProcessTemplate = useSelector(
    (store) => store.processTemplate.isLoadingProcessTemplate
  );

  const dataNewProcessTemplate = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryCreated
  );

  return (
    <ProcessTemplateCategoryCreate
      create={create}
      getProcessTemplate={getProcessTemplate}
      processTemplateList={processTemplateList}
      isLoadingProcessTemplate={isLoadingProcessTemplate}
      dataNewProcessTemplate={dataNewProcessTemplate}
    />
  );
};
