
export const getSelectProvincesEditUser = async (element, getProvincesByID, setCountrySelected, getLocality) => {
  await getProvincesByID(element.country.id);
  setCountrySelected(element.country.name);
    await getLocality(element.province.id);

};

export  const getSelectedLocalityEditUser = async (id, getLocality) => {
  await getLocality(id);  
};



export const getSeleccionaLocalidad = (e, formik, setLocalitySelect) => {

  setLocalitySelect(e?.target.value);
  formik.setFieldValue("localityId", Number(e?.target.value));
};

/*  export const getSelectedLocality = async (id, setProvinceSelect, getLocality) => {
  setProvinceSelect(Number(id?.target.value));
  await getLocality(id?.target.value);
}; */

export const getSelectProvinces = async (e, getProvincesByID, setCountryData, getLocality) => {
  await getProvincesByID(e?.target.value);
  let index = e?.target.selectedIndex;
  setCountryData(e?.target.options[index].text);
  if (!e?.target.value) {
    await getLocality(e?.target.value);
  }
};