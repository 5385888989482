import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ClientForm } from "src/views/pages/clients/ClientForm";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ClientUpdate = ({
  update,
  getClientById,
  getProvincesByID,
  getLocalities,
  getLocality,
  loading,
  clientFound,
  countryList,
  provinceList,
  localitiesList,
  localitySelected,
}) => {
  const { id } = useParams();
  useEffect(() => {
    getClientById(id);
    getLocalities();
  }, [id, getClientById, getLocalities]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Cliente" parentName="Admin"></SimpleHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Container fluid>
          <h2>{"Editar Cliente"}</h2>
          <ClientForm
            id={id}
            elementData={clientFound}
            update={update}
            create={null}
            nameForm={"edit"}
            getProvincesByID={getProvincesByID}
            getLocality={getLocality}
            countryList={countryList}
            provinceList={provinceList}
            localityList={localitiesList}
            localitySelected={localitySelected}
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
