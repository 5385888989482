import { CLIENT_ERRORS } from "../../actions/client/clientAction";
import { USER_ERRORS } from "../../actions/users/usersAction";
import { PROVIDER_ERRORS } from "../../actions/provider/providersAction";
import { MOVEMENTS_ERRORS } from "../../actions/movements/movementsAction";
import { PROCESS_ERRORS } from "../../actions/process/processAction";
import { PROCESS_TEMPLATE_ERRORS } from "../../actions/processTemplate/processTemplateAction";
import { PROCESS_TEMPLATE_CATEGORY_ERRORS } from "../../actions/processTemplateCategory/processTemplateCategoryAction";
import { PRODUCTS_ERRORS } from "../../actions/products/productsAction";
import { PRODUCT_TYPE_ERRORS } from "../../actions/productType/productTypeAction";
import { PROVIDER_TYPE_ERRORS } from "../../actions/providerType/providerTypeAction";
import { STORAGE_UNIT_ERRORS } from "../../actions/storageUnit/storageUnitAction";
import { UNIT_OF_MEASUREMENT_ERRORS } from "../../actions/unitOfMeasurement/unitOfMeasurementAction";

const initialState = {
    clientErrors: null,
    providerErrors: null,
    userErrors: null,
    movementErrors: null,
    processErrors: null,
    processTemplateErrors: null,
    processTemplateCategoryErrors: null,
    productsErrors: null,
    productTypeErrors: null,
    providerTypeErrors: null,
    storageUnitErrors: null,
    unitOfMeasurementErrors: null,
}



export const errorsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CLIENT_ERRORS:
            return { ...state, clientErrors: payload }
        case PROVIDER_ERRORS:
            return { ...state, providerErrors: payload }
        case USER_ERRORS:
            return { ...state, userErrors: payload }
        case MOVEMENTS_ERRORS:
            return { ...state, movementErrors: payload }
        case PROCESS_ERRORS:
            return { ...state, processErrors: payload }
        case PROCESS_TEMPLATE_ERRORS:
            return { ...state, processTemplateErrors: payload }
        case PROCESS_TEMPLATE_CATEGORY_ERRORS:
            return { ...state, processTemplateCategoryErrors: payload }
        case PRODUCTS_ERRORS:
            return { ...state, productsErrors: payload }
        case PRODUCT_TYPE_ERRORS:
            return { ...state, productTypeErrors: payload }
        case PROVIDER_TYPE_ERRORS:
            return { ...state, providerTypeErrors: payload }
        case STORAGE_UNIT_ERRORS:
            return { ...state, storageUnitErrors: payload }
        case UNIT_OF_MEASUREMENT_ERRORS:
            return { ...state, unitOfMeasurementErrors: payload }

        default:
            return state
    }
}