import React from "react";
import { CategoryDetailModal } from "./CategoryDetailModal";

export const ProcessTemplateCategoryDetailCreate = ({
  modal,
  toggleModal,
  getUnitOfMeasurement,
  unitOfMeasurement,
  create,
  processTemplateCategory,
  categoryId,
}) => {
  React.useEffect(() => {
    getUnitOfMeasurement();
  }, []);

  return (
    <>
      {categoryId && (
        <CategoryDetailModal
          create={create}
          update={null}
          elementData={null}
          modalState={modal}
          toggleModal={toggleModal}
          nameForm={"create"}
          unitOfMeasurementModal={unitOfMeasurement?.data?.filter(
            (element) => element.deletedAt === null
          )}
          processTemplateCategoryId={categoryId ?? processTemplateCategory?.id}
        />
      )}
    </>
  );
};
