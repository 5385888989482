import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { UsersForm } from "src/views/pages/users/UsersForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const UsersUpdate = ({
  UsersById,
  UsersFound,
  update,
  getRoles,
  rolesList,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      UsersById(id);
      getRoles();
      if (UsersFound) {
        setLoading(false);
      }
    }
  }, [id, UsersById, UsersFound]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Usuarios" parentName="Admin"></SimpleHeader>
      {!loading && UsersFound && (
        <Container fluid>
          <h2>{"Editar Usuarios"}</h2>
          <UsersForm
            elementData={UsersFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
            rolesList={rolesList}
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
