import { ProviderTypeAPI } from "src/infrastructure/api/api-providerType";
import { fetchStopLoading, fetchStartLoading } from '../loading/loadingAction';
import { setErrorMessages } from "../errors/errorsAction";

//#region ProviderType Const
export const CREATE_PROVIDER_TYPE = 'CREATE_PROVIDER_TYPE';
export const GET_PROVIDER_TYPE_LIST = 'GET_PROVIDER_TYPE_LIST';
export const GET_PROVIDER_TYPE_ID = 'GET_PROVIDER_TYPE_ID';
export const UPDATE_PROVIDER_TYPE = 'UPDATE_PROVIDER_TYPE';
export const DELETE_PROVIDER_TYPE = 'DELETE_PROVIDER_TYPE';
export const PROVIDER_TYPE_ERRORS = 'PROVIDER_TYPE_ERRORS';
//#endregion

//#region ProviderType Actions

// Create ProviderType
export const fetchCreateProviderType = response => ({
    type: CREATE_PROVIDER_TYPE,
    payload: response.data,
});

// Get list of providerType
export const fetchGetProviderTypeList = response => ({
    type: GET_PROVIDER_TYPE_LIST,
    payload: response.data.data
})

export const fetchProviderTypeById = response => ({
    type: GET_PROVIDER_TYPE_ID,
    payload: response.data
})

export const fetchUpdateProviderType = response => ({
    type: UPDATE_PROVIDER_TYPE,
    payload: response
})

export const fetchDeleteProviderType = response => ({
    type: DELETE_PROVIDER_TYPE,
    payload: response
})

//#endregion

const providerTypeAPI = new ProviderTypeAPI()

//#region ProviderType Dispatch

// Dispatch create providerType
export const createProviderType = (providerTypeBody) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchCreateProviderType(await providerTypeAPI.createProviderTypeApi(providerTypeBody)));
    } catch (error) {
        dispatch(setErrorMessages(PROVIDER_TYPE_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
};

// Dispatch get providerType list
export const getProviderTypeList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchGetProviderTypeList(await providerTypeAPI.getProviderTypeApi(specification)))
    } catch (error) {
        dispatch(setErrorMessages(PROVIDER_TYPE_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get providerType by id
export const getProviderTypeById = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchProviderTypeById(await providerTypeAPI.getProviderTypeByIdApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(PROVIDER_TYPE_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update providerType
export const updateProviderType = (body, id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchUpdateProviderType(await providerTypeAPI.updateProviderTypeApi(body, id)))
    } catch (error) {
        dispatch(setErrorMessages(PROVIDER_TYPE_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete providerType
export const deleteProviderType = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchDeleteProviderType(await providerTypeAPI.deleteProviderTypeApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(PROVIDER_TYPE_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}
//#endregion
