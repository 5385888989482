import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessTemplateCategoryForm } from "src/views/pages/processTemplateCategory/ProcessTemplateCategoryForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ProcessTemplateCategoryUpdate = ({
  update,
  resetCreate,
  getProcessTemplateCategoryById,
  processTemplateCategory,
  getProcessTemplate,
  processTemplateList = [],
  isLoadingProcessTemplate,
}) => {
  const { id } = useParams();
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 10,
  });

  const [stackProcessTemplate, setStackProcessTemplate] = useState([]);

  const fetchMoreProcessTemplate = () => {
    if (isLoadingProcessTemplate) return;
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    getProcessTemplate(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.offset === 0) {
      setStackProcessTemplate(processTemplateList?.data);
      return;
    }
    setStackProcessTemplate((state) => [
      ...state,
      ...processTemplateList?.data,
    ]);
  }, [processTemplateList]);

  useEffect(() => {
    getProcessTemplateCategoryById(id);
  }, [id]);

  return (
    <>
      <SimpleHeader
        name="Categoría de platilla de proceso"
        parentName="Admin"
      ></SimpleHeader>
      <Container fluid>
        <h2>{"Editar Categoria de Plantilla de Proceso"}</h2>
        <ProcessTemplateCategoryForm
          elementData={processTemplateCategory}
          nameForm={"edit"}
          id={id}
          create={null}
          update={update}
          processTemplate={{
            ...processTemplateList,
            data: stackProcessTemplate,
          }}
          onFetchMoreProcessTemplate={fetchMoreProcessTemplate}
        />
      </Container>
    </>
  );
};
