export const traceabilityReportTypes = [
  { id: 2, name: "Ascendente" },
  { id: 1, name: "Descendente" },
];

export const dataTraceabilityReports = {
  columns: [
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
      formatter: (_, row) => (
        <>{traceabilityReportTypes.find((l) => l.id === row.type)?.name}</>
      ),
    },
    {
      dataField: "participants",
      text: "Participantes",
      sort: true,
    },
    {
      dataField: "transferNumber",
      text: "Número de Traslado",
      sort: true,
    },
    {
      dataField: "batchNumber",
      text: "Número de Lote",
      sort: true,
    },
  ],
};
