import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProviderTypeForm } from "src/views/pages/providerType/ProviderTypeForm";

export const ProviderTypeUpdate = ({
  providerTypeById,
  providerTypeFound,
  update,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      providerTypeById(id);
      setLoading(false);
    }
  }, [id, providerTypeById, loading]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Tipo de Proveedor" parentName="Admin"></SimpleHeader>
      {!loading && (
        <Container fluid>
          <h2>{"Editar Tipo de Proveedor"}</h2>
          <ProviderTypeForm
            elementData={providerTypeFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
          />
        </Container>
      )}
    </main>
  );
};
