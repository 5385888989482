import React, { useCallback } from 'react'
import { LoginUserApi, PasswordRecoveryApi } from 'src/infrastructure/redux/store/actions/authentication/authAction'
import { Login } from 'src/views/pages/userPages/Login'
import { useDispatch, useSelector } from 'react-redux';

export const AuthLogin = () => {

    const dispatch = useDispatch()

    const LoginUser = useCallback(async (credentials) => {
        await dispatch(LoginUserApi(credentials, dispatch));
    }, [dispatch]);

    const PasswordRecovery = useCallback(async (body) => {
        await dispatch(PasswordRecoveryApi(body));
    }, [dispatch]);

    const loginErrors = useSelector(store => store.login.loginErrors)

    return <Login
        login={LoginUser}
        recovery={PasswordRecovery}
        loginErrors={loginErrors}
    />
}