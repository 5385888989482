import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductTypeCreate } from "src/views/pages/productType/ProductTypeCreate";
import { createProductType } from "src/infrastructure/redux/store/actions/productType/productTypeAction";

export const CreateProductType = () => {

  const dispatch = useDispatch();

  const create = useCallback(
    async (body) => {
      await dispatch(createProductType(body));
    },
    [dispatch]
  );

  const loading = useSelector((store) => store.loading.loading);

  return <ProductTypeCreate create={create} loading={loading} />;
};
