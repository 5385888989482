import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProcess } from "src/infrastructure/redux/store/actions/process/processAction";
import { getProcessTemplateById } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { ProcessCreate } from "src/views/pages/process/ProcessCreate";
import { getProviderList } from "src/infrastructure/redux/store/actions/provider/providersAction";
import { getClientList } from "src/infrastructure/redux/store/actions/client/clientAction";
import { getProductsList } from "src/infrastructure/redux/store/actions/products/productsAction";
import { getStorageUnitLastMovement } from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { useParams } from "react-router-dom";
import { ProcessTemplateAPI } from "src/infrastructure/api/api-processTemplate";

export const CreateProcess = () => {
  const dispatch = useDispatch();
  const { processTemplateId } = useParams();
  const [processTemplate, setProcessTemplate] = useState();
  const [isLoadingProcessTemplate, setIsLoadingProcessTemplate] =
    useState(true);

  useEffect(() => {
    if (!processTemplateId) return;
    const { getProcessTemplateByIdApi } = new ProcessTemplateAPI();

    getProcessTemplateByIdApi(processTemplateId)
      .then((res) => {
        setProcessTemplate(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProcessTemplate(false);
      });
  }, [processTemplateId]);

  const create = useCallback(
    async (body) => {
      await dispatch(createProcess(body));
    },
    [dispatch]
  );

  const processTemplateById = useCallback(
    async (idProcessTemplate) => {
      await dispatch(getProcessTemplateById(idProcessTemplate));
    },
    [dispatch]
  );

  const dataNewProcess = useSelector((store) => store.process.processCreated);

  const selectForms = {
    validateValues: () => {
      console.log(this.ProvidersList);
    },
    getProviders: useCallback(
      async (specification) => {
        await dispatch(getProviderList(specification));
      },
      [dispatch]
    ),
    getClient: useCallback(
      async (specification) => {
        await dispatch(getClientList(specification));
      },
      [dispatch]
    ),
    getProduct: useCallback(
      async (specification) => {
        await dispatch(getProductsList(specification));
      },
      [dispatch]
    ),
    getStorageUnitLastMovement: useCallback(
      async (specification) => {
        await dispatch(getStorageUnitLastMovement(specification));
      },
      [dispatch]
    ),
    ProvidersList: useSelector((store) => store.provider.providerList),
    ClientList: useSelector((store) => store.client.clientList),
    ProductList: useSelector((store) => store.products.productsList),
    StorageUnitLastMovement: useSelector(
      (store) => store.storageUnit.StorageUnitLastMovement
    ),
  };

  return (
    <ProcessCreate
      create={create}
      processTemplate={processTemplate}
      isLoadingProcessTemplate={isLoadingProcessTemplate}
      selectData={selectForms}
      dataNewProcess={dataNewProcess}
    />
  );
};
