import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Users } from 'src/views/pages/users/Users'
import { getUsersList } from 'src/infrastructure/redux/store/actions/users/usersAction';
import { deleteUsers } from 'src/infrastructure/redux/store/actions/users/usersAction';
import { resetErrorMessages } from 'src/infrastructure/redux/store/actions/errors/errorsAction'

export const ListUsers = () => {

    const dispatch = useDispatch();

    const getUsers = useCallback(async (specification) => {
        await dispatch(getUsersList(specification));
    }, [dispatch]);

    const usersToDelete = useCallback(async (id) => {
        await dispatch(deleteUsers(id));
    }, [dispatch]);

    const resetErrors = useCallback(async () => {
        await dispatch(resetErrorMessages('USER_ERRORS'));
    }, [dispatch]);

    const usersList = useSelector(store => store.users.UsersList)
    const usersCreated = useSelector(store => store.users.UsersCreated)
    const usersUpdated = useSelector(store => store.users.UsersUpdated)
    const usersDeleted = useSelector(store => store.users.UsersDeleted)
    const usersFound = useSelector(store => store.users.UsersFound)
    const usersErrors = useSelector(store => store.errors.userErrors)
    const loading = useSelector(store => store.loading.loading)

    return <Users
        getUsers={getUsers}
        deleteUsers={usersToDelete}
        resetErrors={resetErrors}
        loading={loading}
        usersList={usersList}
        usersCreated={usersCreated}
        usersUpdated={usersUpdated}
        usersDeleted={usersDeleted}
        usersFound={usersFound}
        usersErrors={usersErrors}
    />
}
