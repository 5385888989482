export const dataStorageUnit = {
    columns: [
        {
            dataField: "number",
            text: "Número/Nombre",
            sort: true,
        },
        {
            dataField: "capacity",
            text: "Capacidad",
            sort: true,
        },
        {
            dataField: "unitOfMeasurement.name",
            text: "Unidad de medida",
            sort: true,
        },
        {
            dataField: "observations",
            text: "Observaciones",
            sort: true,
        }
    ]
};