/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataClients } from "src/variables/clients";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import { LocalityAPI } from "src/infrastructure/api/api-localities.js";
import { ProvincesAPI } from "src/infrastructure/api/api- provinces.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
  expand: 'locality'
};

export const Client = ({
  getClients,
  deleteClients,
  resetErrors,
  loading,
  clientsList,
  deletedClient,
  updatedClient,
  createdClient,
  foundClient,
  clientsErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(clientsList?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getClients(queryParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [deletedClient, updatedClient, createdClient, foundClient, queryParams]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    clientsErrors ? setAlert(true) : setAlert(false);
  }, [clientsErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={clientsErrors} state={alert} />
      <SimpleHeader
        name="Clientes"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Clientes"
            dataTable={clientsList?.data ?? []}
            columnsTable={dataClients.columns}
            deleteElement={deleteClients}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <ClientFilters
                businessName={queryParams.businessName}
                onBusinessNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    businessName: e.target.value,
                  });
                }}
                registrationNumber={queryParams.registrationNumber}
                onRegistrationNumberChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    registrationNumber: e.target.value,
                  });
                }}
                onLocalyChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    localityId: e.target.value,
                  });
                }}
                onTypeChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    type: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const ClientFilters = ({
  businessName,
  registrationNumber,
  onBusinessNameChange,
  onRegistrationNumberChange,
  onLocalyChange,
  onTypeChange,
}) => {
  const { getLocalitiesApi } = new LocalityAPI();
  const { getProvincesByCountryID } = new ProvincesAPI();

  const [provinces, setProvinces] = useState([]);
  const [localities, setLocalities] = useState([]);

  const onProvincesChange = (e) => {
    getLocalitiesApi({
      provinceId: e.target.value,
      limit: 200,
    }).then((result) => {
      setLocalities(result.data.data);
    });
  };

  useEffect(() => {
    getProvincesByCountryID(1).then((result) => {
      setProvinces(result.data.data);
    });
  }, []);

  return (
    <div className="col-12 d-flex justify-content-between">
      <input
        className="form-control"
        placeholder="Razón Social"
        type="businessName"
        value={businessName ?? ""}
        onChange={onBusinessNameChange}
      />
      <input
        className="ml-4 form-control"
        placeholder="Número de Inscripto"
        type="registrationNumber"
        value={registrationNumber ?? ""}
        onChange={onRegistrationNumberChange}
      />
      <select
        name="provincia"
        className="ml-4 form-control"
        onChange={onProvincesChange}
      >
        <option value="">Provincia</option>
        {provinces.map((p) => (
          <option key={p.id} value={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      <select
        name="localidad"
        className="ml-4 form-control"
        onChange={onLocalyChange}
      >
        <option value="">Localidad</option>
        {localities.map((l) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>
      <select
        name="localidad"
        className="ml-4 form-control"
        onChange={onTypeChange}
      >
        <option value="">Tipo de mercado</option>
        <option value={1}>Bodega</option>
        <option value={2}>Viñedo</option>
      </select>
    </div>
  );
};
