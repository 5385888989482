import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProcessTemplateCategoryAPI {

    createProcessTemplateCategoryApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategory:18 => Error: ', error)
            throw (error)
        }
    };

    getProcessTemplateCategoryApi = async (specification = {}) => {
        try {
            let url = `${BASE_URL}/process-template-category`
            // url += '?deletedAt=null' + (new URLSearchParams(specification)).toString();
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategory:35 => Error: ', error)
            throw (error)
        }
    }

    getProcessTemplateCategoryByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategory:51 => Error: ', error)
            throw (error)
        }
    }

    updateProcessTemplateCategoryApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategory:70 => Error: ', error)
            throw (error)
        }
    };

    deleteProcessTemplateCategoryApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategory:87 => Error: ', error)
            throw (error)
        }
    }

}


