import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class ClientAPI {
  createClientApi = async (body, expand) => {

    

    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(`${BASE_URL}/clients`, options);

      return response;
    } catch (error) {
      console.log("api-client:18 => Error: ", error.params.message);
      throw error;
    }
  };

  getClientsApi = async (specification = {}) => {
    try {
      let url = new URL(`${BASE_URL}/clients`);
      url.searchParams.append("deletedAt", "null");
      Object.entries(specification).forEach(([key, value]) => {
        if (!value) return;
        url.searchParams.append(key, value);
      })

      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);

      return response;
    } catch (error) {
      console.log("api-client:35 => Error: ", error);
      throw error;
    }
  };

  getClientByIdApi = async (id, expand) => {
    if (expand) {
      expand = '?expand=' + expand
    }
    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/clients/${id}${expand}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-client:51 => Error: ", error);
      throw error;
    }
  };

  updateClientApi = async (id, body) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/clients/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-client:70 => Error: ", error);
      throw error;
    }
  };

  deleteClientApi = async (id) => {
    try {
      const options = {
        method: "DELETE",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/clients/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-client:87 => Error: ", error);
      throw error;
    }
  };
}
