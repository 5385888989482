import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProcessTemplate } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { ProcessTemplateCreate } from "src/views/pages/processTemplate/ProcessTemplateCreate";
import { getUnitOfMeasurementList } from "src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction"

export const CreateProcessTemplate = () => {

    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createProcessTemplate(body));
    }, [dispatch]);

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)

    return (
        <ProcessTemplateCreate
            create={create}
            getUnitOfMeasurement={getUnitOfMeasurement}
            unitOfMeasurementsList={unitOfMeasurementsList}
        />
    )
}



