import React, { useEffect, useState } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { TraceabilityReportsForm } from "./TraceabilityReportsForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const TraceabilityReportsCreate = ({ create }) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader
        name="Reporte de Trazabilidad"
        parentName="Admin"
      ></SimpleHeader>
      <Container fluid>
        <h2>{"Crear Reporte de Trazabilidad"}</h2>
        <TraceabilityReportsForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
        />
      </Container>
      <AdminFooter />
    </main>
  );
};
