import {
  CREATE_UNIT_OF_MEASUREMENT,
  GET_UNIT_OF_MEASUREMENT_LIST,
  GET_UNIT_OF_MEASUREMENT_ID,
  UPDATE_UNIT_OF_MEASUREMENT,
  DELETE_UNIT_OF_MEASUREMENT,
  UNIT_OF_MEASUREMENT_ERRORS,
} from "../../actions/unitOfMeasurement/unitOfMeasurementAction";

const initialState = {
  UnitOfMeasurementCreated: null,
  UnitOfMeasurementList: null,
  UnitOfMeasurementFound: null,
  UnitOfMeasurementUpdated: null,
  UnitOfMeasurementDeleted: null,
  UnitOfMeasurementErrors: null,
};

export const unitOfMeasurementReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_UNIT_OF_MEASUREMENT:
      return { ...state, UnitOfMeasurementCreated: payload };
    case GET_UNIT_OF_MEASUREMENT_LIST:
      return { ...state, UnitOfMeasurementList: payload };
    case GET_UNIT_OF_MEASUREMENT_ID:
      return { ...state, UnitOfMeasurementFound: payload.data };
    case UPDATE_UNIT_OF_MEASUREMENT:
      return { ...state, UnitOfMeasurementUpdated: payload };
    case DELETE_UNIT_OF_MEASUREMENT:
      return { ...state, UnitOfMeasurementDeleted: payload };
    case UNIT_OF_MEASUREMENT_ERRORS:
      return { ...state, UnitOfMeasurementErrors: payload };
    default:
      return state;
  }
};
