import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UsersCreate } from "src/views/pages/users/UsersCreate";
import { createUsers } from 'src/infrastructure/redux/store/actions/users/usersAction';
import { getRolesList } from "src/infrastructure/redux/store/actions/roles/rolesAction";

export const CreateUsers = () => {

    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createUsers(body));
    }, [dispatch]);

    const getRoles = useCallback(async (body) => {
        await dispatch(getRolesList(body));
    }, [dispatch]);

    const rolesList = useSelector(store => store.roles.RolesList)

    return (
        <UsersCreate
            create={create}
            getRoles={getRoles}
            rolesList={rolesList}
        />
    )

}


