import { ProductsAPI } from "src/infrastructure/api/api-products";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region Products Const
export const CREATE_PRODUCTS = "CREATE_PRODUCTS";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const SET_PRODUCTS_FOUND = "SET_PRODUCTS_FOUND";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const PRODUCTS_ERRORS = "PRODUCTS_ERRORS";
export const IS_LOADING_PRODUCTS = "IS_LOADING_PRODUCTS";
//#endregion

//#region Products Actions

export const fetchCreateProducts = (response) => ({
  type: CREATE_PRODUCTS,
  payload: response.data,
});

export const setProductList = (response) => ({
  type: SET_PRODUCTS_LIST,
  payload: response,
});

export const setProductFound = (payload) => ({
  type: SET_PRODUCTS_FOUND,
  payload,
});

export const fetchUpdateProducts = (response) => ({
  type: UPDATE_PRODUCTS,
  payload: response,
});

export const fetchDeleteProducts = (response) => ({
  type: DELETE_PRODUCTS,
  payload: response,
});

export const handleIsLoadingProducts = (isLoading) => ({
  type: IS_LOADING_PRODUCTS,
  payload: isLoading,
});

//#endregion

const productsAPI = new ProductsAPI();

//#region Products Dispach

// Dispatch create products
export const createProducts = (productsBody) => async (dispatch) => {
  try {
    dispatch(
      fetchCreateProducts(await productsAPI.createProductsApi(productsBody))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCTS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get products list
export const getProductsList = (specification) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading());
    handleIsLoadingProducts(true);
    const response = await productsAPI.getProductsApi(specification);
    dispatch(setProductList(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PRODUCTS_ERRORS, error));
  } finally {
    handleIsLoadingProducts(false);
    dispatch(fetchStopLoading());
  }
};

export const cleanProductList = () => async (dispatch) => {
  dispatch(setProductList(null));
};

// Dispatch get products by id
export const getProductById = (id, expand) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await productsAPI.getProductByIdApi(id, expand);
    dispatch(setProductFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PRODUCTS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProductFound = () => async (dispatch) => {
  dispatch(setProductFound(null));
};

// Dispatch update products
export const updateProducts = (id, body) => async (dispatch) => {
  try {
    dispatch(
      fetchUpdateProducts(await productsAPI.updateProductsApi(id, body))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCTS_ERRORS, error));
  }
};

// Dispatch delete products
export const deleteProducts = (id) => async (dispatch) => {
  try {
    dispatch(fetchDeleteProducts(await productsAPI.deleteProductsApi(id)));
  } catch (error) {
    dispatch(setErrorMessages(PRODUCTS_ERRORS, error));
  }
};
//#endregion
