export const dataProcessTemplateCategory = {
    columns: [
        {
            dataField: "name",
            text: "denominacion",
            sort: true,
        },
        {
            dataField: "enable",
            text: "Habilitado",
            sort: true,
        }
    ]
}