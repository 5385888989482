export const dataPlantillaProceso = {
    data: [],
    columns: [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "name",
            text: "Denominacion",
            sort: true,
        },
        {
            dataField: "requireProvider",
            text: "Requiere Proveedor",
            sort: true,
        },
        {
            dataField: "requireClient",
            text: "Requiere Cliente",
            sort: true,
        },
        {
            dataField: "requireProduct",
            text: "Requiere Producto",
            sort: true,
        },
        {
            dataField: "requireStorageUnit",
            text: "Requiere Unidad de almacenamiento",
            sort: true,
        },
        {
            dataField: "increases",
            text: "Incrementa",
            sort: true,
        },
        {
            dataField: "decreases",
            text: "Disminuye",
            sort: true,
        },
        {
            dataField: "resetStorageUnit",
            text: "Limpiar Unidad de Almacenamiento",
            sort: true,
        },
        {
            dataField: "generateBatch",
            text: "Genera Lote",
            sort: true,
        },
    ]
};