import { GET_COUNTRIES } from "../../actions/countries/countriesAction"


const initialState = {
    countriesList: [],
}

export const countriesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_COUNTRIES:
            return { ...state, countriesList: payload }
        default:
            return state
    }
}