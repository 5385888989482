import { ProcessTemplateCategoryAPI } from "src/infrastructure/api/api-processTemplateCategory";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region ProcessTemplateCategory Const
export const CREATE_PROCESS_TEMPLATE_CATEGORY =
  "CREATE_PROCESS_TEMPLATE_CATEGORY";
export const GET_PROCESS_TEMPLATE_CATEGORY_LIST =
  "GET_PROCESS_TEMPLATE_CATEGORY_LIST";
export const GET_PROCESS_TEMPLATE_CATEGORY_ID =
  "GET_PROCESS_TEMPLATE_CATEGORY_ID";
export const UPDATE_PROCESS_TEMPLATE_CATEGORY =
  "UPDATE_PROCESS_TEMPLATE_CATEGORY";
export const DELETE_PROCESS_TEMPLATE_CATEGORY =
  "DELETE_PROCESS_TEMPLATE_CATEGORY";
export const PROCESS_TEMPLATE_CATEGORY_ERRORS =
  "PROCESS_TEMPLATE_CATEGORY_ERRORS";
export const IS_LOADING_PROCESS_TEMPLATE_CATEGORY =
  "IS_LOADING_PROCESS_TEMPLATE_CATEGORY";
//#endregion

//#region ProcessTemplateCategory Actions

export const fetchIsLoadingProcessTemplateCategoryList = (isLoading) => ({
  type: IS_LOADING_PROCESS_TEMPLATE_CATEGORY,
  payload: isLoading,
});

export const fetchCreateProcessTemplateCategory = (response) => ({
  type: CREATE_PROCESS_TEMPLATE_CATEGORY,
  payload: response.data,
});

export const fetchResetCreateProcessTemplateCategory = (response) => ({
  type: CREATE_PROCESS_TEMPLATE_CATEGORY,
  payload: response,
});

export const fetchGetProcessTemplateCategoryList = (response) => ({
  type: GET_PROCESS_TEMPLATE_CATEGORY_LIST,
  payload: response.data,
});

export const fetchProcessTemplateCategoryById = (response) => ({
  type: GET_PROCESS_TEMPLATE_CATEGORY_ID,
  payload: response,
});

export const fetchUpdateProcessTemplateCategory = (response) => ({
  type: UPDATE_PROCESS_TEMPLATE_CATEGORY,
  payload: response,
});

export const fetchDeleteProcessTemplateCategory = (response) => ({
  type: DELETE_PROCESS_TEMPLATE_CATEGORY,
  payload: response,
});

//#endregion

const processTemplateCategoryAPI = new ProcessTemplateCategoryAPI();

//#region ProcessTemplateCategorys Dispach

// Dispatch create processTemplateCategory
export const createProcessTemplateCategory =
  (processTemplateCategoryBody) => async (dispatch) => {
    try {
      dispatch(fetchStartLoading());
      dispatch(
        fetchCreateProcessTemplateCategory(
          await processTemplateCategoryAPI.createProcessTemplateCategoryApi(
            processTemplateCategoryBody
          )
        )
      );
      dispatch(fetchStopLoading());
    } catch (error) {
      dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
    }
  };

export const resetCreateProcessTemplateCategory =
  (specification) => async (dispatch) => {
    try {
      dispatch(fetchResetCreateProcessTemplateCategory(null));
    } catch (error) {
      dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
    }
  };

// Dispatch get processTemplateCategory list
export const getProcessTemplateCategoryList =
  (specification) => async (dispatch) => {
    try {
      dispatch(fetchIsLoadingProcessTemplateCategoryList(true));
      dispatch(
        fetchGetProcessTemplateCategoryList(
          await processTemplateCategoryAPI.getProcessTemplateCategoryApi(
            specification
          )
        )
      );
      dispatch(fetchIsLoadingProcessTemplateCategoryList(false));
    } catch (error) {
      dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
    }
  };

// Dispatch get processTemplateCategory by id
export const getProcessTemplateCategoryById = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchProcessTemplateCategoryById(
        await processTemplateCategoryAPI.getProcessTemplateCategoryByIdApi(id)
      )
    );
    dispatch(fetchStopLoading());
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
  }
};

// Dispatch update processTemplateCategory
export const updateProcessTemplateCategory = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchUpdateProcessTemplateCategory(
        await processTemplateCategoryAPI.updateProcessTemplateCategoryApi(
          id,
          body
        )
      )
    );
    dispatch(fetchStopLoading());
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
  }
};

// Dispatch delete processTemplateCategory
export const deleteProcessTemplateCategory = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchDeleteProcessTemplateCategory(
        await processTemplateCategoryAPI.deleteProcessTemplateCategoryApi(id)
      )
    );
    dispatch(fetchStopLoading());
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_CATEGORY_ERRORS, error));
  }
};
//#endregion
