import {
  CREATE_PROCESS_TEMPLATE_CATEGORY,
  GET_PROCESS_TEMPLATE_CATEGORY_LIST,
  GET_PROCESS_TEMPLATE_CATEGORY_ID,
  UPDATE_PROCESS_TEMPLATE_CATEGORY,
  DELETE_PROCESS_TEMPLATE_CATEGORY,
  PROCESS_TEMPLATE_CATEGORY_ERRORS,
  IS_LOADING_PROCESS_TEMPLATE_CATEGORY,
} from "../../actions/processTemplateCategory/processTemplateCategoryAction";

const initialState = {
  processTemplateCategoryCreated: null,
  processTemplateCategoryList: [],
  processTemplateCategoryFound: null,
  processTemplateCategoryUpdated: null,
  processTemplateCategoryDeleted: null,
  processTemplateCategoryErrors: null,
  isLoadingProcessTemplateCategory: true,
};

export const processTemplateCategoryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_PROCESS_TEMPLATE_CATEGORY:
      return { ...state, processTemplateCategoryCreated: payload };
    case GET_PROCESS_TEMPLATE_CATEGORY_LIST:
      return { ...state, processTemplateCategoryList: payload.data };
    case GET_PROCESS_TEMPLATE_CATEGORY_ID:
      return { ...state, processTemplateCategoryFound: payload.data };
    case UPDATE_PROCESS_TEMPLATE_CATEGORY:
      return { ...state, processTemplateCategoryUpdated: payload };
    case DELETE_PROCESS_TEMPLATE_CATEGORY:
      return { ...state, processTemplateCategoryDeleted: payload };
    case PROCESS_TEMPLATE_CATEGORY_ERRORS:
      return { ...state, processTemplateCategoryErrors: payload };
    case IS_LOADING_PROCESS_TEMPLATE_CATEGORY:
      return { ...state, isLoadingProcessTemplateCategory: payload };
    default:
      return state;
  }
};
