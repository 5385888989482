import { ProcessTemplateAPI } from "src/infrastructure/api/api-processTemplate";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region ProcessTemplate Const
export const CREATE_PROCESS_TEMPLATE = "CREATE_PROCESS_TEMPLATE";
export const GET_PROCESS_TEMPLATE_LIST = "GET_PROCESS_TEMPLATE_LIST";
export const SET_PROCESS_TEMPLATE_FOUND = "GET_PROCESS_TEMPLATE_ID";
export const UPDATE_PROCESS_TEMPLATE = "UPDATE_PROCESS_TEMPLATE";
export const DELETE_PROCESS_TEMPLATE = "DELETE_PROCESS_TEMPLATE";
export const PROCESS_TEMPLATE_ERRORS = "PROCESS_TEMPLATE_ERRORS";
export const IS_LOADING_PROCESS_TEMPLATE = "LOADING_PROCESS_TEMPLATE";
//#endregion

//#region ProcessTemplate Actions

export const fetchIsLoadingProcessTemplateList = (isLoading) => ({
  type: IS_LOADING_PROCESS_TEMPLATE,
  payload: isLoading,
});

export const fetchCreateProcessTemplate = (response) => ({
  type: CREATE_PROCESS_TEMPLATE,
  payload: response.data,
});

export const fetchGetProcessTemplateList = (response) => {
  return {
    type: GET_PROCESS_TEMPLATE_LIST,
    payload: response.data,
  };
};

export const fetchLoadingProcessTemplate = (isLoading) => ({
  type: PROCESS_TEMPLATE_ERRORS,
  payload: isLoading,
});

export const setProcessTemplateFound = (payload) => ({
  type: SET_PROCESS_TEMPLATE_FOUND,
  payload,
});

export const fetchUpdateProcessTemplate = (response) => ({
  type: UPDATE_PROCESS_TEMPLATE,
  payload: response,
});

export const fetchDeleteProcessTemplate = (response) => ({
  type: DELETE_PROCESS_TEMPLATE,
  payload: response,
});

//#endregion

const processTemplateAPI = new ProcessTemplateAPI();

//#region ProcessTemplates Dispach

// Dispatch create processTemplate
export const createProcessTemplate =
  (processTemplateBody) => async (dispatch) => {
    try {
      dispatch(fetchStartLoading());
      dispatch(
        fetchCreateProcessTemplate(
          await processTemplateAPI.createProcessTemplateApi(processTemplateBody)
        )
      );
    } catch (error) {
      dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
    } finally {
      dispatch(fetchStopLoading());
    }
  };

// Dispatch get processTemplate list
export const getProcessTemplateList = (specification) => async (dispatch) => {
  try {
    dispatch(fetchIsLoadingProcessTemplateList(true));
    dispatch(
      fetchGetProcessTemplateList(
        await processTemplateAPI.getProcessTemplatesApi(specification)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
  } finally {
    dispatch(fetchIsLoadingProcessTemplateList(false));
  }
};

// Dispatch get processTemplate by id
export const getProcessTemplateById = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await processTemplateAPI.getProcessTemplateByIdApi(id);
    dispatch(setProcessTemplateFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProcessTemplateFound = () => async (dispatch) => {
  try {
    dispatch(setProcessTemplateFound(null));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
  }
};

// Dispatch update processTemplate
export const updateProcessTemplate = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchUpdateProcessTemplate(
        await processTemplateAPI.updateProcessTemplateApi(id, body)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch delete processTemplate
export const deleteProcessTemplate = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchDeleteProcessTemplate(
        await processTemplateAPI.deleteProcessTemplateApi(id)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_TEMPLATE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion
