import { useEffect, useState } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessTemplateCategoryForm } from "src/views/pages/processTemplateCategory/ProcessTemplateCategoryForm";

export const ProcessTemplateCategoryCreate = ({
  create,
  getProcessTemplate,
  processTemplateList,
  isLoadingProcessTemplate,
  dataNewProcessTemplate,
}) => {
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 10,
  });

  const [stackProcessTemplate, setStackProcessTemplate] = useState([]);

  const fetchMoreProcessTemplate = () => {
    if (isLoadingProcessTemplate) return;
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    getProcessTemplate(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.offset === 0) {
      setStackProcessTemplate(processTemplateList?.data);
      return;
    }
    setStackProcessTemplate((state) => [
      ...state,
      ...processTemplateList?.data,
    ]);
  }, [processTemplateList]);

  return (
    <>
      <SimpleHeader
        name="Categoria de platilla de proceso"
        parentName="Admin"
      ></SimpleHeader>
      <Container fluid>
        <h2>{"Agregar Categoria de Plantilla de Proceso"}</h2>
        <ProcessTemplateCategoryForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
          processTemplate={{
            ...processTemplateList,
            data: stackProcessTemplate,
          }}
          processTemplateTotalCount={processTemplateList}
          dataNewProcessTemplate={dataNewProcessTemplate}
          onFetchMoreProcessTemplate={fetchMoreProcessTemplate}
        />
      </Container>
    </>
  );
};
