import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { batchDetailsColumns } from "src/variables/batchDetailsColumns";
import { useDispatch, useSelector } from "react-redux";
import { getBatchDetailList } from "src/infrastructure/redux/store/actions/batchDetail/batchDetailsAction";
import { CrudTable } from "../tables/CrudTable";

export const BatchDetails = ({ batch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const batchDetails = useSelector(
    (store) => store.batchDetails.batchDetailList
  );

  const detailsHaveProcess = useMemo(
    () => batchDetails?.every((item) => item.process),
    [batchDetails]
  );
  const detailsNotHaveMovement = useMemo(
    () => batchDetails?.every((item) => !item.movement),
    [batchDetails]
  );

  const columnsFiltered = useMemo(() => {
    if (detailsNotHaveMovement) {
      return batchDetailsColumns.columns.filter(
        (item) => !item.dataField.includes("movement")
      );
    }
    return batchDetailsColumns.columns;
  }, [detailsHaveProcess]);

  useEffect(() => {
    dispatch(getBatchDetailList(batch.id));
  }, []);

  return (
    <section style={{ height: "500px" }}>
      <CrudTable
        name="Detalle"
        dataTable={batchDetails ?? []}
        columnsTable={columnsFiltered}
      />
    </section>
  );
};
