import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataProcessTemplateCategory } from "src/variables/ProcessTemplateCategory";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

export const ProcessTemplateCategory = ({
  getProcessTemplateCategory,
  deleteProcessTemplateCategory,
  resetErrors,
  loading,
  processTemplateCategoryList,
  processTemplateCategoryDeleted,
  processTemplateCategoryFound,
  processTemplateCategoryCreated,
  processTemplateCategoryUpdated,
  processTemplateCategoryErrors,
}) => {
  const [finalList, setFinalList] = useState([]);
  const [alert, setAlert] = useState();

  useEffect(() => {
    setFinalList(processTemplateCategoryList);
  }, [processTemplateCategoryList]);

  useEffect(() => {
    getProcessTemplateCategory();
  }, [
    processTemplateCategoryDeleted,
    processTemplateCategoryFound,
    processTemplateCategoryCreated,
    processTemplateCategoryUpdated,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    processTemplateCategoryErrors ? setAlert(true) : setAlert(false);
  }, [processTemplateCategoryErrors]);

  const renderTable = (finalList) => {
    return (
      <CrudTable
        name="Categoría de plantilla de procesos"
        dataTable={finalList}
        columnsTable={dataProcessTemplateCategory.columns}
        deleteElement={deleteProcessTemplateCategory}
      />
    );
  };

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={processTemplateCategoryErrors} state={alert} />
      <SimpleHeader
        name="Categoria de plantilla de proceso"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? <LoadingComponent /> : renderTable(finalList)}
      </Container>
      <AdminFooter />
    </main>
  );
};
