import { GET_PROVINCE_ID } from "../../actions/provinces/provincesAction"



const initialState = {
    provincesFound: null,

}

export const provinceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PROVINCE_ID:
            return { ...state, provincesFound: payload.data }
        default:
            return state
    }
}