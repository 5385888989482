import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessTemplateForm } from "src/views/pages/processTemplate/ProcessTemplateForm";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProcessTemplateUpdate = ({
  processTemplateById,
  loading,
  processTemplateFound,
  updateProcessTemplate,
}) => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    setData(processTemplateFound);
  }, [processTemplateFound]);

  useEffect(() => {
    processTemplateById(id);
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Plantilla Proceso" parentName="Admin"></SimpleHeader>
      <>
        <Container fluid>
          {loading ? (
            <LoadingComponent />
          ) : (
            <ProcessTemplateForm
              elementData={data}
              nameForm={"edit"}
              id={id}
              create={null}
              update={updateProcessTemplate}
            />
          )}
        </Container>
      </>
      <AdminFooter />
    </main>
  );
};
