import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";

export const ProcessTemplateForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: elementData?.name ?? "",
      requireProvider: elementData ? elementData.requireProvider : false,
      requireClient: elementData ? elementData.requireClient : false,
      requireProduct: elementData?.requireProduct ?? false,
      increases: elementData ? elementData.increases : false,
      decreases: elementData ? elementData.decreases : false,
      requireStorageUnit: elementData ? elementData.requireStorageUnit : false,
      resetStorageUnit: elementData ? elementData.resetStorageUnit : false,
      generateBatch: elementData ? elementData.generateBatch : false,
      requireDestinationStorageUnitId:
        !!elementData?.requireDestinationStorageUnitId,
      requireTransferNumber: elementData
        ? elementData.requireTransferNumber
        : false,
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        navigate("/admin/process-template");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/process-template");
      }
    },
  });

  const cancelForm = (event) => {
    navigate("/admin/process-template");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="name">Denominacion</label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              required={!elementData}
            />
          </FormGroup>
          <FormGroup>
            <label
              htmlFor="requireTransferNumber"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireTransferNumber"
                id="requireTransferNumber"
                onChange={(e) =>
                  formik.setFieldValue(
                    "requireTransferNumber",
                    +e.target.checked
                  )
                }
                value={formik.values.requireTransferNumber}
                defaultChecked={
                  elementData ? elementData.requireTransferNumber : false
                }
              />
              Requiere Numero de Traslado
            </label>
            <label
              htmlFor="requireProvider"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireProvider"
                id="requireProvider"
                onChange={(e) =>
                  formik.setFieldValue("requireProvider", +e.target.checked)
                }
                value={formik.values.requireProvider}
                defaultChecked={
                  elementData ? elementData.requireProvider : false
                }
              />
              Requiere Proveedor
            </label>
            <label
              htmlFor="requireClient"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireClient"
                id="requireClient"
                onChange={(e) =>
                  formik.setFieldValue("requireClient", +e.target.checked)
                }
                value={formik.values.requireClient}
                defaultChecked={elementData ? elementData.requireClient : false}
              />
              Requiere Cliente
            </label>
            <label
              htmlFor="requireProduct"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireProduct"
                id="requireProduct"
                onChange={(e) =>
                  formik.setFieldValue("requireProduct", +e.target.checked)
                }
                value={formik.values.requireProduct}
                defaultChecked={
                  elementData ? elementData.requireProduct : false
                }
              />
              Requiere Producto
            </label>
            <label
              htmlFor="requireStorageUnit"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireStorageUnit"
                id="requireStorageUnit"
                onChange={(e) =>
                  formik.setFieldValue("requireStorageUnit", +e.target.checked)
                }
                value={formik.values.requireStorageUnit}
                defaultChecked={
                  elementData ? elementData.requireStorageUnit : false
                }
              />
              Requiere Unidad de Almacenamiento
            </label>
            <label
              htmlFor="resetStorageUnit"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="resetStorageUnit"
                id="resetStorageUnit"
                onChange={(e) =>
                  formik.setFieldValue("resetStorageUnit", +e.target.checked)
                }
                value={formik.values.resetStorageUnit}
                defaultChecked={
                  elementData ? elementData.resetStorageUnit : false
                }
              />
              Limpiar Unidad de Almacenamiento
            </label>
            <label
              htmlFor="generateBatch"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="generateBatch"
                id="generateBatch"
                onChange={(e) =>
                  formik.setFieldValue("generateBatch", +e.target.checked)
                }
                value={formik.values.generateBatch}
                defaultChecked={elementData ? elementData.generateBatch : false}
              />
              Generar Lote
            </label>
            <label
              key={Math.random()}
              htmlFor="requireDestinationStorageUnitId"
              className="custom-control custom-checkbox mb-3"
            >
              <Input
                type="checkbox"
                name="requireDestinationStorageUnitId"
                id="requireDestinationStorageUnitId"
                onChange={({ target: { checked } }) => {
                  formik.setFieldValue(
                    "requireDestinationStorageUnitId",
                    checked
                  );
                  formik.setFieldValue("increases", checked);
                  formik.setFieldValue("decreases", checked);
                }}
                value={formik.values.requireDestinationStorageUnitId}
                defaultChecked={formik.values.requireDestinationStorageUnitId}
              />
              Requiere Unidad de Almacenamiento de Destino
            </label>
            <label
              key={Math.random()}
              htmlFor="increases"
              className="custom-control mb-3 custom-checkbox"
            >
              <Input
                type="checkbox"
                name="increases"
                id="increases"
                onChange={({ target: { checked } }) => {
                  if (!checked || (checked && formik.values.decreases)) {
                    formik.setFieldValue(
                      "requireDestinationStorageUnitId",
                      checked
                    );
                  }
                  formik.setFieldValue("increases", checked);
                }}
                value={formik.values.increases}
                defaultChecked={!!formik.values.increases}
              />
              Incrementa
            </label>
            <label
              key={Math.random()}
              htmlFor="decreases"
              className="custom-control mb-3 custom-checkbox"
            >
              <Input
                type="checkbox"
                name="decreases"
                id="decreases"
                onChange={({ target: { checked } }) => {
                  if (!checked || (checked && formik.values.increases)) {
                    formik.setFieldValue(
                      "requireDestinationStorageUnitId",
                      checked
                    );
                  }
                  formik.setFieldValue("decreases", checked);
                }}
                value={!!formik.values.decreases}
                defaultChecked={!!formik.values.decreases}
              />
              Disminuye
            </label>
          </FormGroup>
          <Button classID="btn-icon btn-3" color="primary" type="submit">
            <span classID="btn-inner--text">Guardar</span>
          </Button>
          <Button
            classID="btn-icon btn-3"
            color="warning"
            type="button"
            onClick={(e) => cancelForm(e)}
          >
            <span classID="btn-inner--text">Cancelar</span>
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
