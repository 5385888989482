import { ProvincesAPI } from "src/infrastructure/api/api- provinces"


//#region Locality Const
export const GET_PROVINCE_ID = 'GET_PROVINCE_ID'

//#endregion

//#region Locality Actions


export const fetchProvinceById = response => ({
    type: GET_PROVINCE_ID,
    payload: response
})

//#endregion

const provincesAPI = new ProvincesAPI()

//#region Locality Dispach


export const getProvinceById = (id) => async (dispatch) => {
    try {
        dispatch(fetchProvinceById(await provincesAPI.getProvincesByCountryID(id)))
    } catch (error) {
        console.log(error)
    }
}

//#endregion
