import { ButtonGroup } from "reactstrap";

export const dataProviders = {
  columns: [
    {
      dataField: "businessName",
      text: "Razón social",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "registrationNumber",
      text: "Número de inscripto",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "address",
      text: "Domicilio",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "locality.name",
      text: "Localidad",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "observations",
      text: "observaciones",
      sort: true,
      style: colorByRating,
    },
    {
      dataField: "rating",
      text: "Evaluacion",
      sort: true,
      style: colorByRating,
      formatter: ratingFormatter,
    },
    {
      dataField: "actions",
      text: "Acciones",
      style: colorByRating,
      formatter: (_, row) => (
        <ButtonGroup>
          <button
            className="btn btn-info btn-xs mr-3"
            onClick={() => row.handleUpdate(row.id)}
          >
            Editar
          </button>
          <button
            className="btn btn-danger btn-xs"
            onClick={() => row.handleDelete(row.id)}
          >
            Eliminar
          </button>
        </ButtonGroup>
      ),
    },
  ],
};

function ratingFormatter(cell, row) {
  const ratingNum = Number(row.rating);
  let rating;

  if (ratingNum > 4.5) rating = "Muy confiable";
  else if (ratingNum >= 4) rating = "Confiable";
  else if (ratingNum >= 3.5) rating = "Aceptable";
  else if (ratingNum >= 3) rating = "Poco confiable";
  else if (ratingNum < 3) rating = "Rechazado";

  return (
    <p>
      {rating} ({ratingNum})
    </p>
  );
}

function colorByRating(_, row) {
  let color = "";
  const rating = Number(row.rating);

  if (rating > 4.5) color = "#B1F96E";
  else if (rating >= 4) color = "#D0FBA8";
  else if (rating >= 3.5) color = "#F9E34B";
  else if (rating >= 3) color = "#FFB232";
  else if (rating < 3) color = "#FF5532";

  return { backgroundColor: color };
}
