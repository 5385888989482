import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";

export const StorageUnitForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  unitOfMeasurements,
  readOnly,
}) => {
  const navigate = useNavigate();

  const [valueInput, setValueInput] = useState({
    number: "",
    capacity: "",
    unitOfMeasurementId: "",
    observations: "",
  });

  useEffect(() => {
    if (elementData) {
      setValueInput({
        number: elementData.number,
        capacity: elementData.capacity,
        unitOfMeasurementId: elementData.unitOfMeasurementId,
        observations: elementData.observations,
      });
    }
  }, [elementData]);

  const formik = useFormik({
    initialValues: {
      number: "",
      capacity: "",
      unitOfMeasurementId: "",
      observations: "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        navigate("/admin/storage-units");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/storage-units");
      }
    },
  });

  const cancelForm = (e) => {
    navigate("/admin/storage-units");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="number">Numero</label>
            {readOnly ? (
              <p>{valueInput.number}</p>
            ) : (
              <Input
                id="number"
                name="number"
                type="text"
                onChange={(e) => {
                  formik.setFieldValue("number", e.target.value);
                  setValueInput({
                    ...valueInput,
                    number: e.target.value,
                  });
                }}
                value={valueInput.number ?? "0001"}
                required={!elementData}
              />
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="capacity">Capacidad </label>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {readOnly ? (
                <p>
                  {valueInput.capacity}{" "}
                  {elementData && elementData.unitOfMeasurement.abbreviation}
                </p>
              ) : (
                <Input
                  id="capacity"
                  name="capacity"
                  placeholder="0002"
                  type="number"
                  min={0}
                  onChange={(e) => {
                    formik.setFieldValue("capacity", e.target.value);
                    setValueInput({
                      ...valueInput,
                      capacity: e.target.value,
                    });
                  }}
                  value={valueInput.capacity}
                  required={!elementData}
                />
              )}
              {!readOnly && (
                <Input
                  id="unitOfMeasurementId"
                  name="unitOfMeasurementId"
                  type="select"
                  required={!elementData}
                  defaultValue={formik.values.unitOfMeasurementId}
                  onChange={formik.handleChange}
                  style={{ width: "25%" }}
                >
                  {!elementData && <option value={""}>Seleccionar</option>}
                  {unitOfMeasurements?.data?.map((unit) => (
                    <option value={unit.id}>{unit.abbreviation}</option>
                  ))}
                </Input>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <label htmlFor="observations">Observaciones</label>
            {readOnly ? (
              <p>{valueInput.observations}</p>
            ) : (
              <Input
                id="observations"
                name="observations"
                rows="3"
                onChange={(e) => {
                  formik.setFieldValue("observations", e.target.value);
                  setValueInput({
                    ...valueInput,
                    observations: e.target.value,
                  });
                }}
                value={valueInput.observations}
                required={!elementData}
              />
            )}
          </FormGroup>
          {!readOnly && (
            <>
              <Button classID="btn-icon btn-3" color="primary" type="submit">
                <span classID="btn-inner--text">Guardar</span>
              </Button>
              <Button
                classID="btn-icon btn-3"
                color="warning"
                type="button"
                onClick={(e) => cancelForm(e)}
              >
                <span classID="btn-inner--text">Cancelar</span>
              </Button>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};
