import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { TraceabilityReportsForm } from "./TraceabilityReportsForm";
import AdminFooter from "src/components/Footers/AdminFooter";
import { useSelector } from "react-redux";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";

export const TraceabilityReportsUpdate = ({
  handleGetTraceabilityReportsById,
  traceabilityReportsFound,
  update,
}) => {
  const { id } = useParams();

  const isLoading = useSelector(
    (store) => store.traceabilityReports.isLoadingtraceabilityReportById
  );

  useEffect(() => {
    handleGetTraceabilityReportsById(id);
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader
        name="Reporte de Trazabilidad"
        parentName="Admin"
      ></SimpleHeader>
      <Container fluid>
        <h2>{"Editar Reporte de Trazabilidad"}</h2>
        {isLoading && <LoadingComponent />}
        {!isLoading && traceabilityReportsFound && (
          <TraceabilityReportsForm
            elementData={traceabilityReportsFound}
            nameForm={"update"}
            id={id}
            create={null}
            update={update}
            key={Math.random()}
          />
        )}
        {!isLoading && !traceabilityReportsFound && (
          <h2>Reporte de Trazabilidad no encontrado</h2>
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
