import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import AdminFooter from "src/components/Footers/AdminFooter";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import { BatchNumbersForm } from "./BatchNumbersForm";
import {
  cleanBatchNumberFound,
  getBatchNumberById,
} from "src/infrastructure/redux/store/actions/batchNumbers/batchNumbersAction";
import { updateBatchNumberApi } from "src/infrastructure/api/api-batchNumbers";

export const BatchNumbersUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleBatchNumberById = useCallback(
    async (id) => {
      await dispatch(getBatchNumberById(id));
    },
    [dispatch]
  );

  const handleCleanBatchNumberFound = useCallback(async () => {
    await dispatch(cleanBatchNumberFound());
  }, [dispatch]);

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateBatchNumberApi(id, body));
    },
    [dispatch]
  );

  const batchNumberFound = useSelector(
    (store) => store.batchNumbers.batchNumberFound
  );

  const isLoading = useSelector(
    (store) => store.batchNumbers.isLoadingBatchNumberById
  );

  useEffect(() => {
    handleBatchNumberById(id);
    return handleCleanBatchNumberFound;
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="BatchNumbero" parentName="Admin"></SimpleHeader>
      <Container fluid>
        <h2>{"Editar Número de Lote"}</h2>
        {isLoading && <LoadingComponent />}
        {!isLoading && batchNumberFound && (
          <BatchNumbersForm
            elementData={batchNumberFound}
            nameForm={"update"}
            id={id}
            create={null}
            update={update}
            key={Math.random()}
          />
        )}
        {!isLoading && !batchNumberFound && <h2>BatchNumbero no encontrado</h2>}
      </Container>
      <AdminFooter />
    </main>
  );
};
