import { useDispatch } from "react-redux";
import { deleteBatchDetailApi } from "src/infrastructure/api/api-batches";
import { getBatchDetailList } from "src/infrastructure/redux/store/actions/batchDetail/batchDetailsAction";

const ActionColumn = (row) => {
  const dispatch = useDispatch();

  const handleOnClickDelete = async (id) => {
    try {
      if (window.confirm("¿Eliminar elemento?")) {
        await deleteBatchDetailApi(id);
        dispatch(getBatchDetailList(id));
      }
    } catch (error) {}
  };

  return (
    <button
      className="btn btn-danger btn-xs mr-1"
      onClick={() => handleOnClickDelete(row.id)}
    >
      Eliminar
    </button>
  );
};

export const batchDetailsColumns = {
  columns: [
    {
      dataField: "createdAt",
      text: "Fecha de Carga",
      sort: true,
    },
    {
      dataField: "process.name",
      text: "Nombre / Producto",
      sort: true,
    },
    {
      dataField: "process.transferNumber",
      text: "Traslado",
      sort: true,
    },
    {
      dataField: "process.storageUnit.number",
      text: "Unidad de Almacenamiento",
      sort: true,
    },
    {
      dataField: "process.increases",
      text: "Incrementa",
      sort: true,
    },
    {
      dataField: "process.decreases",
      text: "Disminuye",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Acciones",
      formatter: (_, row) => <ActionColumn {...row} />,
    },
  ],
};
