import { CREATE_PROVIDER_TYPE, GET_PROVIDER_TYPE_LIST, GET_PROVIDER_TYPE_ID, UPDATE_PROVIDER_TYPE, DELETE_PROVIDER_TYPE, PROVIDER_TYPE_ERRORS } from "../../actions/providerType/providerTypeAction"


const initialState = {
    providerTypeCreated: null,
    providerTypeList: [],
    providerTypeFound: null,
    providerTypeUpdated: null,
    providerTypeDeleted: null,
    providerTypeErrors: null
}

export const providerTypeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_PROVIDER_TYPE:
            return { ...state, providerTypeCreated: payload }
        case GET_PROVIDER_TYPE_LIST:
            return { ...state, providerTypeList: payload }
        case GET_PROVIDER_TYPE_ID:
            return { ...state, providerTypeFound: payload }
        case UPDATE_PROVIDER_TYPE:
            return { ...state, providerTypeUpdated: payload }
        case DELETE_PROVIDER_TYPE:
            return { ...state, providerTypeDeleted: payload }
        case PROVIDER_TYPE_ERRORS:
            return { ...state, providerTypeErrors: payload }
        default:
            return state
    }
}