import { getUserToken, isUserLoggedin } from "../utils/request.helpers";
import { secret } from "src/environment/env";

const authRequestOkAction = (userToken, options) =>
    Promise.resolve({
        ...(options ? options : {}),
        headers: {
            ...options?.headers,
            //Authorization: `Bearer ${userToken}`,
        },
    });

const authRequestAction = {
    'OK': authRequestOkAction, default: () => { throw new Error('Need to login'); },
};

/**
 * This method is used to check if the user is LS user token is valid & refresh the token if it is necessary
 * @param options any
 */
export const authenticatedRequest = async options => {
    const userToken = getUserToken();
    return (authRequestAction[isUserLoggedin(userToken)])(userToken, options);
};

export const decodeJwt = () => {
    var jwt = require('jsonwebtoken');
    var token = getUserToken()
    const data = jwt.decode(token, secret)
    return data
}