import { LOGIN_USER, LOGIN_ERRORS } from "../../actions/authentication/authAction"


const initialState = {
    logedUser: null,
    loginErrors: null
}



export const loginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN_USER:
            return { ...state, logedUser: payload }
        case LOGIN_ERRORS:
            return { ...state, loginErrors: payload }
        default:
            return state
    }
}