import { CREATE_PROCESS_TEMPLATE_CATEGORY_DETAIL, GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_LIST, GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_ID, UPDATE_PROCESS_TEMPLATE_CATEGORY_DETAIL, DELETE_PROCESS_TEMPLATE_CATEGORY_DETAIL } from "../../actions/processTemplateCategoryDetail/processTemplateCategoryDetail"


const initialState = {
    processTemplateCategoryDetailCreated: [],
    processTemplateCategoryDetailList: [],
    processTemplateCategoryDetailFound: null,
    processTemplateCategoryDetailUpdated: null,
    processTemplateCategoryDetailDeleted: null
}

export const processTemplateCategoryDetailReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_PROCESS_TEMPLATE_CATEGORY_DETAIL:
            return { ...state, processTemplateCategoryDetailCreated: payload }
        case GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_LIST:
            return { ...state, processTemplateCategoryDetailList: payload.data }
        case GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_ID:
            return { ...state, processTemplateCategoryDetailFound: payload }
        case UPDATE_PROCESS_TEMPLATE_CATEGORY_DETAIL:
            return { ...state, processTemplateCategoryDetailUpdated: payload }
        case DELETE_PROCESS_TEMPLATE_CATEGORY_DETAIL:
            return { ...state, processTemplateCategoryDetailDeleted: payload }
        default:
            return state
    }
}