import { StorageUnitAPI } from "src/infrastructure/api/api-storageUnit";
import { fetchStopLoading, fetchStartLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region StorageUnit Const
export const CREATE_STORAGE_UNIT = "CREATE_STORAGE_UNIT";
export const SET_STORAGE_UNIT_LIST = "SET_STORAGE_UNIT_LIST";
export const SET_STORAGE_UNIT_FOUND = "SET_STORAGE_UNIT_FOUND";
export const UPDATE_STORAGE_UNIT = "UPDATE_STORAGE_UNIT";
export const DELETE_STORAGE_UNIT = "DELETE_STORAGE_UNIT";
export const GET_STORAGE_UNIT_LAST_MOVEMENT = "GET_STORAGE_UNIT_LAST_MOVEMENT";
export const STORAGE_UNIT_ERRORS = "STORAGE_UNIT_ERRORS";
export const IS_LOADING_STORAGE_UNIT = "IS_LOADING_STORAGE_UNIT";
//#endregion

//#region StorageUnit Actions

export const fetchCreateStorageUnit = (response) => ({
  type: CREATE_STORAGE_UNIT,
  payload: response.data,
});

export const setStorageUnitList = (payload) => ({
  type: SET_STORAGE_UNIT_LIST,
  payload,
});

export const setStorageUnitFound = (payload) => ({
  type: SET_STORAGE_UNIT_FOUND,
  payload,
});

export const fetchUpdateStorageUnit = (response) => ({
  type: UPDATE_STORAGE_UNIT,
  payload: response,
});

export const fetchDeleteStorageUnit = (response) => ({
  type: DELETE_STORAGE_UNIT,
  payload: response,
});

export const fetchStorageUnitLastMovement = (response) => ({
  type: GET_STORAGE_UNIT_LAST_MOVEMENT,
  payload: response.data,
});

export const handleIsLoadingStorageUnit = (isLoading) => ({
  type: IS_LOADING_STORAGE_UNIT,
  payload: isLoading,
});

//#endregion

const storageUnitAPI = new StorageUnitAPI();

//#region StorageUnits Dispach

// Dispatch create storageUnit
export const createStorageUnit = (storageUnitBody) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchCreateStorageUnit(
        await storageUnitAPI.createStorageUnitApi(storageUnitBody)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(STORAGE_UNIT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get storageUnit list
export const getStorageUnitList = (specification) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading())
    dispatch(handleIsLoadingStorageUnit(true));
    const response = await storageUnitAPI.getStorageUnitApi(specification);
    dispatch(setStorageUnitList(response.data));
  } catch (error) {
    dispatch(setErrorMessages(STORAGE_UNIT_ERRORS, error));
  } finally {
    dispatch(handleIsLoadingStorageUnit(false));
    dispatch(fetchStopLoading());
  }
};

export const cleanStorageUnitList = () => (dispatch) => {
  dispatch(setStorageUnitList(null));
};

// Dispatch get storageUnit by id
export const getStorageUnitById = (id, expand) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await storageUnitAPI.getStorageUnitByIdApi(id, expand);
    dispatch(setStorageUnitFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(STORAGE_UNIT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanStorageUnitFound = () => (dispatch) => {
  dispatch(setStorageUnitFound(null));
};

// Dispatch update storageUnit
export const updateStorageUnit = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchUpdateStorageUnit(
        await storageUnitAPI.updateStorageUnitApi(id, body)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(STORAGE_UNIT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch delete storageUnit
export const deleteStorageUnit = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchDeleteStorageUnit(await storageUnitAPI.deleteStorageUnitApi(id))
    );
  } catch (error) {
    dispatch(setErrorMessages(STORAGE_UNIT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get storageUnit last movement
export const getStorageUnitLastMovement = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      fetchStorageUnitLastMovement(
        await storageUnitAPI.getStorageUnitLastMovement(id)
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion
