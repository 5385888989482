import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";

import { useFormik } from "formik";

import Select from "react-select";

export const UsersForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  rolesList,
}) => {
  const navigate = useNavigate();

  const [canSubmit, setSubmitting] = React.useState(false);

  const cancelForm = (event) => {
    navigate("/admin/users");
  };

  const validate = (values) => {
    const errors = {};
    let confirmPassword = document.getElementById("confirmPassword").value;
    if (values.password && confirmPassword) {
      if (values.password !== confirmPassword) {
        errors.password = "Las contraseñas no coinciden";
      } else {
        setSubmitting(false);
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: elementData?.email ?? "",
      enable: elementData?.enable ?? "",
      password: elementData?.password ?? "",
      confirmPassword: elementData?.confirmPassword ?? "",
      name: elementData?.name ?? "",
      lastName: elementData?.lastName ?? "",
      identificationNumber: elementData?.identificationNumber ?? "",
      userRoles:
        elementData?.roles?.map((element) => {
          return {
            value: element.id,
            label: element.name,
          };
        }) ?? [],
    },
    validate,
    onSubmit: (values) => {
      if (nameForm === "create") {
        const valuesToSubmit = {
          ...values,
          userRoles: values["userRoles"],
        };
        create(valuesToSubmit);
        navigate("/admin/users");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/users");
      }
    },
  });

  return (
    <>
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label htmlFor="name">Nombre</label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="lastName">Apellido</label>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="identificationNumber">Legajo</label>
              <Input
                id="identificationNumber"
                name="identificationNumber"
                type="number"
                min={0}
                onChange={formik.handleChange}
                value={formik.values.identificationNumber}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                required={!elementData}
              />
            </FormGroup>
            <div className="custom-control custom-checkbox mb-3">
              <Input
                type="checkbox"
                name="enable"
                onChange={formik.handleChange}
                value={formik.values.enable}
                defaultChecked={formik.values.enable}
              />
              <label htmlFor="enable">Habilitado</label>
            </div>
            <FormGroup>
              <label htmlFor="password">Contraseña</label>
              <Input
                id="password"
                name="password"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.password}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="confirmPassword">Confirmar contraseña</label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Seleccione el Rol"
                name="userRoles"
                id="userRoles"
                value={formik.values.userRoles}
                onChange={(value) => formik.setFieldValue("userRoles", value)}
                onBlur={formik.handleBlur}
                options={
                  rolesList?.data?.map((element) => {
                    return {
                      value: element.id,
                      label: element.name,
                    };
                  }) ?? []
                }
              />
            </FormGroup>
            {formik.errors.password ? (
              <div style={{ color: "red" }}>{formik.errors.password}</div>
            ) : null}

            <Button
              classID="btn-icon btn-3"
              color="primary"
              type="submit"
              disabled={canSubmit}
            >
              <span classID="btn-inner--text">Guardar</span>
            </Button>
            <Button
              classID="btn-icon btn-3"
              color="warning"
              type="button"
              onClick={(e) => cancelForm(e)}
            >
              <span classID="btn-inner--text">Cancelar</span>
            </Button>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};
