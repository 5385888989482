
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

export const PasswordRecovery = ( { recovery } ) => {

  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = React.useState(false);
  const [canSubmit, setSubmitting] = React.useState(false)

  const navigate = useNavigate()

  const UUID = new URL(document.location).searchParams.get('recoveryUUID')

  React.useEffect( () => {
    if (UUID === null) {
      navigate('/auth/login')
    }
  })

  const validate = values => {
    const errors = {}
    let confirmPassword = document.getElementById("confirmPassword").value
    if (values.password && confirmPassword) {
      if (values.password !== confirmPassword) {
        errors.password = 'Las contraseñas no coinciden'
      } else {
        setSubmitting(false)
      }
    }
    return errors
  }
  
  const formik = useFormik({
    initialValues: {
        "password" : "",
        "recoveryUUID" : UUID
    },
    validate,
    onSubmit: values => {
      recovery(values)
      navigate('/auth/login')
    }
})

  return (
    <>
    <div className="Auth-bg vh-100 d-flex align-items-center">
      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4 fs-m">
                  <small>Recuperar contraseña</small>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nueva contraseña"
                        type="text"
                        name="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedConfirmPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirme la contraseña"
                        type="text"
                        name="confirmPassword"
                        id="confirmPassword"
                        onFocus={() => setfocusedConfirmPassword(true)}
                        onBlur={() => setfocusedConfirmPassword(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  { formik.errors.password ? <div style={ {'color':'red'} }>{formik.errors.password}</div> : null }
                  <div className="text-center">
                    <Button className="my-4 fs-m" color="info" type="submit" disabled={canSubmit}>
                      Aceptar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}
