import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StorageUnitCreate } from "src/views/pages/storageUnit/StorageUnitCreate";
import { createStorageUnit } from 'src/infrastructure/redux/store/actions/storageUnit/storageUnitAction';
import { getUnitOfMeasurementList } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';

export const CreateStorageUnit = () => {

    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createStorageUnit(body));
    }, [dispatch]);

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const loading = useSelector(store => store.loading.loading)
    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)

    return (
        <StorageUnitCreate
            create={create}
            getUnitOfMeasurement={getUnitOfMeasurement}
            unitOfMeasurementsList={unitOfMeasurementsList}
            loading={loading}
        />
    )

}


