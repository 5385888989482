import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProcessTemplateCategoryDetailAPI {

    createProcessTemplateCategoryDetailApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category-detail`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategoryDetail:18 => Error: ', error)
            throw new Error(error)
        }
    };

    getProcessTemplateCategoryDetailsApi = async (specification = {}) => {
        try {
            let url = `${BASE_URL}/process-template-category-detail`
            // url += '?deletedAt=null' + (new URLSearchParams(specification)).toString();
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategoryDetail:35 => Error: ', error)
            throw new Error(error)
        }
    }

    getProcessTemplateCategoryDetailByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategoryDetail:51 => Error: ', error)
            throw new Error(error)
        }
    }

    updateProcessTemplateCategoryDetailApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategoryDetail:70 => Error: ', error)
            throw new Error(error)
        }
    };

    deleteProcessTemplateCategoryDetailApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-template-category-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processTemplateCategoryDetail:87 => Error: ', error)
            throw new Error(error)
        }
    }

}


