import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessForm } from "src/views/pages/process/ProcessForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProcessUpdate = ({
  processById,
  processFound,
  updateProcess,
  selectData,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      processById(id);
      if (processFound) {
        setLoading(false);
      }
    }
  }, [id, loading, processById, processFound]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Proceso" parentName="Admin"></SimpleHeader>
      {!loading && (
        <>
          <Container className="mt-4" fluid>
            <ProcessForm
              elementData={processFound}
              nameForm={"edit"}
              id={id}
              create={null}
              update={updateProcess}
              processTemplate={processFound.processTemplate}
              selectData={selectData}
            />
          </Container>
        </>
      )}
      <AdminFooter />
    </main>
  );
};
