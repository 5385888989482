import { CountriesAPI } from "src/infrastructure/api/api-countries"


//#region Locality Const
export const GET_COUNTRIES = 'GET_COUNTRIES'

//#endregion

//#region Locality Actions

export const fetchGetCountries = response => ({
    type: GET_COUNTRIES,
    payload: response.data
})

//#endregion

const countriesAPI = new CountriesAPI()

//#region Locality Dispach

// Dispatch get Countries list
export const getCountriesList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchGetCountries(await countriesAPI.getCountries(specification)))
    } catch (error) {
        console.log(error)
    }
}

//#endregion
