import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcessDetailList,
  deleteProcessDetail,
} from "src/infrastructure/redux/store/actions/processDetail/processDetailAction";
import { ProcessDetailList } from "src/views/pages/processDetail/ProcessDetailList";
import { getProcessList } from "src/infrastructure/redux/store/actions/process/processAction";
import { getProcessTemplateCategoryList } from "src/infrastructure/redux/store/actions/processTemplateCategory/processTemplateCategoryAction";
import { getProcessTemplateCategoryDetailList } from "src/infrastructure/redux/store/actions/processTemplateCategoryDetail/processTemplateCategoryDetail";

export const ListProcessDetail = ({ nameForm, processId }) => {
  const dispatch = useDispatch();

  const processDetailToDelete = useCallback(
    async (deleteId) => {
      await dispatch(deleteProcessDetail(deleteId));
    },
    [dispatch]
  );

  const getProcessDetail = useCallback(
    async (specification) => {
      await dispatch(getProcessDetailList(specification));
    },
    [dispatch]
  );

  const loading = useSelector((store) => store.loading.loading);
  const processTemplateCategoryDetailList = useSelector(
    (store) =>
      store.processTemplateCategoryDetail.processTemplateCategoryDetailList
  );
  const processDetailList = useSelector(
    (store) => store.processDetail.processDetailList
  );
  const processDetailDeleted = useSelector(
    (store) => store.processDetail.processDetailDeleted
  );
  const processDetailUpdated = useSelector(
    (store) => store.processDetail.processDetailUpdated
  );
  const processDetailCreated = useSelector(
    (store) => store.processDetail.processDetailCreated
  );
  const processDetailFound = useSelector(
    (store) => store.processDetail.processDetailFound
  );

  const processCreated = useSelector((store) => store.process.processCreated);

  const [modal, setModal] = React.useState(false);
  const [detailId, setDetailId] = React.useState(null);

  const selectForms = {
    getProcess: useCallback(
      async (specification) => {
        await dispatch(getProcessList(specification));
      },
      [dispatch]
    ),
    getProcessTemplateCategory: useCallback(
      async (specification) => {
        await dispatch(getProcessTemplateCategoryList(specification));
      },
      [dispatch]
    ),
    getProcessTemplateCategoryDetail: useCallback(
      async (specification) => {
        await dispatch(getProcessTemplateCategoryDetailList(specification));
      },
      [dispatch]
    ),
    processList: useSelector((store) => store.process.processList),
    processTemplateCategoryList: useSelector(
      (store) => store.processTemplateCategory.processTemplateCategoryList
    ),
    processTemplateCategoryDetailList: useSelector(
      (store) =>
        store.processTemplateCategoryDetail.processTemplateCategoryDetailList
    ),
  };

  const finalDetailList = () =>
    processDetailList.map((detail) => {
      let processName = selectForms.processList?.data?.find(
        (process) => process.id === detail.processId
      );

      let processTemplateCategory =
        selectForms.processTemplateCategoryList?.data?.find(
          (processTemplateCategory) =>
            processTemplateCategory.id === detail.processTemplateCategoryId
        );

      let processTemplateCategoryDetailName =
        selectForms.processTemplateCategoryDetailList?.data?.find(
          (processTemplateCategoryDetail) =>
            processTemplateCategoryDetail.id ===
            detail.processTemplateCategoryDetailId
        );

      let valueType =
        processTemplateCategory?.processTemplateCategoryDetails?.find(
          (p) => p.id === detail.processTemplateCategoryDetailId
        )?.valueType;

      let obj = {
        ...detail,
        processId: processName ? processName.name : "Error",
        processTemplateCategoryId: processTemplateCategory
          ? processTemplateCategory.name
          : "Error",
        processTemplateCategoryDetailId: processTemplateCategoryDetailName
          ? processTemplateCategoryDetailName.name
          : "Error",
        processTemplateCategoryDetailsValueType: valueType,
        value: valueType?.toLowerCase()?.includes("boolean")
          ? detail.value === "0"
            ? "No"
            : "Si"
          : detail.value,
      };

      return obj;
    });

  const toggleModal = (id) => {
    setDetailId(id ?? null);
    setModal(!modal);
  };

  return (
    <>
      <ProcessDetailList
        getProcessDetail={getProcessDetail}
        deleteProcessDetail={processDetailToDelete}
        loading={loading}
        toggleModal={toggleModal}
        processId={processId}
        processDetailList={finalDetailList()}
        processDetailDeleted={processDetailDeleted}
        processDetailFound={processDetailFound}
        processDetailUpdated={processDetailUpdated}
        processDetailCreated={processDetailCreated}
        tableData={selectForms}
      />
      {/* {!detailId && (
                <CreateProcessDetail
                    modal={modal}
                    toggleModal={toggleModal}
                    processCreated={processCreated}
                    processId={
                        nameForm === "create"
                            ? processCreated.id
                            : processId
                    }
                    selectData={selectForms}
                />
            )} */}
      {/* {detailId && (
                <UpdateProcessDetail
                    // loading={loading.loading}
                    modal={modal}
                    toggleModal={toggleModal}
                    id={detailId}
                // processId={
                //     nameForm === "create"
                //         ? processCreated.id
                //         : processId
                // }
                // selectData={selectForms}
                />
            )} */}
    </>
  );
};
