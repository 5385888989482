import React from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProcessForm } from "src/views/pages/process/ProcessForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProcessCreate = ({
  create,
  processTemplate,
  isLoadingProcessTemplate,
  selectData,
  dataNewProcess,
}) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader name="Proceso" parentName="Admin"></SimpleHeader>
      <Container className="mt-4" fluid>
        <h2>{"Agregar proceso"}</h2>
        {!isLoadingProcessTemplate && processTemplate && (
          <ProcessForm
            elementData={null}
            nameForm={"create"}
            id={null}
            create={create}
            update={null}
            processTemplate={processTemplate}
            selectData={selectData}
            dataNewProcess={dataNewProcess}
          />
        )}
        {!isLoadingProcessTemplate && !processTemplate && (
          <div>{"No se encontró la plantilla de proceso seleccionada"}</div>
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
