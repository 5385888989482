import React, { useState, useEffect } from "react";
import 'src/assets/css/Alerts.css'
// reactstrap components
import { Alert } from "reactstrap";

export const Alerts = ({ login, data, state }) => {

    return (
        <>
            {state && (
                !login ? (
                    <Alert color="danger" fade={false} id='custom-alert'>
                        <span className="alert-inner--text">
                            <strong>{data}</strong>
                        </span>
                    </Alert>
                ) : (
                    <Alert color="danger" fade={false}>
                        <span className="alert-inner--text">
                            <strong>Credenciales Incorrectas</strong>
                        </span>
                    </Alert>
                )
            )}
        </>
    );
}