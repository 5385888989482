import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class UnitOfMeasurementAPI {
  createUnitOfMeasurementApi = async (body) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/units-of-measurement`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-unitOfMeasurement:18 => Error: ", error);
      throw error;
    }
  };

  getUnitOfMeasurementApi = async (specification = {}) => {
    try {
      let url = new URL(`${BASE_URL}/units-of-measurement`);
      url.searchParams.append("deletedAt", "null");
      Object.entries(specification).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      })

      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);

      return response.data;
    } catch (error) {
      console.log("api-unitOfMeasurement:35 => Error: ", error);
      throw error;
    }
  };

  getUnitOfMeasurementByIdApi = async (id) => {
    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/units-of-measurement/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-unitOfMeasurement:51 => Error: ", error);
      throw error;
    }
  };

  updateUnitOfMeasurementApi = async (id, body) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/units-of-measurement/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-unitOfMeasurement:70 => Error: ", error);
      throw error;
    }
  };

  deleteUnitOfMeasurementApi = async (id) => {
    try {
      const options = {
        method: "DELETE",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/units-of-measurement/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-unitOfMeasurement:87 => Error: ", error);
      throw error;
    }
  };
}
