import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ProviderTypeUpdate } from 'src/views/pages/providerType/ProviderTypeUpdate'
import { getProviderTypeById, updateProviderType } from 'src/infrastructure/redux/store/actions/providerType/providerTypeAction';

export const UpdateProviderType = () => {

    const dispatch = useDispatch();

    const providerTypeById = useCallback(async (id) => {
        await dispatch(getProviderTypeById(id));
    }, [dispatch]);

    const update = useCallback(async (id, body) => {
        await dispatch(updateProviderType(id, body));
    }, [dispatch]);

    const providerTypeFound = useSelector(store => store.providerType.providerTypeFound)

    return <ProviderTypeUpdate
        providerTypeById={providerTypeById}
        providerTypeFound={providerTypeFound}
        update={update}
    />
}