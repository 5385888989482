import {
  PROCESS_CREATED as PROCESS_CREATED,
  PROCESS_LIST,
  SET_PROCESS_FOUND,
  UPDATE_PROCESS,
  DELETE_PROCESS,
  PROCESS_ERRORS,
} from "../../actions/process/processAction";

const initialState = {
  processCreated: null,
  processList: null,
  processFound: null,
  processUpdated: null,
  processDeleted: null,
  processErrors: null,
};

export const processReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PROCESS_CREATED:
      return { ...state, processCreated: payload };
    case PROCESS_LIST:
      return { ...state, processList: payload.data };
    case SET_PROCESS_FOUND:
      return { ...state, processFound: payload };
    case UPDATE_PROCESS:
      return { ...state, processUpdated: payload };
    case DELETE_PROCESS:
      return { ...state, processDeleted: payload };
    case PROCESS_ERRORS:
      return { ...state, processErrors: payload };
    default:
      return state;
  }
};
