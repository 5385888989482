import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StorageUnit } from "src/views/pages/storageUnit/StorageUnit";
import { getStorageUnitList } from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { deleteStorageUnit } from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";

export const ListStorageUnit = () => {
  const dispatch = useDispatch();

  const getStorageUnit = useCallback(
    async (specification) => {
      await dispatch(getStorageUnitList(specification));
    },
    [dispatch]
  );

  const storageUnitToDelete = useCallback(
    async (id) => {
      await dispatch(deleteStorageUnit(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages('STORAGE_UNIT_ERRORS'));
  }, [dispatch]);

  const storageUnitsList = useSelector(
    (store) => store.storageUnit.StorageUnitList
  );
  const createdStorageUnit = useSelector(
    (store) => store.storageUnit.StorageUnitCreated
  );
  const foundStorageUnit = useSelector(
    (store) => store.storageUnit.StorageUnitFound
  );
  const updatedStorageUnit = useSelector(
    (store) => store.storageUnit.StorageUnitUpdated
  );
  const deletedStorageUnit = useSelector(
    (store) => store.storageUnit.StorageUnitDeleted
  );
  const storageUnitErrors = useSelector(
    (store) => store.errors.storageUnitErrors
  );

  const loading = useSelector((store) => store.loading.loading);

  return (
    <StorageUnit
      loading={loading}
      getStorageUnit={getStorageUnit}
      deleteStorageUnit={storageUnitToDelete}
      resetErrors={resetErrors}
      storageUnitList={storageUnitsList}
      createdStorageUnit={createdStorageUnit}
      foundStorageUnit={foundStorageUnit}
      updatedStorageUnit={updatedStorageUnit}
      deletedStorageUnit={deletedStorageUnit}
      storageUnitErrors={storageUnitErrors}
    />
  );
};
