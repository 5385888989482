import {ADD_DATA_PROFILE} from '../../actions/profile/profileAction';


const initialState = {
    name: '',
    lastName: '',
    userName:''
}

export const profileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_DATA_PROFILE:
            return { ...state, 
                name: payload.name,
                lastName: payload.lastName,
                userName: payload.userName }
                default:
            return state
    }
}