import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export const getBatchesApi = async (specification = {}) => {
  try {
    let url = new URL(`${BASE_URL}/batchs`);
    url.searchParams.append("deletedAt", "null");
    Object.entries(specification).forEach(([key, value]) => {
      if (!value) return;
      url.searchParams.append(key, value);
    });

    const options = {
      method: "GET",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
    };

    const response = await sdkAuthRequest(url.toString(), options);

    return response;
  } catch (error) {
    console.log("api-Lote: get all => Error: ", error);
    throw error;
  }
};

export const getBatchDetailsApi = async (id, expand) => {
  try {
    if (expand) {
      expand = "?expand=" + expand;
    }

    let url = `${BASE_URL}/batch-details/batch/${id}${expand}`;
    const options = {
      method: "GET",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
    };

    const response = await sdkAuthRequest(url, options);

    return response;
  } catch (error) {
    console.log("api-Lote: get all => Error: ", error);
    throw error;
  }
};

export const deleteBatchDetailApi = async (id) => {
  try {
    let url = `${BASE_URL}/batch-details/${id}`;
    const options = {
      method: "DELETE",
      headers: { ...headers, authorization: "Bearer " + getUserToken() },
    };

    const response = await sdkAuthRequest(url, options);
    return response;
  } catch (error) {
    console.log("api-Lote: get all => Error: ", error);
    throw error;
  }
};
