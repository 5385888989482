
//#region Const
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const fetchStopLoading = response => ({
  type: STOP_LOADING,
  payload: false
})

export const fetchStartLoading = response => ({
  type: START_LOADING,
  payload: true
})


