import {
  createBatchNumbersApi,
  deleteBatchNumberApi,
  getBatchNumberByIdApi,
  getBatchNumbersApi,
} from "src/infrastructure/api/api-batchNumbers";
import { setErrorMessages } from "../errors/errorsAction";

//#region batchNumbers Const
export const CREATE_BATCH_NUMBERS = "CREATE_BATCH_NUMBERS";
export const SET_BATCH_NUMBERS_LIST = "SET_BATCH_NUMBERS_LIST";
export const SET_BATCH_NUMBER_FOUND = "SET_BATCH_NUMBER_FOUND";
export const UPDATE_BATCH_NUMBERS = "UPDATE_BATCH_NUMBERS";
export const DELETE_BATCH_NUMBERS = "DELETE_BATCH_NUMBERS";
export const BATCH_NUMBERS_ERRORS = "BATCH_NUMBERS_ERRORS";
export const IS_LOADING_BATCH_NUMBERS = "IS_LOADING_batchNumbers";
export const IS_LOADING_BATCH_NUMBER_BY_ID = "IS_LOADING_BATCH_NUMBER_BY_ID";
//#endregion

//#region batchNumbers Actions

export const setCreateBatchNumbers = (payload) => ({
  type: CREATE_BATCH_NUMBERS,
  payload,
});

export const setBatchNumberList = (payload) => ({
  type: SET_BATCH_NUMBERS_LIST,
  payload,
});

export const setBatchNumberFound = (payload) => ({
  type: SET_BATCH_NUMBER_FOUND,
  payload,
});

export const setUpdateBatchNumber = (payload) => ({
  type: UPDATE_BATCH_NUMBERS,
  payload,
});

export const setDeleteBatchNumbers = (payload) => ({
  type: DELETE_BATCH_NUMBERS,
  payload,
});

export const setIsLoadingBatchNumbers = (isLoading) => ({
  type: IS_LOADING_BATCH_NUMBERS,
  payload: isLoading,
});

export const setIsLoadingBatchNumberById = (isLoading) => ({
  type: IS_LOADING_BATCH_NUMBER_BY_ID,
  payload: isLoading,
});

// Dispatch create batchNumbers
export const createBatchNumbers = (batchNumbersBody) => async (dispatch) => {
  try {
    dispatch(
      setCreateBatchNumbers(await createBatchNumbersApi(batchNumbersBody))
    );
  } catch (error) {
    dispatch(setErrorMessages(BATCH_NUMBERS_ERRORS, error));
  } finally {
    dispatch(setIsLoadingBatchNumbers(false));
  }
};

// Dispatch get batchNumbers list
export const getBatchNumbersList = (specification) => async (dispatch) => {
  try {
    setIsLoadingBatchNumbers(true);
    const response = await getBatchNumbersApi(specification);
    dispatch(setBatchNumberList(response.data));
  } catch (error) {
    dispatch(setErrorMessages(BATCH_NUMBERS_ERRORS, error));
  } finally {
    setIsLoadingBatchNumbers(false);
  }
};

export const cleanBatchNumberList = () => async (dispatch) => {
  dispatch(setIsLoadingBatchNumberById(true));
  dispatch(setBatchNumberList(null));
};

export const deleteBatchNumber = (id) => async (dispatch) => {
  try {
    const response = await deleteBatchNumberApi(id);
    dispatch(setBatchNumberFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(BATCH_NUMBERS_ERRORS, error));
  } finally {
  }
};

export const getBatchNumberById = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoadingBatchNumberById(true));
    const response = await getBatchNumberByIdApi(id);
    dispatch(setBatchNumberFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(BATCH_NUMBERS_ERRORS, error));
  } finally {
    dispatch(setIsLoadingBatchNumberById(false));
  }
};

export const cleanBatchNumberFound = () => async (dispatch) => {
  setIsLoadingBatchNumberById(true);
  dispatch(setBatchNumberFound(null));
};

// Dispatch update batchNumbers
// export const updateBatchNumber = (id, body) => async (dispatch) => {
//   try {
//     dispatch(setUpdateBatchNumber(await updateBatchNumberApi(id, body)));
//   } catch (error) {
//     dispatch(setErrorMessages(BATCH_NUMBERS_ERRORS, error));
//   }
// };
