import {
  CREATE_CLIENT,
  SET_CLIENT_LIST,
  SET_CLIENT_FOUND,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "../../actions/client/clientAction";

const initialState = {
  clientCreated: null,
  clientList: null,
  clientFound: null,
  clientUpdated: null,
  clientDeleted: null,
};

export const clientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_CLIENT:
      return { ...state, clientCreated: payload };
    case SET_CLIENT_LIST:
      return { ...state, clientList: payload };
    case SET_CLIENT_FOUND:
      return { ...state, clientFound: payload };
    case UPDATE_CLIENT:
      return { ...state, clientUpdated: payload };
    case DELETE_CLIENT:
      return { ...state, clientDeleted: payload };
    default:
      return state;
  }
};
