import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from 'src/views/pages/providers/Provider'
import { deleteProvider, getProviderList } from 'src/infrastructure/redux/store/actions/provider/providersAction';
import { resetErrorMessages } from 'src/infrastructure/redux/store/actions/errors/errorsAction';

export const ListProvider = () => {
    const dispatch = useDispatch();

    const getProviders = useCallback(async (specification) => {
        await dispatch(getProviderList(specification));
    }, [dispatch]);

    const providerToDelete = useCallback(async (id) => {
        await dispatch(deleteProvider(id));
    }, [dispatch]);

    const resetErrors = useCallback(async () => {
        await dispatch(resetErrorMessages('PROVIDER_ERRORS'));
    }, [dispatch]);

    const providersList = useSelector(store => store.provider.providerList)
    const providerCreated = useSelector(store => store.provider.providerCreated)
    const providerUpdated = useSelector(store => store.provider.providerUpdated)
    const providerDeleted = useSelector(store => store.provider.providerDeleted)
    const providerFound = useSelector(store => store.provider.providerFound)
    const providerErrors = useSelector(store => store.errors.providerErrors)
    const loading = useSelector(store => store.loading.loading)

    const types = {
        1: "Bodega",
        2: "Viñedo",
        3: "Error"
    }

    const finalProviderList = providersList?.data?.map((provider, key) => {
        return {
            key,
            ...provider,
            type: provider.providerType.name,
            rating: provider.rating ? provider.rating : 0,
        }
    });

    return <Provider
        getProviders={getProviders}
        deleteProvider={providerToDelete}
        resetErrors={resetErrors}
        loading={loading}
        providersList={{ ...providersList, data: finalProviderList }}
        providerCreated={providerCreated}
        providerUpdated={providerUpdated}
        providerDeleted={providerDeleted}
        providerFound={providerFound}
        providerErrors={providerErrors}
    />
}
