import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { providerReducer } from "./reducers/provider/providerReducer";
import { providerTypeReducer } from "./reducers/providerType/providerTypeReducer";
import { clientReducer } from "./reducers/client/clientReducer";
import { productsReducer } from "./reducers/products/productsReducer";
import { productTypeReducer } from "./reducers/productType/productTypeReducer";
import { unitOfMeasurementReducer } from "./reducers/unitOfMeasurement/unitOfMeasurementReducer";
import { storageUnitReducer } from "./reducers/storageUnit/storageUnitReducer";
import { localityReducer } from "./reducers/locality/localityReducer";
import { countriesReducer } from "./reducers/countries/countriesReducer";
import { processTemplateReducer } from "./reducers/processTemplate/processTemplateReducer";
import { usersReducer } from "./reducers/users/usersReducer";
import { processReducer } from "./reducers/process/processReducer";
import { processTemplateCategoryReducer } from "./reducers/processTemplateCategory/processTemplateCategoryReducer";
import { processTemplateCategoryDetailReducer } from "./reducers/processTemplateCategoryDetail/processTemplateCategoryDetail";
import { provinceReducer } from "./reducers/provinces/provinceReducer";
import { profileReducer } from "./reducers/profile/profileReducer";
import { loadingReducer } from "./reducers/loading/loadingReducer";
import { movementsReducer } from "./reducers/movements/movementsReducer";
import { processDetailReducer } from "./reducers/processDetail/processDetail";
import { loginReducer } from "./reducers/login/loginReducers";
import { errorsReducer } from "./reducers/errors/errorsReducer";
import { rolesReducer } from "./reducers/roles/rolesReducer";
import { configurationReducer } from "./reducers/configuration/configurationReducer";
import { traceabilityReportsReducer } from "./reducers/traceabilityReports/traceabilityReportsReducer";
import { batchNumbersReducer } from "./reducers/batchNumbers/batchNumbersReducer";
import { batchDetailsReducer } from "./reducers/batchDetails/batchDetailsReducer";

const reducers = (routes) =>
  combineReducers({
    router: connectRouter(routes),

    // Authentication
    login: loginReducer,

    //#region Reducers
    traceabilityReports: traceabilityReportsReducer,
    loading: loadingReducer,
    provider: providerReducer,
    client: clientReducer,
    configuration: configurationReducer,
    products: productsReducer,
    productType: productTypeReducer,
    unitOfMeasurement: unitOfMeasurementReducer,
    storageUnit: storageUnitReducer,
    locality: localityReducer,
    countries: countriesReducer,
    batchNumbers: batchNumbersReducer,
    batchDetails: batchDetailsReducer,
    provinces: provinceReducer,
    processTemplate: processTemplateReducer,
    users: usersReducer,
    process: processReducer,
    processTemplateCategory: processTemplateCategoryReducer,
    processTemplateCategoryDetail: processTemplateCategoryDetailReducer,
    profile: profileReducer,
    movements: movementsReducer,
    processDetail: processDetailReducer,
    providerType: providerTypeReducer,
    errors: errorsReducer,
    roles: rolesReducer,
    //#endregion Reducers
  });

export default reducers;
