import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductType } from "src/views/pages/productType/ProductType";
import { getProductTypeList } from "src/infrastructure/redux/store/actions/productType/productTypeAction";
import { deleteProductType } from "src/infrastructure/redux/store/actions/productType/productTypeAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";

export const ListProductType = () => {
  const dispatch = useDispatch();

  const getProductType = useCallback(
    async (specification) => {
      await dispatch(getProductTypeList(specification));
    },
    [dispatch]
  );

  const productTypeToDelete = useCallback(
    async (id) => {
      await dispatch(deleteProductType(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages('PRODUCT_TYPE_ERRORS'));
  }, [dispatch]);

  const productTypesList = useSelector(
    (store) => store.productType.ProductTypeList
  );
  const createdProductTypes = useSelector(
    (store) => store.productType.ProductTypeCreated
  );
  const foundProductTypes = useSelector(
    (store) => store.productType.ProductTypeFound
  );
  const updatedProductTypes = useSelector(
    (store) => store.productType.ProductTypeUpdated
  );
  const deletedProductTypes = useSelector(
    (store) => store.productType.ProductTypeDeleted
  );
  const productTypesErrors = useSelector(
    (store) => store.errors.ProductTypeErrors
  );

  const loading = useSelector((store) => store.loading.loading);

  return (
    <ProductType
      loading={loading}
      getProductType={getProductType}
      deleteProductType={productTypeToDelete}
      resetErrors={resetErrors}
      productTypesList={productTypesList}
      createdProductTypes={createdProductTypes}
      foundProductTypes={foundProductTypes}
      updatedProductTypes={updatedProductTypes}
      deletedProductTypes={deletedProductTypes}
      productTypesErrors={productTypesErrors}
    />
  );
};
