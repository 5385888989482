import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Button, Card, CardBody, Container, FormGroup } from "reactstrap";
import moment from "moment";
import { getBatchesApi } from "src/infrastructure/api/api-batches";
import AdminFooter from "src/components/Footers/AdminFooter";
import { BatchDetails } from "./BatchDetails";

export const BatchView = () => {
  const [batch, setBatch] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/admin/batches");
  };

  const getBatch = () => {
    getBatchesApi({
      limit: 999999,
      expand: "product, storageUnit",
    })
      .then(({ data: { data } }) => {
        setBatch(data.find((item) => item.id === parseInt(id)));
      })
      .catch((e) => e);
  };

  useEffect(() => {
    getBatch();
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Lote" parentName="Admin" />
      <Container className="mt-4" fluid>
        <h2>Detalle</h2>
        {batch?.product?.name && (
          <Card>
            <CardBody>
              <FormGroup>
                <label>Producto</label>
                <p>{batch.product?.name}</p>
              </FormGroup>
              <FormGroup>
                <label>Numero de lote</label>
                <p>{batch.batchNumber}</p>
              </FormGroup>
              <FormGroup>
                <label>Unidad de almacenamiento</label>
                <p>{batch.storageUnit?.number}</p>
              </FormGroup>
              <FormGroup>
                <label>Fecha de carga</label>
                <p>{moment(batch.createdAt).format("DD-MM-YYYY")}</p>
              </FormGroup>
            </CardBody>
          </Card>
        )}
        {batch && <BatchDetails batch={batch} />}
        <Button
          onClick={handleClose}
          classID="btn-icon btn-3 m-10"
          className="align-self-start mt-3"
          color="primary"
          type="submit"
        >
          <span classID="btn-inner--text">Cerrar</span>
        </Button>
      </Container>
      <AdminFooter />
    </main>
  );
};
