import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class ProductsAPI {
  createProductsApi = async (body) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(`${BASE_URL}/products`, options);

      return response;
    } catch (error) {
      console.log("api-product: create => Error: ", error);
      throw error;
    }
  };

  getProductsApi = async (specification = {}) => {
    try {
      let url = new URL(`${BASE_URL}/products`);
      url.searchParams.append("deletedAt", "null");
      Object.entries(specification).forEach(([key, value]) => {
        if (!value) return;
        url.searchParams.append(key, value);
      });

      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);

      return response;
    } catch (error) {
      console.log("api-product: get all => Error: ", error);
      throw error;
    }
  };

  getProductByIdApi = async (id, expand) => {    
    if (expand) {
      expand = '?expand=' + expand
    }

    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/products/${id}${expand}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-product: get by id => Error: ", error);
      throw error;
    }
  };

  updateProductsApi = async (id, body) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/products/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-product: update => Error: ", error);
      throw error;
    }
  };

  deleteProductsApi = async (id) => {
    try {
      const options = {
        method: "DELETE",
        headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/products/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-product: delete => Error: ", error);
      throw error;
    }
  };
}
