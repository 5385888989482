import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Products } from "src/views/pages/products/Products";
import { getProductsList } from "src/infrastructure/redux/store/actions/products/productsAction";
import { deleteProducts } from "src/infrastructure/redux/store/actions/products/productsAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";

export const ListProducts = () => {
  const dispatch = useDispatch();

  const getProducts = useCallback(
    async (specification) => {
      await dispatch(getProductsList(specification));
    },
    [dispatch]
  );

  const productsToDelete = useCallback(
    async (id) => {
      await dispatch(deleteProducts(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("PRODUCTS_ERRORS"));
  }, [dispatch]);

  const productsList = useSelector((store) => store.products.productsList);
  const productsCreated = useSelector(
    (store) => store.products.productsCreated
  );
  const productsUpdated = useSelector(
    (store) => store.products.productsUpdated
  );
  const productsDeleted = useSelector(
    (store) => store.products.productsDeleted
  );
  const productsFound = useSelector((store) => store.products.productsFound);
  const productsErrors = useSelector((store) => store.errors.productsErrors);
  const loading = useSelector((store) => store.loading);

  const finalProductsList = productsList?.data?.map((product) => {
    return {
      ...product,
      productTypeId: product.productType?.name,
      unitOfMeasurementId: product.unitOfMeasurement?.name,
    };
  });

  return (
    <Products
      getProducts={getProducts}
      deleteProducts={productsToDelete}
      resetErrors={resetErrors}
      loading={loading.loading}
      productsList={{ ...productsList, data: finalProductsList }}
      productsCreated={productsCreated}
      productsUpdated={productsUpdated}
      productsDeleted={productsDeleted}
      productsFound={productsFound}
      productsErrors={productsErrors}
    />
  );
};
