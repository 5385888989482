import React from "react";
import { StorageUnits } from "src/views/pages/home/StorageUnits";
import { Favorites } from "src/views/pages/home/Favorites";
import { RecentActivities } from "src/views/pages/home/RecentActivities";
import { BreadcrumHome } from "src/views/pages/home/BreadcrumHome";

import "src/assets/css/Home.css";
import AdminFooter from "src/components/Footers/AdminFooter";

export const Home = ({ children }) => {
  return (
    <section className="overflow-auto">
      <BreadcrumHome name="Principal" parentName="Home" />
      <StorageUnits />
      <div className="d-flex justify-content-around">
        {/* <Favorites /> */}
        <RecentActivities />
      </div>
      {children}
      <AdminFooter />
    </section>
  );
};
