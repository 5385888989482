import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ProductTypeUpdate } from 'src/views/pages/productType/ProductTypeUpdate';
import { getProductTypeById, updateProductType } from 'src/infrastructure/redux/store/actions/productType/productTypeAction';

export const UpdateProductType = () => {

    const dispatch = useDispatch();

    const productTypeById = useCallback(async (id) => {
        await dispatch(getProductTypeById(id));
    }, [dispatch]);

    const productTypeFound = useSelector(store => store.productType.ProductTypeFound)

    const update = useCallback(async (id, body) => {
        await dispatch(updateProductType(id, body));
    }, [dispatch]);

    const loading = useSelector(store => store.loading.loading)

    return (
        <ProductTypeUpdate
            productTypeById={productTypeById}
            productTypeFound={productTypeFound}
            update={update}
            loading={loading}
        />
    )
}
