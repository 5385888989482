import { BASE_URL } from "src/environment/env";
import { sdkAuthRequest } from "src/infrastructure/request";
import { headers } from "src/infrastructure/request/utils/headers";
import { getUserToken } from "src/infrastructure/request/utils/request.helpers";

export class TraceabilityReportsAPI {
  createTraceabilityReportsApi = async (body) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/traceability-reports`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-traceability-report: create => Error: ", error);
      throw error;
    }
  };

  publishTraceabilityReportsApi = async (id) => {
    try {
      const options = {
        method: "POST",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/traceability-reports/publish/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-traceability-report: publish => Error: ", error);
      throw error;
    }
  };

  getTraceabilityReportsApi = async (specification = {}) => {
    try {
      let url = new URL(`${BASE_URL}/traceability-reports`);
      url.searchParams.append("deletedAt", "null");
      Object.entries(specification).forEach(([key, value]) => {
        if (!value) return;
        url.searchParams.append(key, value);
      });

      const options = {
        method: "GET",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
      };

      const response = await sdkAuthRequest(url.toString(), options);

      return response;
    } catch (error) {
      console.log("api-traceability-report: get all => Error: ", error);
      throw error;
    }
  };

  getTraceabilityReportByIdApi = async (id) => {
    try {
      const options = {
        method: "GET",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/traceability-reports/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-traceability-report: get by id => Error: ", error);
      throw error;
    }
  };

  getTraceabilityReportPDFApi = async (id) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", `${BASE_URL}/traceability-reports/download/${id}`);
    xhr.responseType = "arraybuffer";
    xhr.setRequestHeader("Authorization", "Bearer " + getUserToken());
    xhr.onerror = function (error) {
      console.log("api-traceability-report: download pdf => Error: ", error);
    };
    xhr.onload = function () {
      var blob = new Blob([this.response], {
        type: "application/pdf",
      });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `informe-de-trazabilidad.pdf`;
      link.click();
    };
    xhr.send();

    // try {
    //   const options = {
    //     method: "GET",
    //     headers: { ...headers, authorization: "Bearer " + getUserToken() },
    //   };
    //   const response = await sdkAuthRequest(
    //     `${BASE_URL}/traceability-reports/download/${id}`,
    //     options
    //   );
    //   return response;
    // } catch (error) {
    //   console.log("api-traceability-report: download pdf => Error: ", error);
    //   throw error;
    // }
  };

  updateTraceabilityReportApi = async (id, body) => {
    try {
      const options = {
        method: "PUT",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
        data: body,
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/traceability-reports/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-traceability-report: update => Error: ", error);
      throw error;
    }
  };

  deleteTraceabilityReportsApi = async (id) => {
    try {
      const options = {
        method: "DELETE",
        headers: { ...headers, authorization: "Bearer " + getUserToken() },
      };

      const response = await sdkAuthRequest(
        `${BASE_URL}/traceability-reports/${id}`,
        options
      );

      return response;
    } catch (error) {
      console.log("api-traceability-report: delete => Error: ", error);
      throw error;
    }
  };
}
