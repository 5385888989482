import React from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { UsersForm } from "src/views/pages/users/UsersForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const UsersCreate = ({ create, getRoles, rolesList }) => {
  React.useEffect(() => {
    getRoles();
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Usuarios" parentName="Admin"></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        <h2>{"Agregar Usuarios"}</h2>
        <UsersForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
          rolesList={rolesList}
        />
      </Container>
      <AdminFooter />
    </main>
  );
};
