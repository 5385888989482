export const dataProcesoDetail = {
    columns : [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "item",
            text: "Item",
            sort: true,
        },
        {
            dataField: "value",
            text: "valor",
            sort: true,
        },
    ]
}