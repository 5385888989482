const {
  CONFIGURATION,
  IS_LOADING_CONFIGURATION,
  IS_LOADING_CONFIGURATION_UPDATE,
} = require("../../actions/configuration/configurationAction");

const initialState = {
  data: null,
  isLoading: null,
  isLoadingUpdate: false,
};

export const configurationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CONFIGURATION:
      return { ...state, data: payload };

    case IS_LOADING_CONFIGURATION:
      return { ...state, isLoading: payload };

    case IS_LOADING_CONFIGURATION_UPDATE:
      return { ...state, isLoadingUpdate: payload };

    default:
      return { ...state };
  }
};
