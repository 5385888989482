export const dataProductType = {
    columns: [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "name",
            text: "Denominacin",
            sort: true,
        },
        {
            dataField: "description",
            text: "Descripción",
            sort: true,
        },
    ]
};