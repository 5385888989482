import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { StorageUnitUpdate } from 'src/views/pages/storageUnit/StorageUnitUpdate';
import { getStorageUnitById, updateStorageUnit } from 'src/infrastructure/redux/store/actions/storageUnit/storageUnitAction';
import { getUnitOfMeasurementList } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';

export const UpdateStorageUnit = () => {

    const dispatch = useDispatch();

    const storageUnitById = useCallback(async (id) => {
        const expand = "unitOfMeasurement"
        await dispatch(getStorageUnitById(id, expand));
    }, [dispatch]);

    const storageUnitFound = useSelector(store => store.storageUnit.StorageUnitFound)

    const update = useCallback(async (id, body) => {
        await dispatch(updateStorageUnit(id, body));
    }, [dispatch]);

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const loading = useSelector(store => store.loading.loading)
    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)

    return (
        <StorageUnitUpdate
            StorageUnitById={storageUnitById}
            StorageUnitFound={storageUnitFound}
            update={update}
            getUnitOfMeasurement={getUnitOfMeasurement}
            unitOfMeasurementsList={unitOfMeasurementsList}
            loading={loading}
        />
    )
}
