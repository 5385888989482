import {
  CREATE_PROVIDER,
  SET_PROVIDER_LIST,
  SET_PROVIDER_FOUND,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  PROVIDER_ERRORS,
} from "../../actions/provider/providersAction";

const initialState = {
  providerCreated: null,
  providerList: null,
  providerFound: null,
  providerUpdated: null,
  providerDeleted: null,
  providerErrors: null,
};

export const providerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_PROVIDER:
      return { ...state, providerCreated: payload };
    case SET_PROVIDER_LIST:
      return { ...state, providerList: payload };
    case SET_PROVIDER_FOUND:
      return { ...state, providerFound: payload };
    case UPDATE_PROVIDER:
      return { ...state, providerUpdated: payload };
    case DELETE_PROVIDER:
      return { ...state, providerDeleted: payload };
    case PROVIDER_ERRORS:
      return { ...state, providerErrors: payload };
    default:
      return state;
  }
};
