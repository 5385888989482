import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { StorageUnitForm } from "./StorageUnitForm";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getStorageUnitById,
  updateStorageUnit,
} from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { getUnitOfMeasurementList } from "src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction";
import { StorageUnitMovements } from "./StorageUnitMovements";
import AdminFooter from "src/components/Footers/AdminFooter";

export const StorageUnitDetails = () => {
  const { id: storageUnitId } = useParams();
  const dispatch = useDispatch();

  const storageUnitById = useCallback(
    async (id) => {
      await dispatch(getStorageUnitById(id));
    },
    [dispatch]
  );

  const storageUnitFound = useSelector(
    (store) => store.storageUnit.StorageUnitFound
  );

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateStorageUnit(id, body));
    },
    [dispatch]
  );

  const getUnitOfMeasurement = useCallback(
    async (specification) => {
      await dispatch(getUnitOfMeasurementList(specification));
    },
    [dispatch]
  );

  const loading = useSelector((store) => store.loading.loading);
  const unitOfMeasurementsList = useSelector(
    (store) => store.unitOfMeasurement.UnitOfMeasurementList
  );

  useEffect(() => {
    storageUnitById(storageUnitId);
    getUnitOfMeasurement();
  }, [storageUnitId, storageUnitById]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Unidad de almacenamiento" parentName="Admin" />
      <Container className="mt-4" fluid>
        <h2>Detalle</h2>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <StorageUnitForm
              elementData={storageUnitFound}
              nameForm={"edit"}
              id={storageUnitId}
              create={null}
              update={update}
              readOnly={true}
              unitOfMeasurements={unitOfMeasurementsList}
            />
            <StorageUnitMovements storageUnitId={storageUnitId} />
          </>
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
