import { ProductTypeAPI } from "src/infrastructure/api/api-productType";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region ProductType Const
export const CREATE_PRODUCT_TYPE = "CREATE_PRODUCT_TYPE";
export const PRODUCT_TYPE_LIST = "PRODUCT_TYPE_LIST";
export const PRODUCT_TYPE_ID = "PRODUCT_TYPE_ID";
export const UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";
export const DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";
export const PRODUCT_TYPE_ERRORS = "PRODUCT_TYPE_ERRORS";
//#endregion

//#region ProductType Actions

// Create ProductType
export const setCreateProductType = (response) => ({
  type: CREATE_PRODUCT_TYPE,
  payload: response.data,
});

// Get list of productTypes
export const setProductTypeList = (response) => ({
  type: PRODUCT_TYPE_LIST,
  payload: response.data,
});

export const setProductTypeById = (response) => ({
  type: PRODUCT_TYPE_ID,
  payload: response,
});

export const setUpdateProductType = (response) => ({
  type: UPDATE_PRODUCT_TYPE,
  payload: response,
});

export const setDeleteProductType = (response) => ({
  type: DELETE_PRODUCT_TYPE,
  payload: response,
});

//#endregion

const productTypeAPI = new ProductTypeAPI();

//#region ProductTypes Dispatch

// Dispatch create productType
export const createProductType = (productTypeBody) => async (dispatch) => {
  try {
    dispatch(
      setCreateProductType(
        await productTypeAPI.createProductTypeApi(productTypeBody)
      )
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCT_TYPE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get productType list
export const getProductTypeList = (specification) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading())
    dispatch(
      setProductTypeList(await productTypeAPI.getProductTypesApi(specification))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCT_TYPE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProductTypeList = () => (dispatch) => {
  dispatch(setProductTypeList(null));
};

// Dispatch get productType by id
export const getProductTypeById = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(
      setProductTypeById(await productTypeAPI.getProductTypeByIdApi(id))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCT_TYPE_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

const cleanProductTypeFound = () => (dispatch) => {
  dispatch(setProductTypeById(null));
};

// Dispatch update productType
export const updateProductType = (body, id) => async (dispatch) => {
  try {
    dispatch(
      setUpdateProductType(await productTypeAPI.updateProductTypeApi(body, id))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCT_TYPE_ERRORS, error));
  }
};

// Dispatch delete productType
export const deleteProductType = (id) => async (dispatch) => {
  try {
    dispatch(
      setDeleteProductType(await productTypeAPI.deleteProductTypeApi(id))
    );
  } catch (error) {
    dispatch(setErrorMessages(PRODUCT_TYPE_ERRORS, error));
  }
};
//#endregion
