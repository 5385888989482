import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";
import {
  deletetraceabilityReports,
  getTraceabilityReportsList,
} from "src/infrastructure/redux/store/actions/traceabilityReports/traceabilityReportsAction";
import { TraceabilityReports } from "src/views/pages/traceabilityReports/TraceabilityReports";

export const ListTraceabilityReports = () => {
  const dispatch = useDispatch();

  const getTraceabilityReports = useCallback(
    async (specification) => {
      await dispatch(getTraceabilityReportsList(specification));
    },
    [dispatch]
  );

  const traceabilityReportsToDelete = useCallback(
    async (id) => {
      await dispatch(deletetraceabilityReports(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("TraceabilityReports_ERRORS"));
  }, [dispatch]);

  const traceabilityReportsList = useSelector(
    (store) => store.traceabilityReports.traceabilityReportsList
  );
  const traceabilityReportsCreated = useSelector(
    (store) => store.traceabilityReports.traceabilityReportsCreated
  );
  const traceabilityReportsUpdated = useSelector(
    (store) => store.traceabilityReports.traceabilityReportsUpdated
  );
  const traceabilityReportsDeleted = useSelector(
    (store) => store.traceabilityReports.traceabilityReportsDeleted
  );
  const traceabilityReportsFound = useSelector(
    (store) => store.traceabilityReports.traceabilityReportsFound
  );
  const traceabilityReportsErrors = useSelector(
    (store) => store.errors.traceabilityReportsErrors
  );
  const loading = useSelector((store) => store.loading);

  return (
    <TraceabilityReports
      getTraceabilityReports={getTraceabilityReports}
      deleteTraceabilityReports={traceabilityReportsToDelete}
      resetErrors={resetErrors}
      loading={loading.loading}
      TraceabilityReportsList={traceabilityReportsList}
      TraceabilityReportsCreated={traceabilityReportsCreated}
      TraceabilityReportsUpdated={traceabilityReportsUpdated}
      TraceabilityReportsDeleted={traceabilityReportsDeleted}
      TraceabilityReportsFound={traceabilityReportsFound}
      TraceabilityReportsErrors={traceabilityReportsErrors}
    />
  );
};
