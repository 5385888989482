import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class RolesAPI {

    getRolesApi = async (specification = {}) => {
        try {
            let url = `${BASE_URL}/roles`
            url += '?deletedAt=null' + (new URLSearchParams(specification)).toString();
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
            }

            const response = await sdkAuthRequest(url, options)

            return response
        } catch (error) {
            console.log('api-roles: get all => Error: ', error)
            throw (error)
        }
    }

    getRolesByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/roles/${id}`, options)

            return response

        } catch (error) {
            console.log('api-roles:38 => Error: ', error)
            throw (error)
        }
    }
}