import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataProducts } from "src/variables/products";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import { ProductTypeAPI } from "src/infrastructure/api/api-productType.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
  expand: "unitOfMeasurement, productType",
};

export const Products = ({
  getProducts,
  deleteProducts,
  resetErrors,
  loading,
  productsList,
  productsCreated,
  productsDeleted,
  productsFound,
  productsErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(productsList?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getProducts(queryParams);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [productsCreated, productsFound, productsDeleted, queryParams]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    productsErrors ? setAlert(true) : setAlert(false);
  }, [productsErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={productsErrors} state={alert} />
      <SimpleHeader
        name="Productos"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Productos"
            dataTable={productsList?.data ?? []}
            columnsTable={dataProducts.columns}
            deleteElement={deleteProducts}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <ProductsFilters
                identificationNumber={queryParams?.identificationNumber}
                onIndentificationNumberChange={(e) => {
                  setQueryParams({
                    identificationNumber: e.target.value,
                    offset: 0,
                  });
                }}
                productName={queryParams?.name}
                onProductNameChange={(e) => {
                  setQueryParams({
                    name: e.target.value,
                    offset: 0,
                  });
                }}
                onProductTypeChange={(e) => {
                  setQueryParams({
                    productTypeId: e.target.value,
                    offset: 0,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const ProductsFilters = ({
  productName,
  identificationNumber,
  onIndentificationNumberChange,
  onProductNameChange,
  onProductTypeChange,
}) => {
  const { getProductTypesApi } = new ProductTypeAPI();
  const [productTypes, setProductTypes] = useState([]);

  const getProductTypes = () => {
    getProductTypesApi().then((result) => {
      setProductTypes(result.data.data);
    });
  };

  useEffect(() => {
    getProductTypes();
  }, []);

  return (
    <div className="col-12 d-flex justify-content-between">
      <input
        className="form-control"
        placeholder="Número de identificación"
        type="text"
        value={identificationNumber ?? ""}
        onChange={onIndentificationNumberChange}
      />
      <input
        className="ml-4 form-control"
        placeholder="Nombre"
        type="text"
        value={productName ?? ""}
        onChange={onProductNameChange}
      />
      <select
        name="Tipo de Producto"
        className="ml-4 form-control"
        onChange={onProductTypeChange}
      >
        <option value="">Tipo de Producto</option>
        {productTypes.map((l) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>
    </div>
  );
};
