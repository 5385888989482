import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClientUpdate } from 'src/views/pages/clients/ClientUpdate';
import { getClientById, updateClient } from 'src/infrastructure/redux/store/actions/client/clientAction';
import { getLocalitiesList } from 'src/infrastructure/redux/store/actions/locality/localityAction';
import { getLocalityById } from 'src/infrastructure/redux/store/actions/locality/localityAction';
import { getCountriesList } from 'src/infrastructure/redux/store/actions/countries/countriesAction';
import { getProvinceById } from 'src/infrastructure/redux/store/actions/provinces/provincesAction';

export const UpdateClient = () => {

    const dispatch = useDispatch();

    const getClient = useCallback(async (idClient) => {
        const expand = "province, locality, country"
        await dispatch(getClientById(idClient,expand));
    }, [dispatch]);

    const clientFound = useSelector(store => store.client.clientFound)

    const update = useCallback(async (id, body) => {
        await dispatch(updateClient(id, body));
    }, [dispatch]);

    const getLocalities = useCallback(async (specification) => {
        await dispatch(getLocalitiesList(specification));
    }, [dispatch]);

    const getLocality = useCallback(async (specification) => {
        await dispatch(getLocalityById(specification));
    }, [dispatch]);

    const getCountries = useCallback(async () => {
        await dispatch(getCountriesList());
    }, [dispatch]);

    const getProvincesByID = useCallback(async (id) => {
        await dispatch(getProvinceById(id));
    }, [dispatch]);

    useEffect(() => {
        getCountries()
    }, [])

    const localitiesList = useSelector(store => store.locality.localityList)
    const countryList = useSelector(store => store.countries.countriesList)
    const provinceList = useSelector(store => store.provinces.provincesFound)
    const localitySelected = useSelector(store => store.locality.localityFound)
    const loading = useSelector(store => store.loading.loading)

    return (
        <ClientUpdate
            update={update}
            getClientById={getClient}
            getCountries={getCountries}
            getProvincesByID={getProvincesByID}
            getLocalities={getLocalities}
            getLocality={getLocality}
            loading={loading}
            clientFound={clientFound}
            countryList={countryList}
            provinceList={provinceList}
            localitiesList={localitiesList}
            localitySelected={localitySelected}
        />
    )
}