import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUnitOfMeasurementList } from "src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction";
import { getProcessTemplateCategoryList } from "src/infrastructure/redux/store/actions/processTemplateCategory/processTemplateCategoryAction";
import { createProcessTemplateCategoryDetail } from "src/infrastructure/redux/store/actions/processTemplateCategoryDetail/processTemplateCategoryDetail";

import { ProcessTemplateCategoryDetailCreate } from 'src/views/pages/processTemplateCategoryDetail/ProcessTemplateCategoryDetailCreate'

export const CreateProcessTemplateCategoryDetail = ({ modal, toggleModal, categoryCreated, categoryId }) => {

    const dispatch = useDispatch()

    const create = useCallback(async (body) => {
        await dispatch(createProcessTemplateCategoryDetail(body));
    }, [dispatch]);

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)

    const getProcessTemplateCategory = useCallback(async (specification) => {
        await dispatch(getProcessTemplateCategoryList(specification));
    }, [dispatch]);

    return (
        <>
            <ProcessTemplateCategoryDetailCreate
                modal={modal}
                toggleModal={toggleModal}
                getUnitOfMeasurement={getUnitOfMeasurement}
                unitOfMeasurement={unitOfMeasurementsList}
                create={create}
                update={null}
                getProcessTemplateCategory={getProcessTemplateCategory}
                processTemplateCategory={categoryCreated}
                categoryId={categoryId}
            />
        </>
    )
}