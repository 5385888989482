import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcessTemplateById } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { updateProcessTemplate } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { ProcessTemplateUpdate } from "src/views/pages/processTemplate/ProcessTemplateUpdate";

export const UpdateProcessTemplate = () => {
    const dispatch = useDispatch();

    const processTemplateById = useCallback(async (idProcessTemplate) => {
        await dispatch(getProcessTemplateById(idProcessTemplate));
    }, [dispatch]);

    const processTemplateFound = useSelector(store => store.processTemplate.processTemplateFound)

    const loading = useSelector(store => store.loading)

    const update = useCallback(async (id, body) => {
        await dispatch(updateProcessTemplate(id, body));
    }, [dispatch]);

    return (
        <ProcessTemplateUpdate
            processTemplateById={processTemplateById}
            loading={loading.loading}
            processTemplateFound={processTemplateFound}
            updateProcessTemplate={update}
        />
    )
}