import { AuthAPI } from 'src/infrastructure/api/api-authentication'
import { fetchStartLoading, fetchStopLoading } from '../loading/loadingAction';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ERRORS = 'LOGIN_ERRORS';

export const fetchLoginUser = response => ({
    type: LOGIN_USER,
    payload: response
})

export const fetchLoginErrors = error => ({
    type: LOGIN_ERRORS,
    payload: error.params.message
})

const AuthApi = new AuthAPI()

export const LoginUserApi = (credentials, dispatchApi) => async (dispatch) => {
    try {
        dispatch(fetchLoginUser(await AuthApi.LoginUser(credentials, dispatchApi)));
    } catch (error) {
        dispatch(fetchLoginErrors(error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

export const ForgotUserApi = (credentials) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(await AuthApi.LoginUser(credentials));
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

export const PasswordRecoveryApi = (credentials) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(await AuthApi.LoginUser(credentials));
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}