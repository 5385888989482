import { ProcessAPI } from "src/infrastructure/api/api-process";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region Process Const
export const PROCESS_CREATED = "PROCESS_CREATED";
export const PROCESS_LIST = "PROCESS_LIST";
export const SET_PROCESS_FOUND = "SET_PROCESS_FOUND";
export const UPDATE_PROCESS = "UPDATE_PROCESS";
export const DELETE_PROCESS = "DELETE_PROCESS";
export const APPROVE_PROCESS = "APPROVE_PROCESS";
export const PROCESS_ERRORS = "PROCESS_ERRORS";
//#endregion

//#region Process Actions

export const setProcessCreated = (payload) => ({
  type: PROCESS_CREATED,
  payload,
});

export const setProcessList = (payload) => ({
  type: PROCESS_LIST,
  payload,
});

export const setProcessFound = (payload) => ({
  type: SET_PROCESS_FOUND,
  payload,
});

export const fetchUpdateProcess = (payload) => ({
  type: UPDATE_PROCESS,
  payload,
});

export const fetchDeleteProcess = (payload) => ({
  type: DELETE_PROCESS,
  payload,
});

export const fetchApproveProcess = (payload) => ({
  type: APPROVE_PROCESS,
  payload,
});

//#endregion

const processAPI = new ProcessAPI();

//#region Processs Dispach

export const cleanProcessFound = () => async (dispatch) => {
  try {
    dispatch(setProcessFound(null));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  }
};

export const cleanProcessCreated = () => async (dispatch) => {
  try {
    dispatch(setProcessCreated(null));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  }
};

// Dispatch create process
export const createProcess = (processBody) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await processAPI.createProcessApi(processBody);
    dispatch(setProcessCreated(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch get process list
export const getProcessList = (specification) => async (dispatch) => {
  try {
    dispatch(setProcessList(await processAPI.getProcesssApi(specification)));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanProcessList = () => async (dispatch) => {
  try {
    dispatch(setProcessList(null));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  }
};

// Dispatch get process by id
export const getProcessById = (id, expand) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await processAPI.getProcessByIdApi(id, expand);
    dispatch(setProcessFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch update process
export const updateProcess = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchUpdateProcess(await processAPI.updateProcessApi(id, body)));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch delete process
export const deleteProcess = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchDeleteProcess(await processAPI.deleteProcessApi(id)));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion

// Dispatch approve process
export const approve = (id) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading());
    dispatch(fetchApproveProcess(await processAPI.approveProcessApi(id)));
  } catch (error) {
    dispatch(setErrorMessages(PROCESS_ERRORS, error));
  } finally {
    // dispatch(fetchStopLoading());
  }
};
