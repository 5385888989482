import {
  CREATE_TRANCEABILITY_REPORTS,
  TRANCEABILITY_REPORTS_LIST,
  TRANCEABILITY_REPORT_FOUND,
  UPDATE_TRANCEABILITY_REPORTS,
  DELETE_TRANCEABILITY_REPORTS,
  TRANCEABILITY_REPORTS_ERRORS,
  IS_LOADING_TRANCEABILITY_REPORTS,
  IS_LOADING_TRANCEABILITY_REPORT_BY_ID,
  IS_LOADING_TRANCEABILITY_REPORT_PUBLISHED,
  IS_LOADING_TRANCEABILITY_REPORT_PDF,
  TRANCEABILITY_REPORT_PDF,
} from "../../actions/traceabilityReports/traceabilityReportsAction";

const initialState = {
  traceabilityReportsCreated: null,
  traceabilityReportsList: null,
  traceabilityReportsFound: null,
  traceabilityReportsUpdated: null,
  traceabilityReportsDeleted: null,
  traceabilityReportsErrors: null,
  isLoadingtraceabilityReports: true,
  isLoadingtraceabilityReportById: true,
  isLoadingTraceabilityReportPublished: false,
  isLoadingTraceabilityReportPdf: false,
  traceabilityReportPdf: null,
};

export const traceabilityReportsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_TRANCEABILITY_REPORTS:
      return { ...state, traceabilityReportsCreated: payload };
    case TRANCEABILITY_REPORTS_LIST:
      return { ...state, traceabilityReportsList: payload };
    case TRANCEABILITY_REPORT_FOUND:
      return { ...state, traceabilityReportFound: payload };
    case UPDATE_TRANCEABILITY_REPORTS:
      return { ...state, traceabilityReportsUpdated: payload };
    case DELETE_TRANCEABILITY_REPORTS:
      return { ...state, traceabilityReportsDeleted: payload };
    case TRANCEABILITY_REPORTS_ERRORS:
      return { ...state, traceabilityReportsErrors: payload };
    case IS_LOADING_TRANCEABILITY_REPORTS:
      return { ...state, isLoadingtraceabilityReports: payload };
    case IS_LOADING_TRANCEABILITY_REPORT_BY_ID:
      return { ...state, isLoadingtraceabilityReportById: payload };
    case IS_LOADING_TRANCEABILITY_REPORT_PUBLISHED:
      return { ...state, isLoadingTraceabilityReportPublished: payload };
    case IS_LOADING_TRANCEABILITY_REPORT_PDF:
      return { ...state, isLoadingTraceabilityReportPdf: payload };
    case TRANCEABILITY_REPORT_PDF:
      return { ...state, traceabilityReportPdf: payload };
    default:
      return state;
  }
};
