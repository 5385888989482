import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import { validateEspecialCharacters } from "src/utils/valdiateEspecialCharacters";
import { getSelectProvincesEditUser } from "src/utils/getLocation";
import { getSelectedLocalityEditUser } from "src/utils/getLocation";
import { getSelectProvinces } from "src/utils/getLocation";
/* import { getSelectedLocality } from "src/utils/getLocationEditUser"; */
import { getSeleccionaLocalidad } from "src/utils/getLocation";

export const ClientForm = ({
  id,
  create,
  nameForm,
  elementData,
  update,
  countryList,
  provinceList,
  localitiesList,
  getProvincesByID,
  getLocality,
  localitySelected,
  getProvinces,
}) => {
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [localityData, setLocalityData] = useState([]);
  const [localitySelect, setLocalitySelect] = useState("");
  const [provinceSelect, setProvinceSelect] = useState("");
  const [formikFunction, setFormikFunction] = useState("");
  const [valueInput, setValueInput] = useState({
    businessName: "",
    registrationNumber: "",
    address: "",
    observations: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // TODO: Quitar o arreglar. Tenemos un state en el store para loading.

  const getSelectedLocality = async (id, formik) => {
    setProvinceSelect(Number(id?.target.value));
    await getLocality(id?.target.value);
    setFormikFunction(formik);
  };

  useEffect(() => {
    if (formikFunction) {
      formikFunction.setFieldValue(
        "localityId",
        Number(localityData.data[0]?.id)
      );
    }
  }, [formikFunction]);

  useEffect(() => {
    if (elementData) {
      setValueInput({
        businessName: elementData.businessName,
        registrationNumber: elementData.registrationNumber,
        address: elementData.address,
        observations: elementData.observations,
      });
    }
  }, [elementData]);

  useEffect(() => {
    if (elementData) {
      getSelectProvincesEditUser(
        elementData,
        getProvincesByID,
        setCountryData,
        getLocality
      );
      getSelectedLocalityEditUser(elementData.province.id, getLocality);
      setProvinceSelect(elementData.province.id);
      setLocalitySelect(elementData.locality.id);
      setLoading(false);
    } else {
      getLocality(0);
      getProvincesByID(0);
    }
  }, [elementData]);

  useEffect(() => {
    setProvinceData(provinceList ?? getProvinces);
    setLocalityData(localitySelected);
    setLoading(false);
  }, [countryData, getSelectedLocality, provinceList, getProvinces]);

  const formik = useFormik({
    initialValues: {
      businessName: "",
      registrationNumber: "",
      address: "",
      localityId: "",
      type: "",
      marketType: "",
      observations: "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        navigate("/admin/clients");
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/clients");
      }
    },
  });

  const cancelForm = (event) => {
    navigate("/admin/clients");
  };

  return (
    <Card>
      <CardBody>
        {!loading && (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label htmlFor="businessName">Razon social</label>
              <Input
                id="businessName"
                type="text"
                onChange={(e) => {
                  formik.setFieldValue("businessName", e.target.value);
                  setValueInput({
                    ...valueInput,
                    businessName: e.target.value,
                  });
                }}
                value={valueInput.businessName}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="registrationNumber">Numero de registro</label>
              <Input
                id="registrationNumber"
                name="registrationNumber"
                type="text"
                maxLength={"6"}
                min={0}
                onChange={(e) => {
                  validateEspecialCharacters(e, formik);
                  setValueInput({
                    ...valueInput,
                    registrationNumber: e.target.value,
                  });
                }}
                value={valueInput.registrationNumber}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="address">Domicilio</label>
              <Input
                id="address"
                name="address"
                type="string"
                onChange={(e) => {
                  formik.setFieldValue("address", e.target.value);
                  setValueInput({
                    ...valueInput,
                    address: e.target.value,
                  });
                }}
                value={valueInput.address}
                required={!elementData}
              />
            </FormGroup>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <label>Pais</label>
                  <Input
                    id="country"
                    type="select"
                    name="country"
                    onChange={(e) =>
                      getSelectProvinces(
                        e,
                        getProvincesByID,
                        setCountryData,
                        getLocality
                      )
                    }
                    /* value={countrySelected} */ required={!elementData}
                  >
                    {!elementData && (
                      <option selected value={""}>
                        Seleccionar
                      </option>
                    )}
                    {elementData && (
                      <option value={elementData.country.id} selected>
                        {elementData.country.name}
                      </option>
                    )}
                    {!elementData &&
                      countryList?.data?.map((country) => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label>Provincia</label>
                  <Input
                    id="provincia"
                    type="select"
                    name="province"
                    onChange={(e) => {
                      getSelectedLocality(e, formik);
                    }}
                    defaultValue={provinceSelect}
                    value={provinceSelect}
                    required={!elementData}
                  >
                    {!elementData && (
                      <option selected value={""}>
                        Seleccionar
                      </option>
                    )}
                    {provinceData?.data.map((province) =>
                      province?.id === elementData?.province.id ? (
                        <option value={elementData.province.id}>
                          {elementData.province.name}
                        </option>
                      ) : (
                        <option value={province.id}>{province.name}</option>
                      )
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label /* htmlFor="localityId" */>Localidad</label>
                  <Input
                    id="localityId"
                    type="select"
                    name="localityId"
                    onChange={(e) =>
                      getSeleccionaLocalidad(e, formik, setLocalitySelect)
                    }
                    defaultValue={localitySelect}
                    value={localitySelect}
                    required={!elementData}
                  >
                    {!elementData && (
                      <option selected value={""}>
                        Seleccionar
                      </option>
                    )}
                    {localityData?.data.map((locality) =>
                      locality?.id === localitySelect ? (
                        <option value={localitySelect}>
                          {elementData.locality.name}
                        </option>
                      ) : (
                        <option value={locality.id}>{locality.name}</option>
                      )
                    )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <label htmlFor="type">Tipo de establecimiento</label>
              <Input
                id="type"
                type="select"
                name="type"
                onChange={formik.handleChange}
                required={!elementData}
              >
                {!elementData && (
                  <option selected value={""}>
                    Seleccionar
                  </option>
                )}
                <option value={1} selected={elementData?.type === 1}>
                  Bodega
                </option>
                <option value={2} selected={elementData?.type === 2}>
                  Viñedo
                </option>
              </Input>
            </FormGroup>
            <FormGroup>
              <label htmlFor="marketType">Tipo de mercado</label>
              <Input
                id="marketType"
                type="select"
                name="marketType"
                onChange={formik.handleChange}
                required={!elementData}
              >
                {!elementData && <option>Seleccionar</option>}
                <option
                  value={1}
                  selected={elementData?.marketType === "Interno"}
                >
                  Interno
                </option>
                <option
                  value={2}
                  selected={elementData?.marketType === "exportacion"}
                >
                  Exportación
                </option>
              </Input>
            </FormGroup>
            <FormGroup>
              <label htmlFor="observations">Observaciones</label>
              <Input
                id="observations"
                name="observations"
                rows="3"
                onChange={(e) => {
                  formik.setFieldValue("observations", e.target.value);
                  setValueInput({
                    ...valueInput,
                    observations: e.target.value,
                  });
                }}
                value={valueInput.observations}
              />
            </FormGroup>
            <Button classID="btn-icon btn-3" color="primary" type="submit">
              <span classID="btn-inner--text">Guardar</span>
            </Button>
            <Button
              classID="btn-icon btn-3"
              color="warning"
              type="button"
              onClick={(e) => cancelForm(e)}
            >
              <span classID="btn-inner--text">Cancelar</span>
            </Button>
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
