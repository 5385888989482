import React, { useEffect } from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProviderForm } from "src/views/pages/providers/ProviderForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProviderCreate = ({
  create,
  getLocalities,
  localitiesList,
  getCountries,
  getProvinces,
  localitySelected,
  getLocality,
  getProvincesByID,
  getProviderType,
  providersTypeList,
}) => {
  useEffect(() => {
    getLocalities();
    getProviderType();
  }, [getLocalities]);

  useEffect(() => {
    getProviderType();
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Proveedor" parentName="Admin"></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        <h2>{"Agregar Proveedor"}</h2>
        <ProviderForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
          localities={localitiesList}
          getCountries={getCountries}
          getProvinces={getProvinces}
          localitySelected={localitySelected}
          getLocality={getLocality}
          getProvincesByID={getProvincesByID}
          providersType={providersTypeList}
        />
      </Container>
      <AdminFooter />
    </main>
  );
};
