import { CLIENT_ERRORS } from "../../actions/client/clientAction";
import { USER_ERRORS } from "../../actions/users/usersAction";
import { PROVIDER_ERRORS } from "../../actions/provider/providersAction";
import { MOVEMENTS_ERRORS } from "../../actions/movements/movementsAction";
import { PROCESS_ERRORS } from "../../actions/process/processAction";
import { PROCESS_TEMPLATE_ERRORS } from "../../actions/processTemplate/processTemplateAction";
import { PROCESS_TEMPLATE_CATEGORY_ERRORS } from "../../actions/processTemplateCategory/processTemplateCategoryAction";
import { PRODUCTS_ERRORS } from "../../actions/products/productsAction";
import { PRODUCT_TYPE_ERRORS } from "../../actions/productType/productTypeAction";
import { PROVIDER_TYPE_ERRORS } from "../../actions/providerType/providerTypeAction";
import { STORAGE_UNIT_ERRORS } from "../../actions/storageUnit/storageUnitAction";
import { UNIT_OF_MEASUREMENT_ERRORS } from "../../actions/unitOfMeasurement/unitOfMeasurementAction";

export const setErrors = (type, error) => ({
    type: type,
    payload: error.params.message
})

export const resetErrors = type => ({
    type: type,
    payload: null
})

export const setErrorMessages = (type, error) => async (dispatch) => {
    try {
        dispatch(setErrors(type,error));
    } catch (error) {
        console.log(error)
    }
}

export const resetErrorMessages = (type) => async (dispatch) => {
    try {
        switch(type) {
            case 'CLIENT_ERRORS':
                dispatch(resetErrors(CLIENT_ERRORS));
            case 'PROVIDER_ERRORS':
                dispatch(resetErrors(PROVIDER_ERRORS));
            case 'USER_ERRORS':
                dispatch(resetErrors(USER_ERRORS));
            case 'MOVEMENTS_ERRORS':
                dispatch(resetErrors(MOVEMENTS_ERRORS));
            case 'PROCESS_ERRORS':
                dispatch(resetErrors(PROCESS_ERRORS));
            case 'PROCESS_TEMPLATE_ERRORS':
                dispatch(resetErrors(PROCESS_TEMPLATE_ERRORS));
            case 'PROCESS_TEMPLATE_CATEGORY_ERRORS':
                dispatch(resetErrors(PROCESS_TEMPLATE_CATEGORY_ERRORS));
            case 'PRODUCTS_ERRORS':
                dispatch(resetErrors(PRODUCTS_ERRORS));
            case 'PRODUCT_TYPE_ERRORS':
                dispatch(resetErrors(PRODUCT_TYPE_ERRORS));
            case 'PROVIDER_TYPE_ERRORS':
                dispatch(resetErrors(PROVIDER_TYPE_ERRORS));
            case 'STORAGE_UNIT_ERRORS':
                dispatch(resetErrors(STORAGE_UNIT_ERRORS));
            case 'UNIT_OF_MEASUREMENT_ERRORS':
                dispatch(resetErrors(UNIT_OF_MEASUREMENT_ERRORS));
        }
    } catch (error) {
        console.log(error)
    }
}
