import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { ProductTypeForm } from "src/views/pages/productType/ProductTypeForm";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import AdminFooter from "src/components/Footers/AdminFooter";

export const ProductTypeUpdate = ({
  productTypeById,
  productTypeFound,
  update,
  loading,
}) => {
  const { id } = useParams();

  useEffect(() => {
    productTypeById(id);
  }, [id, productTypeById]);

  return (
    <main className="overflow-auto">
      <SimpleHeader name="Tipo de producto" parentName="Admin"></SimpleHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Container fluid>
          <h2>{"Editar Tipo de producto"}</h2>
          <ProductTypeForm
            elementData={productTypeFound}
            nameForm={"edit"}
            id={id}
            create={null}
            update={update}
          />
        </Container>
      )}
      <AdminFooter />
    </main>
  );
};
