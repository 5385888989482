import { ProcessTemplateCategoryDetailAPI } from "src/infrastructure/api/api-processTemplateCategoryDetail";
import { fetchStartLoading, fetchStopLoading } from '../loading/loadingAction';

//#region ProcessTemplateCategoryDetail Const
export const CREATE_PROCESS_TEMPLATE_CATEGORY_DETAIL = 'CREATE_PROCESS_TEMPLATE_CATEGORY_DETAIL'
export const GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_LIST = 'GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_LIST'
export const GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_ID = 'GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_ID'
export const UPDATE_PROCESS_TEMPLATE_CATEGORY_DETAIL = 'UPDATE_PROCESS_TEMPLATE_CATEGORY_DETAIL'
export const DELETE_PROCESS_TEMPLATE_CATEGORY_DETAIL = 'DELETE_PROCESS_TEMPLATE_CATEGORY_DETAIL'
//#endregion

//#region ProcessTemplateCategoryDetail Actions

export const fetchCreateProcessTemplateCategoryDetail = response => ({
    type: CREATE_PROCESS_TEMPLATE_CATEGORY_DETAIL,
    payload: response.data
})

export const fetchGetProcessTemplateCategoryDetailList = response => ({
    type: GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_LIST,
    payload: response.data
})

export const fetchProcessTemplateCategoryDetailById = response => ({
    type: GET_PROCESS_TEMPLATE_CATEGORY_DETAIL_ID,
    payload: response.data
})

export const fetchUpdateProcessTemplateCategoryDetail = response => ({
    type: UPDATE_PROCESS_TEMPLATE_CATEGORY_DETAIL,
    payload: response
})

export const fetchDeleteProcessTemplateCategoryDetail = response => ({
    type: DELETE_PROCESS_TEMPLATE_CATEGORY_DETAIL,
    payload: response
})

//#endregion

const processTemplateCategoryDetailAPI = new ProcessTemplateCategoryDetailAPI()

//#region ProcessTemplateCategoryDetails Dispach

// Dispatch create processTemplateCategoryDetail
export const createProcessTemplateCategoryDetail = (processTemplateCategoryDetailBody) => async (dispatch) => {
    try {
        dispatch(fetchCreateProcessTemplateCategoryDetail(await processTemplateCategoryDetailAPI.createProcessTemplateCategoryDetailApi(processTemplateCategoryDetailBody)));
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get processTemplateCategoryDetail list
export const getProcessTemplateCategoryDetailList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchGetProcessTemplateCategoryDetailList(await processTemplateCategoryDetailAPI.getProcessTemplateCategoryDetailsApi(specification)))
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get processTemplateCategoryDetail by id
export const getProcessTemplateCategoryDetailById = (id) => async (dispatch) => {
    try {
        dispatch(fetchProcessTemplateCategoryDetailById(await processTemplateCategoryDetailAPI.getProcessTemplateCategoryDetailByIdApi(id)))
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Reset DetailFound data in the modal
export const resetProcessTemplateCategoryDetail = (id) => async (dispatch) => {
    try {
        dispatch(fetchProcessTemplateCategoryDetailById(null))
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update processTemplateCategoryDetail
export const updateProcessTemplateCategoryDetail = (id, body) => async (dispatch) => {
    try {
        dispatch(fetchUpdateProcessTemplateCategoryDetail(await processTemplateCategoryDetailAPI.updateProcessTemplateCategoryDetailApi(id, body)))
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete processTemplateCategoryDetail
export const deleteProcessTemplateCategoryDetail = (id) => async (dispatch) => {
    try {
        dispatch(fetchDeleteProcessTemplateCategoryDetail(await processTemplateCategoryDetailAPI.deleteProcessTemplateCategoryDetailApi(id)))
        dispatch(fetchStartLoading())
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}
//#endregion
