import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsUpdate } from "src/views/pages/products/ProductsUpdate";
import {
  getProductById,
  updateProducts,
} from "src/infrastructure/redux/store/actions/products/productsAction";
import { getProductTypeList } from "src/infrastructure/redux/store/actions/productType/productTypeAction";
import { getUnitOfMeasurementList } from "src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction";

export const UpdateProducts = () => {
  const dispatch = useDispatch();

  const productsById = useCallback(async (idProducts) => {
    const expand = "unitOfMeasurement, productType"
      await dispatch(getProductById(idProducts, expand));
    },
    [dispatch]
  );

  const productsFound = useSelector((store) => store.products.productsFound);

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateProducts(id, body));
    },
    [dispatch]
  );

  const getProductsType = useCallback(
    async (specification) => {
      await dispatch(getProductTypeList(specification));
    },
    [dispatch]
  );

  const productTypesList = useSelector(
    (store) => store.productType.ProductTypeList
  );

  const getUnitOfMeasurement = useCallback(
    async (specification) => {
      await dispatch(getUnitOfMeasurementList(specification));
    },
    [dispatch]
  );

  const unitOfMeasurementsList = useSelector(
    (store) => store.unitOfMeasurement.UnitOfMeasurementList
  );

  return (
    <ProductsUpdate
      productsById={productsById}
      productsFound={productsFound}
      update={update}
      getProductsType={getProductsType}
      productTypesList={productTypesList}
      getUnitOfMeasurement={getUnitOfMeasurement}
      unitOfMeasurementsList={unitOfMeasurementsList}
    />
  );
};
