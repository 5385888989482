import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyTraceabilityReportFound,
  getTraceabilityReportById,
  updateTraceabilityReport,
} from "src/infrastructure/redux/store/actions/traceabilityReports/traceabilityReportsAction";
import { TraceabilityReportsUpdate } from "src/views/pages/traceabilityReports/TraceabilityReportsUpdate";

export const UpdateTraceabilityReports = () => {
  const dispatch = useDispatch();

  const traceabilityReportsById = useCallback(
    async (idTraceabilityReports) => {
      await dispatch(getTraceabilityReportById(idTraceabilityReports));
    },
    [dispatch]
  );

  const traceabilityReportsFound = useSelector(
    (store) => store.traceabilityReports.traceabilityReportFound
  );

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateTraceabilityReport(id, body));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => dispatch(emptyTraceabilityReportFound());
  }, []);

  return (
    <TraceabilityReportsUpdate
      handleGetTraceabilityReportsById={traceabilityReportsById}
      traceabilityReportsFound={traceabilityReportsFound}
      update={update}
    />
  );
};
