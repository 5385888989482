export const dataProducts = {
    columns : [
        {
            dataField: "identificationNumber",
            text: "Numero identificatorio",
            sort: true,
        },
        {
            dataField: "name",
            text: "Nombre",
            sort: true,
        },
        {
            dataField: "unitOfMeasurementId",
            text: "Unidad de medida",
            sort: true,
        },
        {
            dataField: "description",
            text: "Descripción",
            sort: true,
        },
        {
            dataField: "productTypeId",
            text: "Tipo de producto",
            sort: true,
        },
    ]
};