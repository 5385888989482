import {
  SET_BATCH_NUMBERS_LIST,
  SET_BATCH_NUMBER_FOUND,
  UPDATE_BATCH_NUMBERS,
  DELETE_BATCH_NUMBERS,
  BATCH_NUMBERS_ERRORS,
  IS_LOADING_BATCH_NUMBERS,
  IS_LOADING_BATCH_NUMBER_BY_ID,
} from "../../actions/batchNumbers/batchNumbersAction";

const initialState = {
  batchNumbersList: null,
  batchNumberFound: null,
  batchNumberUpdated: null,
  batchNumberDeleted: null,
  batchNumberErrors: null,
  isLoadingBatchNumbers: true,
  isLoadingBatchNumberById: true,
};

export const batchNumbersReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_BATCH_NUMBERS_LIST:
      return { ...state, batchNumbersList: payload };
    case SET_BATCH_NUMBER_FOUND:
      return { ...state, batchNumberFound: payload };
    case UPDATE_BATCH_NUMBERS:
      return { ...state, batchNumberUpdated: payload };
    case DELETE_BATCH_NUMBERS:
      return { ...state, batchNumberDeleted: payload };
    case BATCH_NUMBERS_ERRORS:
      return { ...state, batchNumberErrors: payload };
    case IS_LOADING_BATCH_NUMBERS:
      return { ...state, isLoadingBatchNumbers: payload };
    case IS_LOADING_BATCH_NUMBER_BY_ID:
      return { ...state, isLoadingBatchNumberById: payload };
    default:
      return state;
  }
};
