import React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcessTemplateCategoryList,
  deleteProcessTemplateCategory,
} from "src/infrastructure/redux/store/actions/processTemplateCategory/processTemplateCategoryAction";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";
import { ProcessTemplateCategory } from "src/views/pages/processTemplateCategory/ProcessTemplateCategory";

export const ListProcessTemplateCategory = () => {
  const dispatch = useDispatch();

  const getProcessTemplateCategory = useCallback(
    async (specification) => {
      await dispatch(getProcessTemplateCategoryList(specification));
    },
    [dispatch]
  );

  const processTemplateCategoryToDelete = useCallback(
    async (id) => {
      await dispatch(deleteProcessTemplateCategory(id));
    },
    [dispatch]
  );

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("PROCESS_TEMPLATE_CATEGORY_ERRORS"));
  }, [dispatch]);

  const processTemplateCategoryList = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryList
  );
  const processTemplateCategoryDeleted = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryDeleted
  );
  const processTemplateCategoryFound = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryFound
  );
  const processTemplateCategoryCreated = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryCreated
  );
  const processTemplateCategoryUpdated = useSelector(
    (store) => store.processTemplateCategory.processTemplateCategoryUpdated
  );
  const processTemplateCategoryErrors = useSelector(
    (store) => store.errors.processTemplateCategoryErrors
  );
  const isLoadingProcessTemplateCategoryList = useSelector(
    (store) => store.loading.isLoadingProcessTemplateCategoryList
  );

  const enabledTemplate = {
    1: "SI",
    0: "NO",
  };

  const finalProcessTemplateCategoryList = processTemplateCategoryList.map(
    (element, key) => {
      return {
        key,
        ...element,
        enable: enabledTemplate[element.enable],
      };
    }
  );

  return (
    <ProcessTemplateCategory
      getProcessTemplateCategory={getProcessTemplateCategory}
      deleteProcessTemplateCategory={processTemplateCategoryToDelete}
      resetErrors={resetErrors}
      loading={isLoadingProcessTemplateCategoryList}
      processTemplateCategoryList={finalProcessTemplateCategoryList}
      processTemplateCategoryDeleted={processTemplateCategoryDeleted}
      processTemplateCategoryFound={processTemplateCategoryFound}
      processTemplateCategoryCreated={processTemplateCategoryCreated}
      processTemplateCategoryUpdated={processTemplateCategoryUpdated}
      processTemplateCategoryErrors={processTemplateCategoryErrors}
    />
  );
};
