import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import "src/components/Loading/LoadingComponent.css";

export default function Selector({
  items = [], // [{id: 1, name: "name"}]
  onValueChange,
  onFetchMoreData,
  itemsTotalCount,
  defaultValue = "Seleccionar",
  inputStyle = {},
  boxStyle = {},
  handleOpen = () => {},
  handleClose = () => {},
  selectionRemovable = false,
}) {
  const SelectorRef = useRef(null);
  const DropListRef = useRef(null);
  const IntersectorRef = useRef(null);
  const [dropOpen, setDropOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const hasMoreData = itemsTotalCount > items.length;

  useEffect(() => {
    const onClick = (e) => {
      const outsideClicked = !SelectorRef.current.contains(e.target);
      const dropListClicked = DropListRef.current.contains(e.target);

      if (outsideClicked || dropListClicked) {
        handleClose();
        setDropOpen(false);
      }
    };

    if (dropOpen) {
      document.addEventListener("click", onClick);
    }

    return () => document.removeEventListener("click", onClick);
  }, [dropOpen]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!IntersectorRef.current || !DropListRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        const { isIntersecting } = entry;
        if (!isIntersecting || !hasMoreData) return;
        onFetchMoreData();
      },
      {
        root: DropListRef.current,
        threshold: 0.1,
      }
    );

    observer.observe(IntersectorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [dropOpen, hasMoreData]);

  return (
    <div ref={SelectorRef} className="selector" style={boxStyle}>
      <input
        id="processTemplateId"
        name="processTemplateId"
        className="form-control"
        value={value}
        onChange={() => {}}
        onClick={() => {
          setDropOpen(true);
          handleOpen();
        }}
        style={inputStyle}
      />
      {dropOpen && (
        <div className="form-control">
          <ul ref={DropListRef}>
            {items.map((element) => (
              <li key={element.id}>
                <button
                  value={element.id}
                  className={classNames({ active: element.name === value })}
                  onClick={() => {
                    setValue(element.name);
                    onValueChange(element.id);
                  }}
                >
                  {element.name}
                </button>
              </li>
            ))}
            {hasMoreData && (
              <li ref={IntersectorRef} style={{ paddingRight: "30px" }}>
                <span className="loader"> </span>
              </li>
            )}
          </ul>
          {selectionRemovable && value !== defaultValue && !!items.length && (
            <button
              className="btn"
              onClick={() => {
                setValue(defaultValue);
                onValueChange(null);
              }}
            >
              Limpiar
            </button>
          )}
        </div>
      )}
    </div>
  );
}
