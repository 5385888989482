import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import CardsHeader from "src/components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
} from "src/variables/charts.js";

function Dashboard() {


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <CardsHeader name="Estadísticas " parentName="Dashboards" />
      <Container fluid>
      </Container>
    </>
  );
}

export default Dashboard;
