import { ProcessDetailAPI } from "src/infrastructure/api/api-process-detail";
import { fetchStartLoading, fetchStopLoading } from '../loading/loadingAction';

//#region Process Detail Const
export const CREATE_PROCESS_DETAIL = 'CREATE_PROCESS_DETAIL'
export const GET_PROCESS_DETAIL_LIST = 'GET_PROCESS_DETAIL_LIST'
export const GET_PROCESS_DETAIL_ID = 'GET_PROCESS_DETAIL_ID'
export const UPDATE_PROCESS_DETAIL = 'UPDATE_PROCESS_DETAIL'
export const DELETE_PROCESS_DETAIL = 'DELETE_PROCESS_DETAIL'
//#endregion

//#region Process Detail Actions

export const fetchCreateProcessDetail = response => ({
    type: CREATE_PROCESS_DETAIL,
    payload: response.data
})

export const fetchGetProcessDetailList = response => ({
    type: GET_PROCESS_DETAIL_LIST,
    payload: response.data
})

export const fetchProcessDetailById = response => ({
    type: GET_PROCESS_DETAIL_ID,
    payload: response
})

export const fetchUpdateProcessDetail = response => ({
    type: UPDATE_PROCESS_DETAIL,
    payload: response
})

export const fetchDeleteProcessDetail = response => ({
    type: DELETE_PROCESS_DETAIL,
    payload: response
})

//#endregion

const processDetailAPI = new ProcessDetailAPI()

//#region Processs Detail Dispach

// Dispatch create process Detail
export const createProcessDetail = (processDetailBody) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchCreateProcessDetail(await processDetailAPI.createProcessDetailApi(processDetailBody)));
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get process Detail list 
export const getProcessDetailList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchGetProcessDetailList(await processDetailAPI.getProcessDetailApi(specification)))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get process Detail by id
export const getProcessDetailById = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchProcessDetailById(await processDetailAPI.getProcessDetailByIdApi(id)))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update process Detail
export const updateProcessDetail = (id, body) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchUpdateProcessDetail(await processDetailAPI.updateProcessDetailApi(id, body)))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete process Detail
export const deleteProcessDetail = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchDeleteProcessDetail(await processDetailAPI.deleteProcessDetailApi(id)))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}
//#endregion
//#endregion Processs Detail Dispach
