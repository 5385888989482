import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UsersUpdate } from 'src/views/pages/users/UsersUpdate';
import { getUsersById, updateUsers } from 'src/infrastructure/redux/store/actions/users/usersAction';
import { getRolesList } from "src/infrastructure/redux/store/actions/roles/rolesAction";

export const UpdateUsers = () => {

    const dispatch = useDispatch();

    const usersById = useCallback(async (id) => {
        await dispatch(getUsersById(id));
    }, [dispatch]);

    const usersFound = useSelector(store => store.users.UsersFound)

    const update = useCallback(async (id, body) => {
        await dispatch(updateUsers(id, body));
    }, [dispatch]);

    const getRoles = useCallback(async (body) => {
        await dispatch(getRolesList(body));
    }, [dispatch]);

    const rolesList = useSelector(store => store.roles.RolesList)

    return (
        <UsersUpdate
            UsersById={usersById}
            UsersFound={usersFound}
            update={update}
            getRoles={getRoles}
            rolesList={rolesList}
        />
    )
}
