import Dashboard from "src/views/pages/dashboards/Dashboard.js";

import { Home } from "src/views/pages/home/Home";

import { ListProvider } from "src/containers/providers/ListProvider";
import { CreateProvider } from "src/containers/providers/CreateProvider";
import { UpdateProvider } from "src/containers/providers/UpdateProvider";

import { ListProviderType } from "src/containers/providersType/ListProviderType";
import { CreateProviderType } from "src/containers/providersType/CreateProviderType";
import { UpdateProviderType } from "src/containers/providersType/UpdateProviderType";

import { ListClient } from "src/containers/clients/ListClient";
import { CreateClient } from "src/containers/clients/CreateClient";
import { UpdateClient } from "src/containers/clients/UpdateClient";

import { ListProducts } from "src/containers/products/ListProducts";
import { CreateProducts } from "src/containers/products/CreateProducts";
import { UpdateProducts } from "src/containers/products/UpdateProducts";

import { ListUnitOfMeasurement } from "src/containers/unitOfMeasurement/ListUnitOfMeasurement";
import { CreateUnitOfMeasurement } from "src/containers/unitOfMeasurement/CreateUnitOfMeasurement";
import { UpdateUnitOfMeasurement } from "src/containers/unitOfMeasurement/UpdateUnitOfMeasurement";

import { ListProductType } from "src/containers/productType/ListProductType";
import { CreateProductType } from "src/containers/productType/CreateProductType";
import { UpdateProductType } from "src/containers/productType/UpdateProductType";

import { ListProcess } from "src/containers/process/ListProcess";
import { CreateProcess } from "src/containers/process/CreateProcess";
import { UpdateProcess } from "src/containers/process/UpdateProcess";

import { ListProcessTemplate } from "src/containers/processTemplate/ListProcessTemplate";
import { CreateProcessTemplate } from "src/containers/processTemplate/CreateProcessTemplate";
import { UpdateProcessTemplate } from "src/containers/processTemplate/UpdateProcessTemplate";

import { ListProcessTemplateCategory } from "src/containers/processTemplateCategory/ListProcessTemplateCategory";
import { CreateProcessTemplateCategory } from "src/containers/processTemplateCategory/CreateProcessTemplateCategory";
import { UpdateProcessTemplateCategory } from "src/containers/processTemplateCategory/UpdateProcessTemplateCategory";

import { ListStorageUnit } from "src/containers/storageUnit/ListStorageUnit";
import { CreateStorageUnit } from "src/containers/storageUnit/CreateStorageUnit";
import { UpdateStorageUnit } from "src/containers/storageUnit/UpdateStorageUnit";

import { ListUsers } from "src/containers/users/ListUsers";
import { CreateUsers } from "src/containers/users/CreateUsers";
import { UpdateUsers } from "src/containers/users/UpdateUsers";

import { ListMovements } from "src/containers/movements/ListMovements";
import { CreateMovements } from "src/containers/movements/CreateMovements";
import { UpdateMovements } from "src/containers/movements/UpdateMovements";
import { ViewDetailMovements } from "src/containers/movements/ViewDetailMovements";
import { Batches } from "src/views/pages/batches/Batches";
import { BatchView } from "src/views/pages/batches/BatchView";
import { StorageUnitDetails } from "src/views/pages/storageUnit/StorageUnitDetails";
import { Configuration } from "./containers/configuration/Configuration";
import { ViewProcess } from "./containers/process/ViewProcess";
import { ListTraceabilityReports } from "./containers/traceabilityReports/ListTraceabilityReports";
import { UpdateTraceabilityReports } from "./containers/traceabilityReports/UpdateTraceabilityReports";
import { CreateTraceabilityReports } from "./containers/traceabilityReports/CreateTraceabilityReports";
import { ListBatchNumbers } from "./containers/batchNumbers/ListBatchNumbers";
import { CreateBatchNumber } from "./containers/batchNumbers/CreateBatchNumber";
import { UpdateBatchNumber } from "./containers/batchNumbers/UpdateBatchNumber";
import { TraceabilityReportsDetail } from "./views/pages/traceabilityReports/TraceabilityReportsDetail";

// ROLES
const personalDeBascula = "Personal de Báscula";
const operario = "Operario";
const laboratorista = "Laboratorista";
const jefeProduccion = "Jefe de Producción";
const jefeCalidad = "Jefe de Calidad";
const root = "Root";

const routes = [
  {
    path: "/home",
    name: "Home",
    miniName: "H",
    element: <Home />,
    layout: "/admin",
    icon: "fas fa-home text-primary",
    visibility: true,
    roles: [root],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    miniName: "D",
    element: <Dashboard />,
    layout: "/admin",
    icon: "fas fa-chalkboard text-primary",
    visibility: false,
    roles: "",
  },
  {
    path: "/users",
    name: "Usuarios",
    miniName: "U",
    element: <ListUsers />,
    layout: "/admin",
    icon: "fas fa-user text-primary",
    visibility: true,
    roles: [root],
  },
  {
    path: "/users/create",
    name: "Usuarios",
    miniName: "U",
    element: <CreateUsers />,
    layout: "/admin",
    icon: "fas fa-user text-primary",
    visibility: false,
  },
  {
    path: "/users/edit/:id",
    name: "Usuarios",
    miniName: "U",
    element: <UpdateUsers />,
    layout: "/admin",
    icon: "fas fa-user text-primary",
    visibility: false,
  },
  {
    path: "/providers",
    name: "Proveedores",
    miniName: "P",
    element: <ListProvider />,
    layout: "/admin",
    icon: "fas fa-briefcase text-primary",
    visibility: true,
    roles: [personalDeBascula, operario, laboratorista, jefeCalidad, root],
  },
  {
    path: "/providers/create",
    name: "Crear Provedor",
    miniName: "P",
    element: <CreateProvider />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/providers/edit/:id",
    name: "Editar Provedor",
    miniName: "P",
    element: <UpdateProvider />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/clients",
    name: "Clientes",
    miniName: "C",
    element: <ListClient />,
    layout: "/admin",
    icon: "fas fa-user text-primary",
    visibility: true,
    roles: [personalDeBascula, operario, laboratorista, jefeCalidad, root],
  },
  {
    path: "/clients/create",
    name: "Crear Cliente",
    miniName: "C",
    element: <CreateClient />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/clients/edit/:id",
    name: "Editar Cliente",
    miniName: "C",
    element: <UpdateClient />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/storage-units",
    name: "Unidad de almacenamiento",
    miniName: "SU",
    element: <ListStorageUnit />,
    layout: "/admin",
    icon: "fas fa-box-open text-primary",
    visibility: true,
    roles: [personalDeBascula, operario, laboratorista, jefeCalidad, root],
  },
  {
    path: "/storage-units/create",
    name: "Crear unidad de almacenamiento",
    miniName: "SU",
    element: <CreateStorageUnit />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/storage-units/edit/:id",
    name: "Crear unidad de almacenamiento",
    miniName: "SU",
    element: <UpdateStorageUnit />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/storage-units/:id",
    name: "Unidad de almacenamiento",
    miniName: "SU",
    element: <StorageUnitDetails />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/unit-of-measurement",
    name: "Unidad de medida",
    miniName: "U",
    element: <ListUnitOfMeasurement />,
    layout: "/admin",
    icon: "fas fa-weight text-primary",
    visibility: true,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/unit-of-measurement/create",
    name: "Crear unidad de medida",
    miniName: "U",
    element: <CreateUnitOfMeasurement />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/batches",
    name: "Lotes",
    miniName: "SU",
    element: <Batches />,
    layout: "/admin",
    icon: "fa-solid fa-barcode text-primary",
    visibility: true,
    roles: [personalDeBascula, operario, laboratorista, jefeCalidad, root],
  },
  {
    path: "/batches/:id",
    name: "batch view",
    miniName: "SU",
    element: <BatchView />,
    layout: "/admin",
    icon: "",
    visibility: false,
    roles: [personalDeBascula, operario, laboratorista, jefeCalidad, root],
  },
  {
    path: "/batch-numbers",
    name: "Números de lote",
    miniName: "BN",
    element: <ListBatchNumbers />,
    layout: "/admin",
    icon: "fa-solid fa-barcode text-primary",
    visibility: true,
    roles: [root],
  },
  {
    path: "/batch-numbers/create",
    name: "Movimientos",
    miniName: "BNU",
    element: <CreateBatchNumber />,
    layout: "/admin",
    visibility: false,
    roles: [root],
  },
  {
    path: "/batch-numbers/edit/:id",
    name: "Movimientos",
    miniName: "BNV",
    element: <UpdateBatchNumber />,
    layout: "/admin",
    visibility: false,
    roles: [root],
  },
  {
    path: "/unit-of-measurement/edit/:id",
    name: "Editar unidad de medida",
    miniName: "U",
    element: <UpdateUnitOfMeasurement />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/products-type",
    name: "Tipo de producto",
    miniName: "P",
    element: <ListProductType />,
    layout: "/admin",
    icon: "fas fa-wine-glass-alt text-primary",
    visibility: true,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/products-type/create",
    name: "Crear tipo de producto",
    miniName: "P",
    element: <CreateProductType />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/products-type/edit/:id",
    name: "Editar tipo de producto",
    miniName: "P",
    element: <UpdateProductType />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, root],
  },
  {
    path: "/products",
    name: "Productos",
    miniName: "P",
    element: <ListProducts />,
    layout: "/admin",
    icon: "fas fa-wine-bottle text-primary",
    visibility: true,
    roles: [
      personalDeBascula,
      operario,
      laboratorista,
      jefeCalidad,
      jefeProduccion,
      root,
    ],
  },
  {
    path: "/products/create",
    name: "Crear Producto",
    miniName: "p",
    element: <CreateProducts />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/products/edit/:id",
    name: "Editar Producto",
    miniName: "p",
    element: <UpdateProducts />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/traceability-reports",
    name: "Reporte de Trazabilidad",
    miniName: "RT",
    element: <ListTraceabilityReports />,
    layout: "/admin",
    icon: "fas fa-file text-primary",
    visibility: true,
    roles: [
      personalDeBascula,
      operario,
      laboratorista,
      jefeCalidad,
      jefeProduccion,
      root,
    ],
  },
  {
    path: "/traceability-reports/create",
    name: "Reporte de Trazabilidad",
    miniName: "RT",
    element: <CreateTraceabilityReports />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/traceability-reports/edit/:id",
    name: "Editar Producto",
    miniName: "p",
    element: <UpdateTraceabilityReports />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/traceability-reports/:id",
    name: "Editar Producto",
    miniName: "p",
    element: <TraceabilityReportsDetail />,
    layout: "/admin",
    visibility: false,
    roles: [laboratorista, jefeCalidad, jefeProduccion, root],
  },
  {
    path: "/process-template",
    name: "Plantilla Proceso",
    miniName: "PP",
    element: <ListProcessTemplate />,
    layout: "/admin",
    icon: "fas fa-microchip text-primary",
    visibility: true,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process-template/create",
    name: "Crear plantilla proceso",
    miniName: "PP",
    element: <CreateProcessTemplate />,
    layout: "/admin",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process-template/edit/:id",
    name: "Editar plantilla proceso",
    miniName: "PP",
    element: <UpdateProcessTemplate />,
    layout: "/admin",
    icon: "fas fa-list-ul text-primary",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process-template-category",
    name: "Categoria de Plantilla de Proceso",
    miniName: "CPP",
    element: <ListProcessTemplateCategory />,
    layout: "/admin",
    icon: "fas fa-list-ul text-primary",
    visibility: true,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process-template-category/create",
    name: "Crear categoria de plantilla proceso",
    miniName: "CPP",
    element: <CreateProcessTemplateCategory />,
    layout: "/admin",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process-template-category/edit/:id",
    name: "Editar categoria de plantilla proceso",
    miniName: "CPP",
    element: <UpdateProcessTemplateCategory />,
    layout: "/admin",
    icon: "fas fa-list-ul text-primary",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process",
    name: "Proceso",
    miniName: "PP",
    element: <ListProcess />,
    layout: "/admin",
    icon: "fas fas fa-cogs text-primary",
    visibility: true,
    roles: [personalDeBascula, operario, jefeCalidad, root],
  },
  {
    path: "/process/create/:processTemplateId",
    name: "Crear proceso",
    miniName: "PP",
    element: <CreateProcess />,
    layout: "/admin",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process/edit/:id",
    name: "Editar proceso",
    miniName: "PP",
    element: <UpdateProcess />,
    layout: "/admin",
    icon: "fas fa-list-ul text-primary",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/process/view/:id",
    name: "Editar proceso",
    miniName: "PP",
    element: <ViewProcess />,
    layout: "/admin",
    icon: "fas fa-list-ul text-primary",
    visibility: false,
    roles: [jefeCalidad, root],
  },
  {
    path: "/configuration",
    name: "Configuración",
    miniName: "CC",
    element: <Configuration />,
    layout: "/admin",
    icon: "fas fa-cogs text-primary",
    visibility: true,
    roles: [
      personalDeBascula,
      operario,
      laboratorista,
      jefeCalidad,
      jefeProduccion,
      root,
    ],
  },
  {
    path: "/providers-type",
    name: "Tipo de provedor",
    miniName: "P",
    element: <ListProviderType />,
    layout: "/admin",
    icon: "fas fa-wine-glass-alt text-primary",
    visibility: true,
    roles: "",
  },
  {
    path: "/providers-type/create",
    name: "Crear tipo de provedor",
    miniName: "P",
    element: <CreateProviderType />,
    layout: "/admin",
    visibility: false,
  },
  {
    path: "/providers-type/edit/:id",
    name: "Editar tipo de provedor",
    miniName: "P",
    element: <UpdateProviderType />,
    layout: "/admin",
    visibility: false,
  },
];

export default routes;
