
//#region Profile Const
export const ADD_DATA_PROFILE = 'ADD_DATA_PROFILE';
//#endregion

//#region Profile Actions

// Create Profile
export const fetchCreateProfile = response => ({
  type: ADD_DATA_PROFILE,
  payload: response.data
})

//#endregion
export const createProfile = (profile) => async (dispatch) => {

  try {
    dispatch(fetchCreateProfile(profile))
  } catch (error) {
    console.log(error)
  }
};