import {START_LOADING, STOP_LOADING} from '../../actions/loading/loadingAction';

  const initalState = {
    loading: true,
  }
  
  export const loadingReducer = (state = initalState, { type, payload } ) => {
      switch (type) {
      case START_LOADING:
        return {
          ...state,
          loading: true,
        }
  
      case STOP_LOADING:
        return {
          ...state,
          loading: false,
        }
        
      default:
        return state
    }
  }