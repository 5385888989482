import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import { batchNumberTypes } from "src/variables/batchNumbers";

export const ProductsForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  productTypes,
  unitOfMeasurement,
  batchNumbers,
}) => {
  const [Loading, setLoading] = useState();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      identificationNumber: elementData?.identificationNumber ?? "",
      name: elementData?.name ?? "",
      productTypeId: elementData?.productTypeId ?? "",
      unitOfMeasurementId: elementData?.unitOfMeasurementId ?? "",
      description: elementData?.description ?? "",
      batchNumberId: elementData?.batchNumberId ?? "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        setLoading(false);
        if (!Loading) {
          navigate("/admin/products");
        }
      } else if (nameForm === "update") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/products");
      }
    },
  });

  const cancelForm = (e) => {
    navigate("/admin/products");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="identificationNumber">
              Numero de identificacion
            </label>
            <Input
              id="identificationNumber"
              name="identificationNumber"
              type="number"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.identificationNumber}
              required={!elementData}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="name">Nombre</label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              required={!elementData}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="batchNumberId">Número de Lote</label>
            <Input
              id="batchNumberId"
              name="batchNumberId"
              type="select"
              required={!elementData}
              defaultValue={formik.values.batchNumberId}
              onChange={formik.handleChange}
            >
              <option value={""}>Seleccionar</option>
              {batchNumbers.map((batchNumber) => (
                <option key={batchNumber.id} value={batchNumber.id}>
                  {
                    batchNumberTypes.find((t) => t.id === batchNumber.type)
                      ?.name
                  }
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <label htmlFor="productTypeId">Tipo de producto</label>
            <Input
              id="productTypeId"
              name="productTypeId"
              type="select"
              required={!elementData}
              defaultValue={formik.values.productTypeId}
              onChange={formik.handleChange}
            >
              {!elementData && <option value={""}>Seleccionar</option>}
              {productTypes.map((productType) => (
                <option key={productType.id} value={productType.id}>
                  {productType.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <label htmlFor="unitOfMeasurementId">Unidad de medida</label>
            <Input
              id="unitOfMeasurementId"
              name="unitOfMeasurementId"
              type="select"
              required={!elementData}
              onChange={formik.handleChange}
              defaultValue={formik.values.unitOfMeasurementId}
            >
              {!elementData && <option value={""}>Seleccionar</option>}
              {unitOfMeasurement.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <label htmlFor="description">Descripcion</label>
            <Input
              id="description"
              name="description"
              rows="3"
              onChange={formik.handleChange}
              value={formik.values.description}
              required={!elementData}
            />
          </FormGroup>
          <Button classID="btn-icon btn-3" color="primary" type="submit">
            <span classID="btn-inner--text">Guardar</span>
          </Button>
          <Button
            classID="btn-icon btn-3"
            color="warning"
            type="button"
            onClick={(e) => cancelForm(e)}
          >
            <span classID="btn-inner--text">Cancelar</span>
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
