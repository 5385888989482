import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProcessTemplateList } from 'src/infrastructure/redux/store/actions/processTemplate/processTemplateAction'
import { deleteProcessTemplate } from 'src/infrastructure/redux/store/actions/processTemplate/processTemplateAction'
import { resetErrorMessages } from 'src/infrastructure/redux/store/actions/errors/errorsAction'
import { ProcessTemplate } from 'src/views/pages/processTemplate/ProcessTemplate'

export const ListProcessTemplate = () => {

    const dispatch = useDispatch();

    const processTemplateToDelete = useCallback(async (id) => {
        await dispatch(deleteProcessTemplate(id));
    }, [dispatch]);

    const getAllProcessTemplates = useCallback(async (specification) => {
        await dispatch(getProcessTemplateList(specification));
    }, [dispatch]);

    const resetErrors = useCallback(async () => {
        await dispatch(resetErrorMessages('PROCESS_TEMPLATE_ERRORS'));
    }, [dispatch]);

    const processTemplateList = useSelector(store => store.processTemplate.processTemplateList)
    const processTemplateFound = useSelector(store => store.processTemplate.processTemplateFound)
    const processTemplateDeleted = useSelector(store => store.processTemplate.processTemplateDeleted)
    const processTemplateCreated = useSelector(store => store.processTemplate.processTemplateCreated)
    const processTemplateUpdated = useSelector(store => store.processTemplate.processTemplateUpdated)
    const processTemplateErrors = useSelector(store => store.errors.processTemplateErrors)
    const loading = useSelector(store => store.loading)

    const types = {
        1: 'SI',
        0: 'NO',
        null: 'NO (null)'
    }

    const finalProcessTemplateList = processTemplateList?.data?.map((processTemplate, key) => {
        return {
            key,
            ...processTemplate,
            requireProvider: types[processTemplate.requireProvider],
            requireClient: types[processTemplate.requireClient],
            requireProduct: types[processTemplate.requireProduct],
            requireStorageUnit: types[processTemplate.requireStorageUnit],
            increases: types[processTemplate.increases],
            decreases: types[processTemplate.decreases],
            resetStorageUnit: types[processTemplate.resetStorageUnit],
            generateBatch: types[processTemplate.generateBatch],
        }
    });

    return <ProcessTemplate
        getProcessTemplates={getAllProcessTemplates}
        deleteProcessTemplates={processTemplateToDelete}
        resetErrors={resetErrors}
        loading={loading.loading}
        processTemplateList={{ ...processTemplateList, data: finalProcessTemplateList }}
        processTemplateFound={processTemplateFound}
        processTemplateDeleted={processTemplateDeleted}
        processTemplateCreated={processTemplateCreated}
        processTemplateUpdated={processTemplateUpdated}
        processTemplateErrors={processTemplateErrors}
    />
}
