import { ButtonGroup } from "reactstrap";

export const dataLotes = {
    columns: [
        {
            dataField: "product.name",
            text: "Producto",
            sort: true,
        },
        {
            dataField: "batchNumber",
            text: "Numero de lote",
            sort: true,
        },
        {
            dataField: "storageUnit.number",
            text: "Unidad de Almacenamiento",
            sort: true,
        },
        {
            dataField: "createdAt",
            text: "Fecha de Carga",
            sort: true,
        },
        {
            dataField: "actions",
            text: "Acciones",
            formatter: (cellContent, row) => {
                return (
                    <ButtonGroup>
                        <button
                            className="btn btn-secondary btn-xs mr-3"
                            onClick={() => {
                                const newPath = `${window.location.hash}/${row.id}`
                                window.location.href = newPath
                            }}
                        >
                            Detalle
                        </button>
                    </ButtonGroup>
                );
            },
        }
    ]
};
