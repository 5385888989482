import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { fetchUpdateConfiguration } from "src/infrastructure/redux/store/actions/configuration/configurationAction";

export const ConfigurationForm = ({ defaultData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingUpdate } = useSelector((store) => store.configuration);

  const formik = useFormik({
    initialValues: {
      businessName: defaultData?.businessName || "",
      webPage: defaultData?.webPage || "",
      batchNumber: defaultData?.batchNumber || "",
      batchType: defaultData?.batchType || "",
      prefix: defaultData?.prefix || "",
      subfix: defaultData?.subfix || "",
      driveCredentials: defaultData?.driveCredentials || "",
      driveFolderId: defaultData?.driveFolderId || "",
    },
    onSubmit: (values) => {
      dispatch(fetchUpdateConfiguration(values));
    },
  });

  const hnadleCancel = () => {
    navigate("/admin/home");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="businessName">Nombre</label>
            <Input
              id="businessName"
              name="businessName"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.businessName}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="webPage">Página Web</label>
            <Input
              id="webPage"
              name="webPage"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.webPage}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="batchNumber">Número Actual de Lote</label>
            <Input
              id="batchNumber"
              name="batchNumber"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.batchNumber}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="batchType">Tipo de Lote</label>
            <Input
              id="batchType"
              name="batchType"
              type="select"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.batchType}
            >
              <option value="A">Alfanumerico</option>
              <option value="N">Numerico</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <label htmlFor="prefix">Prefijo</label>
            <Input
              id="prefix"
              name="prefix"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.prefix}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="subfix">Subfijo</label>
            <Input
              id="subfix"
              name="subfix"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.subfix}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="driveCredentials">Credenciales de Drive</label>
            <Input
              id="driveCredentials"
              name="driveCredentials"
              type="textarea"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.driveCredentials}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="driveFolderId">Carpeta de Drive</label>
            <Input
              id="driveFolderId"
              name="driveFolderId"
              type="text"
              min={0}
              onChange={formik.handleChange}
              value={formik.values.driveFolderId}
            />
          </FormGroup>

          <Button
            classID="btn-icon btn-3"
            color="primary"
            type="submit"
            disabled={isLoadingUpdate}
          >
            <span classID="btn-inner--text">Guardar</span>
          </Button>
          <Button
            classID="btn-icon btn-3"
            color="warning"
            type="button"
            onClick={hnadleCancel}
            disabled={isLoadingUpdate}
          >
            <span classID="btn-inner--text">Cancelar</span>
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
