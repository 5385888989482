import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import SimpleHeader from "src/components/Headers/SimpleHeader";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import {
  emptyTraceabilityReportFound,
  getTraceabilityReportById,
  publishTraceabilityReport,
  updateTraceabilityReport,
} from "src/infrastructure/redux/store/actions/traceabilityReports/traceabilityReportsAction";
import { TraceabilityReportsForm } from "./TraceabilityReportsForm";
import AdminFooter from "src/components/Footers/AdminFooter";
import { TraceabilityReportsAPI } from "src/infrastructure/api/api-traceability-reports";

export const TraceabilityReportsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const traceabilityReportsAPI = new TraceabilityReportsAPI();
  const navigate = useNavigate();

  const traceabilityReportsById = useCallback(
    async (idTraceabilityReports) => {
      await dispatch(getTraceabilityReportById(idTraceabilityReports));
    },
    [dispatch]
  );

  const traceabilityReportsFound = useSelector(
    (store) => store.traceabilityReports.traceabilityReportFound
  );

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateTraceabilityReport(id, body));
    },
    [dispatch]
  );

  const downloadPDF = async (id) => {
    traceabilityReportsAPI.getTraceabilityReportPDFApi(id);
  };

  const handlePublishTraceabilityReport = useCallback(
    async (id) => {
      await dispatch(publishTraceabilityReport(id));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => dispatch(emptyTraceabilityReportFound());
  }, []);

  const isLoading = useSelector(
    (store) => store.traceabilityReports.isLoadingtraceabilityReportById
  );

  useEffect(() => {
    traceabilityReportsById(id);
  }, []);

  return (
    <main className="overflow-auto">
      <SimpleHeader
        name="Reporte de Trazabilidad"
        parentName="Admin"
      ></SimpleHeader>
      <Container fluid>
        <h2>{"Detalle de Reporte de Trazabilidad"}</h2>
        {isLoading && <LoadingComponent />}
        {!isLoading && traceabilityReportsFound && (
          <TraceabilityReportsForm
            elementData={traceabilityReportsFound}
            nameForm={"update"}
            id={id}
            create={null}
            update={update}
            key={Math.random()}
            readOnly
          />
        )}
        <section className="d-flex flex-column">
          {traceabilityReportsFound?.link && (
            <a
              href={traceabilityReportsFound?.link}
              target="_blank"
              rel="noreferrer"
            >
              {traceabilityReportsFound?.link}
            </a>
          )}
          {traceabilityReportsFound?.qr && (
            <img
              style={{ width: 200 }}
              src={traceabilityReportsFound?.qr}
              alt="QR"
            />
          )}
        </section>
        <footer>
          {traceabilityReportsFound?.publish === 0 && (
            <Button
              onClick={() => handlePublishTraceabilityReport(id)}
              classID="btn-icon btn-3"
              color="success"
              type="submit"
            >
              <span classID="btn-inner--text">Publish</span>
            </Button>
          )}
          <Button
            onClick={() => downloadPDF(id)}
            classID="btn-icon btn-3"
            color="warning"
            type="submit"
          >
            <span classID="btn-inner--text">Descargar PDF</span>
          </Button>
          <Button
            onClick={() => navigate("/admin/traceability-reports")}
            classID="btn-icon btn-3"
            color="primary"
            type="button"
          >
            <span classID="btn-inner--text">Cerrar</span>
          </Button>
        </footer>
      </Container>
      <AdminFooter />
    </main>
  );
};
