import {
  BATCH_DETAIL_LIST,
  IS_LOADING_DETAIL_LIST,
} from "../../actions/batchDetail/batchDetailsAction";

const initialState = {
  batchDetailList: null,
  isLoadingBatchDetailList: true,
};

export const batchDetailsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case BATCH_DETAIL_LIST:
      return { ...state, batchDetailList: payload };
    case IS_LOADING_DETAIL_LIST:
      return { ...state, batchDetailDeleted: payload };
    default:
      return state;
  }
};
