import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ListProcessTemplateCategoryDetail } from "src/containers/processTemplateCategoryDetail/ListProcessTemplateCategoryDetail";
import Selector from "../components/Selector";

export const ProcessTemplateCategoryForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  processTemplate,
  dataNewProcessTemplate,
  onFetchMoreProcessTemplate,
}) => {
  const [denoData, setDenoData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    elementData ? setDenoData(elementData.name) : setDenoData("");
  }, [elementData, dataNewProcessTemplate]);

  useEffect(() => {
    if (
      dataNewProcessTemplate !== null &&
      dataNewProcessTemplate !== undefined
    ) {
      navigate(
        `/admin/process-template-category/edit/${dataNewProcessTemplate?.id}`,
        {
          state: {
            change: true,
          },
        }
      );
    }
  }, [dataNewProcessTemplate]);

  const formik = useFormik({
    initialValues: {
      processTemplateId: elementData?.processTemplateId ?? "",
      name: elementData?.name ?? "",
      enable: elementData?.enable ?? false,
    },
    onSubmit: async (values) => {
      if (nameForm === "create") {
        create(values);
      } else if (nameForm === "edit") {
        const keys = Object.keys(values);
        for (let key of keys) {
          if (values[key] === "") {
            values[key] = elementData[key];
          }
        }
        update(id, values);
        navigate("/admin/process-template-category", {
          state: {
            change: true,
          },
        });
      }
    },
  });

  const cancelForm = (event) => {
    navigate("/admin/process-template-category");
  };

  return (
    <>
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label htmlFor="processTemplateId">Plantilla de proceso</label>
              <Selector
                items={processTemplate?.data}
                itemsTotalCount={processTemplate?.totalCount}
                onValueChange={(value) =>
                  formik.setFieldValue("processTemplateId", value)
                }
                onFetchMoreData={onFetchMoreProcessTemplate}
                defaultValue={
                  processTemplate?.data?.find(
                    (el) => el.id === elementData?.processTemplateId
                  )?.name
                }
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="name">Denominación</label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={(e) => {
                  setDenoData(e.target.value);
                  formik.setFieldValue("name", e.target.value);
                }}
                value={denoData}
                required={!elementData}
              />
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-checkbox space-left2">
                <Input
                  type="checkbox"
                  name="enable"
                  onChange={formik.handleChange}
                  value={formik.values.enable}
                  defaultChecked={elementData?.enable}
                />
                <label htmlFor="enable">Habilitado</label>
              </div>
            </FormGroup>
            <Button classID="btn-icon btn-3" color="primary" type="submit">
              <span classID="btn-inner--text">Guardar</span>
            </Button>
            <Button
              classID="btn-icon btn-3"
              color="warning"
              type="button"
              onClick={(e) => cancelForm(e)}
            >
              <span classID="btn-inner--text">Cancelar</span>
            </Button>
          </Form>
        </CardBody>
      </Card>
      <Card>
        {id && (
          <ListProcessTemplateCategoryDetail
            nameForm={nameForm}
            categoryId={id}
          />
        )}
      </Card>
    </>
  );
};
