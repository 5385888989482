/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataLotes } from "src/variables/lotes.js";
import { Container } from "reactstrap";
import moment from "moment";
import { LoadingComponent } from "src/components/Loading/LoadingComponent.js";
import { getBatchesApi } from "src/infrastructure/api/api-batches.js";
import { ProductsAPI } from "src/infrastructure/api/api-products.js";
import { StorageUnitAPI } from "src/infrastructure/api/api-storageUnit.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
  expand: "product, storageUnit",
};

export const Batches = () => {
  const [lotes, setLotes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(lotes?.totalCount / queryParams.limit);
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  const getLotes = (params) => {
    getBatchesApi(params)
      .then(({ data }) => {
        const listFormatted = data.data?.map((item) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        }));

        setLotes({
          ...data,
          data: listFormatted,
        });
      })
      .catch((e) => e)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getLotes(queryParams);
  }, [queryParams]);

  return (
    <main className="flex-fill d-flex flex-column">
      <SimpleHeader name="Lotes" parentName="Admin" />
      <Container className="flex-fill d-flex flex-column mt-4" fluid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Lotes"
            dataTable={lotes?.data ?? []}
            columnsTable={dataLotes.columns}
            metadata={metadata}
            onPageChange={onPageChange}
            filtersComponent={
              <LotesFilters
                batchNumber={queryParams.batchNumber}
                onBatchNumberChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    batchNumber: e.target.value,
                  });
                }}
                onProductNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    productId: e.target.value,
                  });
                }}
                onStorageUnitChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    storageUnitId: e.target.value,
                  });
                }}
                onFromDateChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    fromDate: e.target.value,
                  });
                }}
                onToDateChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    toDate: e.target.value,
                  });
                }}
              />
            }
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const LotesFilters = ({
  batchNumber,
  onBatchNumberChange,
  onProductNameChange,
  onStorageUnitChange,
  onFromDateChange,
  onToDateChange,
}) => {
  const { getStorageUnitApi } = new StorageUnitAPI();
  const { getProductsApi } = new ProductsAPI();
  const [products, setProducts] = useState([]);
  const [storageUnits, setStorageUnits] = useState([]);

  const getProducts = () => {
    getProductsApi().then((result) => {
      setProducts(result.data.data);
    });
  };

  const getStorateUnits = () => {
    getStorageUnitApi().then((result) => {
      setStorageUnits(result.data.data);
    });
  };

  useEffect(() => {
    getProducts();
    getStorateUnits();
  }, []);

  return (
    <div className="col-12 d-flex justify-content-between">
      <input
        className="form-control"
        placeholder="Número de lote"
        type="batchNumber"
        value={batchNumber ?? ""}
        onChange={onBatchNumberChange}
      />
      <select
        name="localidad"
        className="ml-4 form-control"
        onChange={onProductNameChange}
      >
        <option value="">Producto</option>
        {products.map((l) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>
      <select
        name="Unidad de Almacenamiento"
        className="ml-4 form-control"
        onChange={onStorageUnitChange}
      >
        <option value="">Unidad de Almacenamiento</option>
        {storageUnits.map((l) => (
          <option key={l.id} value={l.id}>
            {l.number}
          </option>
        ))}
      </select>
      <div className="ml-4">
        <input
          className="form-control"
          type="date"
          id="start"
          name="fecha desde"
          onChange={onFromDateChange}
        />
      </div>
      <input
        className="ml-4 form-control"
        type="date"
        id="end"
        name="fecha hasta"
        onChange={onToDateChange}
      />
    </div>
  );
};
