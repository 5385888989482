import React from "react";
import { Button, Card, Container } from "reactstrap";
import { CrudTable } from "../tables/CrudTable.js";
import { dataCategoryDetail } from "src/variables/CategoryDetail";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";

export const ProcessTemplateCategoryDetail = ({
  loading,
  processTemplateCategoryDetail,
  toggleModal,
  processTemplateCategoryDetailToDelete,
}) => {
  const dataTableFormatted = React.useMemo(() => {
    if (processTemplateCategoryDetail) {
      const final = processTemplateCategoryDetail.map((category) => {
        return {
          ...category,
          enable: category.enable ? "SI" : "NO",
        };
      });
      return final;
    }
    return [];
  }, [processTemplateCategoryDetail]);

  return (
    <>
      <Card id="detailTable">
        <Container className="mt-4" fluid>
          <Button
            className="mb-3"
            classID="btn-icon btn-3"
            color="primary"
            onClick={toggleModal}
          >
            <span classID="btn-inner--text">Agregar detalle</span>
          </Button>
          <section style={{ height: 500 }}>
            {!loading ? (
              <CrudTable
                name="Detalle de categoria"
                dataTable={dataTableFormatted}
                columnsTable={dataCategoryDetail.columns}
                deleteElement={processTemplateCategoryDetailToDelete}
                toggleModal={toggleModal}
              />
            ) : (
              <LoadingComponent />
            )}
          </section>
        </Container>
      </Card>
    </>
  );
};
