import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataPlantillaProceso } from "src/variables/PlantillaProceso";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";

let offset = 0;
const limit = 10;

const initQueryParams = {
  offset,
  limit,
};

export const ProcessTemplate = ({
  getProcessTemplates,
  deleteProcessTemplates,
  resetErrors,
  loading,
  processTemplateList,
  processTemplateFound,
  processTemplateDeleted,
  processTemplateCreated,
  processTemplateUpdated,
  processTemplateErrors,
}) => {
  const [alert, setAlert] = useState();
  const totalPages = Math.ceil(processTemplateList.totalCount / limit);

  const metadata = {
    totalPages,
    page: offset / limit + 1,
  };

  const onPageChange = (newPage) => {
    offset = ((newPage || 1) - 1) * limit;
    getProcessTemplates({
      offset,
      limit,
    });
  };

  useEffect(() => {
    getProcessTemplates(initQueryParams);
  }, [
    processTemplateFound,
    processTemplateCreated,
    processTemplateUpdated,
    processTemplateDeleted,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    processTemplateErrors ? setAlert(true) : setAlert(false);
  }, [processTemplateErrors]);

  useEffect(() => () => (offset = 0), []);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={processTemplateErrors} state={alert} />
      <SimpleHeader
        name="Plantilla Proceso"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Plantilla Proceso"
            dataTable={processTemplateList?.data ?? []}
            columnsTable={dataPlantillaProceso.columns}
            deleteElement={deleteProcessTemplates}
            metadata={metadata}
            onPageChange={onPageChange}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
