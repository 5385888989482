import {
  CREATE_PRODUCTS,
  SET_PRODUCTS_LIST,
  SET_PRODUCTS_FOUND,
  DELETE_PRODUCTS,
  PRODUCTS_ERRORS,
  IS_LOADING_PRODUCTS,
} from "../../actions/products/productsAction";

const initialState = {
  productsCreated: null,
  productsList: null,
  productsFound: null,
  productsDeleted: null,
  productsErrors: null,
  isLoadingProducts: true,
};

export const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_PRODUCTS:
      return { ...state, productsCreated: payload };
    case SET_PRODUCTS_LIST:
      return { ...state, productsList: payload };
    case SET_PRODUCTS_FOUND:
      return { ...state, productsFound: payload };
    case DELETE_PRODUCTS:
      return { ...state, productsDeleted: payload };
    case PRODUCTS_ERRORS:
      return { ...state, productsErrors: payload };
    case IS_LOADING_PRODUCTS:
      return { ...state, isLoadingProducts: payload };
    default:
      return state;
  }
};
