import { MovementsAPI } from 'src/infrastructure/api/api-movements';
import { fetchStopLoading, fetchStartLoading } from '../loading/loadingAction';
import { setErrorMessages } from '../errors/errorsAction';

//#region Movements Const
export const CREATE_MOVEMENTS = 'CREATE_MOVEMENTS'
export const GET_MOVEMENTS_LIST = 'GET_MOVEMENTS_LIST'
export const GET_MOVEMENTS_ID = 'GET_MOVEMENTS_ID'
export const UPDATE_MOVEMENTS = 'UPDATE_MOVEMENTS'
export const DELETE_MOVEMENTS = 'DELETE_MOVEMENTS'
export const APPROVE_MOVEMENTS = 'APPROVE_MOVEMENTS'
export const MOVEMENTS_ERRORS = 'MOVEMENTS_ERRORS'
//#endregion

//#region Movements Actions

export const fetchCreateMovements = response => ({
    type: CREATE_MOVEMENTS,
    payload: response.data
})

export const fetchGetMovementsList = response => ({
    type: GET_MOVEMENTS_LIST,
    payload: response
})

export const fetchMovementsById = response => ({
    type: GET_MOVEMENTS_ID,
    payload: response
})

export const fetchUpdateMovements = response => ({
    type: UPDATE_MOVEMENTS,
    payload: response
})

export const fetchDeleteMovements = response => ({
    type: DELETE_MOVEMENTS,
    payload: response
})

export const fetchApproveMovements = response => ({
    type: APPROVE_MOVEMENTS,
    payload: response
})

//#endregion

const movementsAPI = new MovementsAPI()

//#region Movements Dispach

// Dispatch create movements
export const createMovements = (movementsBody) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchCreateMovements(await movementsAPI.createMovementsApi(movementsBody)));
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get movements list
export const getMovementsList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchGetMovementsList(await movementsAPI.getMovementsApi(specification)))
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get movements by id
export const getMovementsById = (id, expand) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchMovementsById(await movementsAPI.getMovementsByIdApi(id, expand)))
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch update movements
export const updateMovements = (id, body) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchUpdateMovements(await movementsAPI.updateMovementsApi(id, body)))
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch delete movements
export const deleteMovements = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchDeleteMovements(await movementsAPI.deleteMovementsApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch approve movements
export const approveMovements = (id) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchApproveMovements(await movementsAPI.approveMovementsApi(id)))
    } catch (error) {
        dispatch(setErrorMessages(MOVEMENTS_ERRORS, error))
    } finally {
        dispatch(fetchStopLoading())
    }
}
//#endregion
