import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ProviderUpdate } from 'src/views/pages/providers/ProviderUpdate'
import { getProviderById, updateProvider } from 'src/infrastructure/redux/store/actions/provider/providersAction';
import { getLocalitiesList } from 'src/infrastructure/redux/store/actions/locality/localityAction';
import { getProvinceById } from 'src/infrastructure/redux/store/actions/provinces/provincesAction';
import { getCountriesList } from 'src/infrastructure/redux/store/actions/countries/countriesAction';
import { getLocalityById } from 'src/infrastructure/redux/store/actions/locality/localityAction';
import { getProviderTypeList } from 'src/infrastructure/redux/store/actions/providerType/providerTypeAction';

export const UpdateProvider = () => {

    const dispatch = useDispatch();

    const providerById = useCallback(async (id) => {

        const expand = "province, locality, country"

        await dispatch(getProviderById(id, expand));
    }, [dispatch]);

    const update = useCallback(async (id, body) => {
        await dispatch(updateProvider(id, body));
    }, [dispatch]);

    const providerFound = useSelector(store => store.provider.providerFound)

    const getLocalities = useCallback(async (specification) => {
        await dispatch(getLocalitiesList(specification));
    }, [dispatch]);

    const getLocality = useCallback(async (specification) => {
        await dispatch(getLocalityById(specification));
    }, [dispatch]);

    const getCountriess = useCallback(async () => {
        await dispatch(getCountriesList());
    }, [dispatch]);

    const getProvincesByID = useCallback(async (id) => {
        await dispatch(getProvinceById(id));
    }, [dispatch])

    useEffect(() => {
        getCountriess()
    }, [])

    const getProviderType = useCallback(async (specification) => {
        await dispatch(getProviderTypeList(specification));
    }, [dispatch]);

    const providersType = useSelector(store => store.providerType.providerTypeList)

    const localitiesList = useSelector(store => store.locality.localityList)
    const getCountries = useSelector(store => store.countries.countriesList)
    const getProvinces = useSelector(store => store.provinces.provincesFound)
    const localitySelected = useSelector(store => store.locality.localityFound)
    const providerTypeList = useSelector(store => store.providerType.providerTypeList)

    return <ProviderUpdate
        providerById={providerById}
        providerFound={providerFound}
        update={update}
        getLocalities={getLocalities}
        localitiesList={localitiesList}
        localitySelected={localitySelected}
        getProvinces={getProvinces}
        getCountries={getCountries}
        getLocality={getLocality}
        getProvincesByID={getProvincesByID}
        getProviderType={getProviderType}
        providersTypeList={providersType}
    />
}