import {
  CREATE_PRODUCT_TYPE,
  PRODUCT_TYPE_ID,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  PRODUCT_TYPE_ERRORS,
  PRODUCT_TYPE_LIST,
} from "../../actions/productType/productTypeAction";

const initialState = {
  ProductTypeCreated: null,
  ProductTypeList: null,
  ProductTypeFound: null,
  ProductTypeUpdated: null,
  ProductTypeDeleted: null,
  ProductTypeErrors: null,
};

export const productTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_PRODUCT_TYPE:
      return { ...state, ProductTypeCreated: payload };
    case PRODUCT_TYPE_LIST:
      return { ...state, ProductTypeList: payload };
    case PRODUCT_TYPE_ID:
      return { ...state, ProductTypeFound: payload.data };
    case UPDATE_PRODUCT_TYPE:
      return { ...state, ProductTypeUpdated: payload };
    case DELETE_PRODUCT_TYPE:
      return { ...state, ProductTypeDeleted: payload };
    case PRODUCT_TYPE_ERRORS:
      return { ...state, productTypeErrors: payload };
    default:
      return state;
  }
};
