import { APPROVE_MOVEMENTS, CREATE_MOVEMENTS, GET_MOVEMENTS_LIST, GET_MOVEMENTS_ID, UPDATE_MOVEMENTS, DELETE_MOVEMENTS, MOVEMENTS_ERRORS } from "../../actions/movements/movementsAction"

const initialState = {
    MovementsCreated: null,
    MovementsList: null,
    MovementsFound: null,
    MovementsUpdated: null,
    MovementsDeleted: null,
    MovementsApproved: null,
    MovementsErrors: null
}

export const movementsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_MOVEMENTS:
            return { ...state, MovementsCreated: payload }
        case GET_MOVEMENTS_LIST:
            return { ...state, MovementsList: payload.data }
        case GET_MOVEMENTS_ID:
            return { ...state, MovementsFound: payload.data }
        case UPDATE_MOVEMENTS:
            return { ...state, MovementsUpdated: payload }
        case DELETE_MOVEMENTS:
            return { ...state, MovementsDeleted: payload }
        case APPROVE_MOVEMENTS:
            return { ...state, MovementsApproved: payload }
        case MOVEMENTS_ERRORS:
            return { ...state, MovementsErrors: payload }
        default:
            return state
    }
}