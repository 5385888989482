export const dataUsers = {
    columns : [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            dataField: "enable",
            text: "Habilitado",
            sort: true,
        },
        {
            dataField: "name",
            text: "Nombre",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Apellido",
            sort: true,
        },
        {
            dataField: "identificationNumber",
            text: "Legajo",
            sort: true,
        }
    ]
};