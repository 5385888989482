import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanProcessFound,
  getProcessById,
} from "src/infrastructure/redux/store/actions/process/processAction";
import {
  cleanProcessTemplateFound,
  getProcessTemplateById,
} from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction";
import { updateProcess } from "src/infrastructure/redux/store/actions/process/processAction";
import { ProcessUpdate } from "src/views/pages/process/ProcessUpdate";
import {
  cleanProviderList,
  getProviderList,
} from "src/infrastructure/redux/store/actions/provider/providersAction";
import {
  cleanClientList,
  getClientList,
} from "src/infrastructure/redux/store/actions/client/clientAction";
import {
  cleanProductList,
  getProductsList,
} from "src/infrastructure/redux/store/actions/products/productsAction";
import {
  getStorageUnitList,
  getStorageUnitLastMovement,
  cleanStorageUnitList,
} from "src/infrastructure/redux/store/actions/storageUnit/storageUnitAction";
import { ProcessView } from "src/views/pages/process/ProcessView";

export const ViewProcess = () => {
  const dispatch = useDispatch();

  const processById = useCallback(
    async (idProcess) => {
      const expand = "processTemplate, provider, client, product, storageUnit.unitOfMeasurement, destinationStorageUnit"
      await dispatch(getProcessById(idProcess, expand));
    },
    [dispatch]
  );

  const processFound = useSelector((store) => store.process.processFound);

  const processTemplateById = useCallback(
    async (idProcessTemplate) => {
      await dispatch(getProcessTemplateById(idProcessTemplate));
    },
    [dispatch]
  );

  const processTemplateFound = useSelector(
    (store) => store.processTemplate.processTemplateFound
  );

  const update = useCallback(
    async (id, body) => {
      await dispatch(updateProcess(id, body));
    },
    [dispatch]
  );

  const selectForms = {
    validateValues: () => {
      console.log(this.ProvidersList);
    },
    getProviders: useCallback(
      async (specification) => {
        await dispatch(getProviderList(specification));
      },
      [dispatch]
    ),
    getClient: useCallback(
      async (specification) => {
        await dispatch(getClientList(specification));
      },
      [dispatch]
    ),
    getProduct: useCallback(
      async (specification) => {
        await dispatch(getProductsList(specification));
      },
      [dispatch]
    ),
    getStorageUnit: useCallback(
      async (specification) => {
        await dispatch(getStorageUnitList(specification));
      },
      [dispatch]
    ),
    getStorageUnitLastMovement: useCallback(
      async (specification) => {
        await dispatch(getStorageUnitLastMovement(specification));
      },
      [dispatch]
    ),
    ProvidersList: useSelector((store) => store.provider.providerList),
    ClientList: useSelector((store) => store.client.clientList),
    ProductList: useSelector((store) => store.products.productsList),
    StorageUnitList: useSelector((store) => store.storageUnit.StorageUnitList),
    StorageUnitLastMovement: useSelector(
      (store) => store.storageUnit.StorageUnitLastMovement
    ),
  };

  useEffect(() => {
    return () => {
      dispatch(cleanProcessFound());
      dispatch(cleanProcessTemplateFound());
      dispatch(cleanProviderList());
      dispatch(cleanClientList());
      dispatch(cleanProductList());
      dispatch(cleanStorageUnitList());
    };
  }, []);

  return (
    <ProcessView
      processById={processById}
      processFound={processFound}
      getProcessTemplateById={processTemplateById}
      processTemplateFound={processTemplateFound}
      updateProcess={update}
      selectData={selectForms}
    />
  );
};
