import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleHeader from "src/components/Headers/SimpleHeader";
import { fetchGetConfiguration } from "src/infrastructure/redux/store/actions/configuration/configurationAction";
import { ConfigurationForm } from "./ConfigurationForm";
import { LoadingComponent } from "src/components/Loading/LoadingComponent";
import { Card, Container } from "reactstrap";
import AdminFooter from "src/components/Footers/AdminFooter";

export const Configuration = () => {
  const dispatch = useDispatch();
  const { data: configuration, isLoading } = useSelector(
    (store) => store.configuration
  );

  useEffect(() => {
    dispatch(fetchGetConfiguration());
  }, []);

  return (
    <section className="overflow-auto">
      <SimpleHeader name="Configuración" parentName="Admin" />
      <Container fluid className="mt-4">
        {isLoading ? (
          <Card>
            <div
              style={{ height: "304px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <LoadingComponent />
            </div>
          </Card>
        ) : (
          <ConfigurationForm defaultData={configuration} />
        )}
      </Container>
      <AdminFooter />
    </section>
  );
};
