import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent.js";
import { Alerts } from "src/components/Alerts/Alerts.js";
import AdminFooter from "src/components/Footers/AdminFooter.js";
import { dataBatchNumbers } from "src/variables/batchNumbers.js";

const initQueryParams = {
  offset: 0,
  limit: 10,
};

export const BatchNumbers = ({
  getBatchNumbers,
  deleteBatchNumber,
  resetErrors,
  loading,
  batchNumbersList,
  batchNumbersCreated,
  batchNumbersUpdated,
  batchNumbersDeleted,
  batchNumbersFound,
  batchNumbersErrors,
}) => {
  const [alert, setAlert] = useState();
  const [queryParams, setQueryParams] = useState(initQueryParams);
  const totalPages = Math.ceil(
    batchNumbersList?.totalCount / queryParams.limit
  );
  const metadata = {
    totalPages,
    page: queryParams.offset / queryParams.limit + 1,
  };

  const onPageChange = (newPage) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: ((newPage || 1) - 1) * state.limit,
    }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBatchNumbers(queryParams);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [
    batchNumbersCreated,
    batchNumbersUpdated,
    batchNumbersFound,
    batchNumbersDeleted,
    queryParams,
  ]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    batchNumbersErrors ? setAlert(true) : setAlert(false);
  }, [batchNumbersErrors]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={batchNumbersErrors} state={alert} />
      <SimpleHeader
        name="Números de Lote"
        parentName="Admin"
        create="create"
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Números de Lote"
            dataTable={batchNumbersList?.data ?? []}
            columnsTable={dataBatchNumbers.columns}
            deleteElement={deleteBatchNumber}
            metadata={metadata}
            onPageChange={onPageChange}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};
