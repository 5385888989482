import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProductTypeAPI {

    createProductTypeApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/products-type`, options)

            return response

        } catch (error) {
            console.log('api-productType:18 => Error: ', error)
            throw (error)
        }
    };

    getProductTypesApi = async (specification = {}) => {
        try {
            let url = new URL(`${BASE_URL}/products-type`);
            url.searchParams.append("deletedAt", "null");
            Object.entries(specification).forEach(([key, value]) => {
                if (!value) return;
                url.searchParams.append(key, value);
            })

            const options = {
                method: 'GET',
                headers: { ...headers, authorization: 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url.toString(), options)
            return response

        } catch (error) {
            console.log('api-productType:35 => Error: ', error)
            throw (error)
        }
    }

    getProductTypeByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, authorization: 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/products-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-productType:51 => Error: ', error)
            throw (error)
        }
    }

    updateProductTypeApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, authorization: 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/products-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-productType:70 => Error: ', error)
            throw (error)
        }
    };

    deleteProductTypeApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, authorization: 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/products-type/${id}`, options)

            return response

        } catch (error) {
            console.log('api-productType:87 => Error: ', error)
            throw (error)
        }
    }

}


