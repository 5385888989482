export const dataProceso = {
  columns: [
    {
      dataField: "processTemplate.name",
      text: "Nombre de la plantilla",
      sort: true,
    },
    {
      dataField: "name",
      text: "Denominacion",
      sort: true,
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "product.name",
      text: "Nombre de Producto",
      sort: true,
    },
    {
      dataField: "storageUnit.number",
      text: "Unid de Almacenamiento",
      sort: true,
    },
    {
      dataField: "increases",
      text: "Incrementa",
      sort: true,
    },
    {
      dataField: "decreases",
      text: "Disminuye",
      sort: true,
    },
  ],
};
