import { SET_ROLES_LIST, SET_ROLES_ID } from "../../actions/roles/rolesAction";

const initialState = {
  RolesList: [],
  RolesFound: null,
};

export const rolesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ROLES_LIST:
      return { ...state, RolesList: payload };
    case SET_ROLES_ID:
      return { ...state, RolesFound: payload.data };
    default:
      return state;
  }
};
