import React from "react";
import SimpleHeader from "src/components/Headers/SimpleHeader.js";
import { Container } from "reactstrap";
import { UnitOfMeasurementForm } from "src/views/pages/unitOfMeasurement/UnitOfMeasurementForm";
import AdminFooter from "src/components/Footers/AdminFooter";

export const UnitOfMeasurementCreate = ({ create }) => {
  return (
    <main className="overflow-auto">
      <SimpleHeader name="Unidad de medida" parentName="Admin"></SimpleHeader>
      <Container fluid>
        <h2>{"Agregar Unidad de medida"}</h2>
        <UnitOfMeasurementForm
          elementData={null}
          nameForm={"create"}
          id={null}
          create={create}
          update={null}
        />
      </Container>
      <AdminFooter />
    </main>
  );
};
