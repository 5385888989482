import classNames from "classnames";
import { useEffect, useState } from "react";

const pageFramgeLimit = 3;
let pagesFrame = null;
let totalPagesCache = null;

export function usePagination({
  totalPages,
  onPageChange,
  page,
}) {
  const [_, setRerender] = useState(0);

  const triggerRerender = () => setRerender(s => s + 1);

  const onClickNext = () => {
    const nextPage = page + 1;
    onPageChange(nextPage)

    if (page === pagesFrame.at(-1)) {
      pagesFrame = [...pagesFrame.slice(1), nextPage];
      triggerRerender()
    }
  }

  const onClickPrev = () => {
    const prevPage = page - 1;
    onPageChange(prevPage)

    if (page === pagesFrame[0]) {
      pagesFrame = [prevPage, ...pagesFrame.slice(0, -1)];
      triggerRerender()
    }
  }

  useEffect(() => {
    if (totalPagesCache === totalPages) return;

    if (totalPages > pageFramgeLimit) {
      pagesFrame = Array.from({ length: pageFramgeLimit }, (_, i) => i + 1);
    } else {
      pagesFrame = Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    triggerRerender()
  }, [totalPages]);

  if (!totalPages || totalPages === NaN || !onPageChange) {
    return { ComponentPagination: () => null };
  }

  const ComponentPagination = () => {
    return (
      <section style={{ margin: '0 28px 0 auto', width: 'fit-content', display: 'block' }}>
        {page !== 1 && (
          <button
            className="btn btn-secondary btn-xs"
            onClick={onClickPrev}
          >
            {`<`}
          </button>
        )}
        {pagesFrame?.map((num) => (
          <button
            className={classNames(
              "btn btn-xs mr-1",
              num === page
                ? "btn-primary"
                : "btn-secondary",
            )}
            key={num}
            onClick={() => {
              onPageChange(num);
            }}
          >
            {num}
          </button>
        ))}
        {page !== totalPages && (
          <button
            className="btn btn-secondary btn-xs"
            onClick={onClickNext}
          >
            {`>`}
          </button>
        )}

      </section>
    );
  };

  return { ComponentPagination };
}
