import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsCreate } from "src/views/pages/products/ProductsCreate";
import { createProducts } from 'src/infrastructure/redux/store/actions/products/productsAction';
import { getProductTypeList } from 'src/infrastructure/redux/store/actions/productType/productTypeAction';
import { getUnitOfMeasurementList } from 'src/infrastructure/redux/store/actions/unitOfMeasurement/unitOfMeasurementAction';

export const CreateProducts = () => {

    const dispatch = useDispatch();

    const create = useCallback(async (body) => {
        await dispatch(createProducts(body));
    }, [dispatch]);

    const getProductsType = useCallback(async (specification) => {
        await dispatch(getProductTypeList(specification));
    }, [dispatch]);

    const productTypesList = useSelector(store => store.productType.ProductTypeList)

    const getUnitOfMeasurement = useCallback(async (specification) => {
        await dispatch(getUnitOfMeasurementList(specification));
    }, [dispatch]);

    const unitOfMeasurementsList = useSelector(store => store.unitOfMeasurement.UnitOfMeasurementList)

    return (
        <ProductsCreate
            create={create}
            getProductsType={getProductsType}
            productTypesList={productTypesList}
            getUnitOfMeasurement={getUnitOfMeasurement}
            unitOfMeasurementsList={unitOfMeasurementsList}
        />
    )

}

