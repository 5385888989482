import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanBatchNumberList,
  deleteBatchNumber,
  getBatchNumbersList,
} from "src/infrastructure/redux/store/actions/batchNumbers/batchNumbersAction";
// import { BatchNumber } from "src/views/pages/batchNumbers/BatchNumber";
import { resetErrorMessages } from "src/infrastructure/redux/store/actions/errors/errorsAction";
import { BatchNumbers } from "src/views/pages/batchNumbers/BatchNumbers";

export const ListBatchNumbers = () => {
  const dispatch = useDispatch();

  const getBatchNumbers = useCallback(
    async (specification) => {
      await dispatch(getBatchNumbersList(specification));
    },
    [dispatch]
  );

  const batchNumberToDelete = useCallback(
    async (id) => {
      await dispatch(deleteBatchNumber(id));
      await getBatchNumbers();
    },
    [dispatch]
  );

  const handleCleanBatchNumberList = useCallback(async () => {
    await dispatch(cleanBatchNumberList());
  }, [dispatch]);

  const resetErrors = useCallback(async () => {
    await dispatch(resetErrorMessages("PROVIDER_ERRORS"));
  }, [dispatch]);

  const batchNumbersList = useSelector(
    (store) => store.batchNumbers.batchNumbersList
  );
  const batchNumberCreated = useSelector(
    (store) => store.batchNumbers.batchNumberCreated
  );
  const batchNumberUpdated = useSelector(
    (store) => store.batchNumbers.batchNumberUpdated
  );
  const batchNumberDeleted = useSelector(
    (store) => store.batchNumbers.batchNumberDeleted
  );
  const batchNumberFound = useSelector(
    (store) => store.batchNumbers.batchNumberFound
  );
  const batchNumberErrors = useSelector(
    (store) => store.errors.batchNumberErrors
  );
  const loading = useSelector((store) => store.batchNumbers.loading);

  useEffect(() => {
    return handleCleanBatchNumberList;
  }, []);

  return (
    <BatchNumbers
      getBatchNumbers={getBatchNumbers}
      deleteBatchNumber={batchNumberToDelete}
      resetErrors={resetErrors}
      loading={loading}
      batchNumbersList={batchNumbersList}
      batchNumberCreated={batchNumberCreated}
      batchNumberUpdated={batchNumberUpdated}
      batchNumberDeleted={batchNumberDeleted}
      batchNumberFound={batchNumberFound}
      batchNumberErrors={batchNumberErrors}
    />
  );
};
