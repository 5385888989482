import React, { useCallback } from 'react'
import { PasswordRecoveryApi } from 'src/infrastructure/redux/store/actions/authentication/authAction'
import { PasswordRecovery } from 'src/views/pages/userPages/PasswordRecovery'
import { useDispatch } from 'react-redux'

export const AuthRecoverPassword = () => {

    const dispatch = useDispatch()

    const passwordRecovery = useCallback(async (body) => {
        await dispatch(PasswordRecoveryApi(body));
    }, [dispatch]);

    return <PasswordRecovery
        recovery={passwordRecovery}
    />
}