import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import Selector from "../components/Selector";
import { traceabilityReportTypes } from "src/variables/traceabilityReports";

export const TraceabilityReportsForm = ({
  elementData,
  nameForm,
  id,
  create,
  update,
  readOnly = false,
}) => {
  const [Loading, setLoading] = useState();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      type: elementData?.type ?? traceabilityReportTypes[0].id,
      participants: elementData?.participants ?? "",
      transferNumber: elementData?.transferNumber ?? "",
      publish: false,
      batchNumber: elementData?.batchNumber ?? "",
    },
    onSubmit: (values) => {
      if (nameForm === "create") {
        create(values);
        setLoading(false);
        if (!Loading) {
          navigate("/admin/traceability-reports");
        }
      } else if (nameForm === "update") {
        update(id, values);
        navigate("/admin/traceability-reports");
      }
    },
  });

  const cancelForm = (e) => {
    navigate("/admin/traceability-reports");
  };

  const isDescendent = formik.values.type === 1;

  return (
    <Card>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <label htmlFor="type">Tipo</label>
            {readOnly ? (
              <p>
                {
                  traceabilityReportTypes.find((l) => l.id === elementData.type)
                    ?.name
                }
              </p>
            ) : (
              <Selector
                items={traceabilityReportTypes}
                defaultValue={
                  traceabilityReportTypes.find(
                    (l) => l.id === formik.values.type
                  )?.name
                }
                onValueChange={(v) => formik.setFieldValue("type", v)}
                itemsTotalCount={2}
              />
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="participants">Participantes</label>
            {readOnly ? (
              <p>{formik.values.participants}</p>
            ) : (
              <Input
                id="participants"
                onChange={formik.handleChange}
                rows="3"
                type="textarea"
                value={formik.values.participants}
              />
            )}
          </FormGroup>
          {!isDescendent && (
            <FormGroup>
              <label htmlFor="batchNumber">Número de Lote</label>
              {readOnly ? (
                <p>{formik.values.batchNumber}</p>
              ) : (
                <Input
                  id="batchNumber"
                  name="batchNumber"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.batchNumber}
                  required
                />
              )}
            </FormGroup>
          )}
          {isDescendent && (
            <FormGroup>
              <label htmlFor="transferNumber">Número de Traslado</label>
              {readOnly ? (
                <p>{formik.values.transferNumber}</p>
              ) : (
                <Input
                  id="transferNumber"
                  name="transferNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.transferNumber}
                  required
                />
              )}
            </FormGroup>
          )}
          {!readOnly && (
            <>
              <Button classID="btn-icon btn-3" color="primary" type="submit">
                <span classID="btn-inner--text">Guardar</span>
              </Button>
              <Button
                classID="btn-icon btn-3"
                color="warning"
                type="button"
                onClick={(e) => cancelForm(e)}
              >
                <span classID="btn-inner--text">Cancelar</span>
              </Button>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};
