import { ClientAPI } from "src/infrastructure/api/api-client";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";
import { setErrorMessages } from "../errors/errorsAction";

//#region Client Const
export const CREATE_CLIENT = "CREATE_CLIENT";
export const SET_CLIENT_LIST = "SET_CLIENT_LIST";
export const SET_CLIENT_FOUND = "SET_CLIENT_FOUND";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const CLIENT_ERRORS = "CLIENT_ERRORS";
//#endregion

//#region Client Actions

export const fetchCreateClient = (response) => ({
  type: CREATE_CLIENT,
  payload: response.data,
});

export const setClientList = (payload) => ({
  type: SET_CLIENT_LIST,
  payload,
});

export const setClientFound = (response) => ({
  type: SET_CLIENT_FOUND,
  payload: response,
});

export const fetchUpdateClient = (response) => ({
  type: UPDATE_CLIENT,
  payload: response,
});

export const fetchDeleteClient = (response) => ({
  type: DELETE_CLIENT,
  payload: response,
});

//#endregion

const clientAPI = new ClientAPI();

//#region Clients Dispach

// Dispatch create client
export const createClient = (clientBody) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchCreateClient(await clientAPI.createClientApi(clientBody)));
  } catch (error) {
    dispatch(setErrorMessages(CLIENT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
    console.log("finally");
  }
};

// Dispatch get client list
export const getClientList = (specification) => async (dispatch) => {
  try {
    // dispatch(fetchStartLoading())
    const response = await clientAPI.getClientsApi(specification);
    dispatch(setClientList(response.data));
  } catch (error) {
    dispatch(setErrorMessages(CLIENT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanClientList = () => async (dispatch) => {
  dispatch(setClientList(null));
};

// Dispatch get client by id
export const getClientById = (id, expand) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    const response = await clientAPI.getClientByIdApi(id, expand);
    dispatch(setClientFound(response.data));
  } catch (error) {
    dispatch(setErrorMessages(CLIENT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

export const cleanClientFound = () => async (dispatch) => {
  dispatch(setClientFound(null));
};

// Dispatch update client
export const updateClient = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchUpdateClient(await clientAPI.updateClientApi(id, body)));
  } catch (error) {
    dispatch(setErrorMessages(CLIENT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};

// Dispatch delete client
export const deleteClient = (id) => async (dispatch) => {
  try {
    dispatch(fetchStartLoading());
    dispatch(fetchDeleteClient(await clientAPI.deleteClientApi(id)));
  } catch (error) {
    dispatch(setErrorMessages(CLIENT_ERRORS, error));
  } finally {
    dispatch(fetchStopLoading());
  }
};
//#endregion
