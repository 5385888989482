import {
  CREATE_PROCESS_TEMPLATE,
  GET_PROCESS_TEMPLATE_LIST,
  SET_PROCESS_TEMPLATE_FOUND,
  UPDATE_PROCESS_TEMPLATE,
  DELETE_PROCESS_TEMPLATE,
  PROCESS_TEMPLATE_ERRORS,
  IS_LOADING_PROCESS_TEMPLATE,
} from "../../actions/processTemplate/processTemplateAction";

const initialState = {
  processTemplateCreated: null,
  processTemplateList: null,
  processTemplateFound: null,
  processTemplateUpdated: null,
  processTemplateDeleted: null,
  processTemplateErrors: null,
  isLoadingProcessTemplate: true,
};

export const processTemplateReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_PROCESS_TEMPLATE:
      return { ...state, processTemplateCreated: payload };
    case GET_PROCESS_TEMPLATE_LIST:
      return { ...state, processTemplateList: payload };
    case SET_PROCESS_TEMPLATE_FOUND:
      return { ...state, processTemplateFound: payload };
    case UPDATE_PROCESS_TEMPLATE:
      return { ...state, processTemplateUpdated: payload };
    case DELETE_PROCESS_TEMPLATE:
      return { ...state, processTemplateDeleted: payload };
    case PROCESS_TEMPLATE_ERRORS:
      return { ...state, processTemplateErrors: payload };
    case IS_LOADING_PROCESS_TEMPLATE:
      return { ...state, isLoadingProcessTemplate: payload };
    default:
      return state;
  }
};
