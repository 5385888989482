export const batchNumberTypes = [
  { id: "ALF", name: "Alfanumerico" },
  { id: "NASC", name: "Numerico Ascendente" },
  { id: "JUL", name: "Juliano" },
  { id: "DY", name: "(YYYY-mm-dd)" },
  { id: "DN", name: "(dd-mm-YYYY)" },
];

export const dataBatchNumbers = {
  columns: [
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
      formatter: (_, row) => (
        <>{batchNumberTypes.find((l) => l.id === row.type)?.name}</>
      ),
    },
    {
      dataField: "generateByDispatch",
      text: "Generar por Despacho",
      sort: true,
      formatter: (_, row) => <>{row.generateByDispatch ? "Si" : "No"}</>,
    },
    {
      dataField: "subfix",
      text: "Subfijo",
      sort: true,
    },
    {
      dataField: "prefix",
      text: "Prefijo",
      sort: true,
    },
  ],
};
