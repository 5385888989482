import { LocalityAPI } from "src/infrastructure/api/api-localities";
import { fetchStartLoading, fetchStopLoading } from "../loading/loadingAction";

//#region Locality Const
export const CREATE_LOCALITY = 'CREATE_LOCALITY'
export const GET_LOCALITY_LIST = 'GET_LOCALITY_LIST'
export const GET_LOCALITY_ID = 'GET_LOCALITY_ID'
export const UPDATE_LOCALITY = 'UPDATE_LOCALITY'
export const DELETE_LOCALITY = 'DELETE_LOCALITY'
//#endregion

//#region Locality Actions

export const fetchGetLocalitiesList = response => ({
    type: GET_LOCALITY_LIST,
    payload: response.data
})

export const fetchLocalityById = response => ({
    type: GET_LOCALITY_ID,
    payload: response
})

//#endregion

const localityAPI = new LocalityAPI()

//#region Locality Dispach

// Dispatch get locality list
export const getLocalitiesList = (specification) => async (dispatch) => {
    try {
        dispatch(fetchStartLoading())
        dispatch(fetchGetLocalitiesList(await localityAPI.getLocalitiesApi(specification)))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(fetchStopLoading())
    }
}

// Dispatch get locality by id
export const getLocalityById = (id) => async (dispatch) => {
    try {
        dispatch(fetchLocalityById(await localityAPI.getLocalityByIdApi(id)))
    } catch (error) {
        console.log(error)
    } finally {
    }
}

//#endregion
