import { BASE_URL } from 'src/environment/env';
import { sdkAuthRequest } from 'src/infrastructure/request';
import { headers } from 'src/infrastructure/request/utils/headers';
import { getUserToken } from 'src/infrastructure/request/utils/request.helpers';

export class ProcessDetailAPI {

    createProcessDetailApi = async (body) => {
        try {
            const options = {
                method: 'POST',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-detail`, options)

            return response

        } catch (error) {
            console.log('api-processDetail:18 => Error: ', error)
            throw (error)
        }
    };

    getProcessDetailApi = async (specification = {}) => {
        try {
            let url = `${BASE_URL}/process-detail`
            url += `?processId=${(specification).toString()}`
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(url, options)

            return response

        } catch (error) {
            console.log('api-processDetail:35 => Error: ', error)
            throw (error)
        }
    }

    getProcessDetailByIdApi = async (id) => {
        try {
            const options = {
                method: 'GET',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processDetail:51 => Error: ', error)
            throw (error)
        }
    }

    updateProcessDetailApi = async (id, body) => {
        try {
            const options = {
                method: 'PUT',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() },
                data: body
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processDetail:70 => Error: ', error)
            throw (error)
        }
    };

    deleteProcessDetailApi = async (id) => {
        try {
            const options = {
                method: 'DELETE',
                headers: { ...headers, 'authorization': 'Bearer ' + getUserToken() }
            }

            const response = await sdkAuthRequest(`${BASE_URL}/process-detail/${id}`, options)

            return response

        } catch (error) {
            console.log('api-processDetail:87 => Error: ', error)
            throw (error)
        }
    }

}


