import React, { useState, useEffect, useCallback } from "react";
import { Container } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.js";
import { CrudTable } from "../tables/CrudTable.js";
import { dataProceso } from "src/variables/Process";
import { LoadingComponent } from "../../../components/Loading/LoadingComponent";
import { Alerts } from "src/components/Alerts/Alerts.js";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AdminFooter from "src/components/Footers/AdminFooter.js";
import Selector from "../components/Selector.js";
import { useDispatch, useSelector } from "react-redux";
import { getProcessTemplateList } from "src/infrastructure/redux/store/actions/processTemplate/processTemplateAction.js";
import { StorageUnitAPI } from "src/infrastructure/api/api-storageUnit.js";

let offset = 0;
const limit = 10;

const initQueryParams = {
  offset,
  limit,
  expand:
    "processTemplate,provider,client,product,storageUnit,destinationStorageUnit",
};

export const Process = ({
  getProcess,
  deleteProcess,
  resetErrors,
  loading,
  processList,
  processDeleted,
  processFound,
  processUpdated,
  processCreated,
  processErrors,
  approveProcess,
  restoreProcessCreated,
}) => {
  const [alert, setAlert] = useState();
  const totalPages = Math.ceil(processList?.totalCount / limit);
  const [queryParams, setQueryParams] = useState(initQueryParams);

  const metadata = {
    totalPages,
    page: offset / limit + 1,
  };

  const onPageChange = (newPage) => {
    offset = ((newPage || 1) - 1) * limit;
    getProcess({
      offset,
      limit,
      expand:
        "processTemplate,provider,client,product,storageUnit,destinationStorageUnit",
    });
  };

  useEffect(() => {
    restoreProcessCreated();
    getProcess(initQueryParams);
  }, [processDeleted, processCreated, processFound, processUpdated]);

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    processErrors ? setAlert(true) : setAlert(false);
  }, [processErrors]);

  useEffect(() => () => (offset = 0), []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getProcess(queryParams);
    }, 500);

    return () => clearTimeout(timeout);
  }, [queryParams]);

  return (
    <main className="flex-fill d-flex flex-column">
      <Alerts data={processErrors} state={alert} />
      <SimpleHeader
        name="Proceso"
        parentName="Admin"
        subComponent={<ProcessOptions />}
      ></SimpleHeader>
      <Container className="flex-fill d-flex flex-column" fluid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <CrudTable
            name="Proceso"
            dataTable={processList?.data ?? []}
            columnsTable={dataProceso.columns}
            deleteElement={deleteProcess}
            approve={"approve"}
            approveElement={approveProcess}
            filtersComponent={
              <ProcessFilters
                name={queryParams.name}
                onNameChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    offset: 0,
                    name: e.target.value,
                  });
                }}
                onStorageUnitChange={(value) => {
                  const newState = {
                    ...queryParams,
                    offset: 0,
                    storageUnitId: value,
                  };
                  if (value === null) delete newState.storageUnitId;
                  setQueryParams(newState);
                }}
              />
            }
            metadata={metadata}
            onPageChange={onPageChange}
          />
        )}
      </Container>
      <AdminFooter />
    </main>
  );
};

const ProcessFilters = ({ name, onNameChange, onStorageUnitChange }) => {
  const initialQueryParams = {
    offset: 0,
    limit: 10,
  };
  const { getStorageUnitApi } = new StorageUnitAPI();
  const [storageUnitsResponse, setStorageUnitsResponse] = useState(null);
  const [queryParams, setQueryParams] = useState();

  const getStorateUnits = (params) => {
    getStorageUnitApi(params).then((res) => {
      setStorageUnitsResponse((state) => {
        if (state === null) return res.data;
        return {
          ...state,
          data: [...state.data, ...res.data.data],
        };
      });
    });
  };

  useEffect(() => {
    if (storageUnitsResponse !== null) getStorateUnits(queryParams);
  }, [queryParams]);

  return (
    <div className="col-12 d-flex">
      <input
        className="col-4 form-control"
        placeholder="Nombre"
        type="text"
        value={name ?? ""}
        onChange={onNameChange}
      />
      <Selector
        boxStyle={{ width: "33%", marginLeft: "20px" }}
        items={
          storageUnitsResponse?.data?.map((storageUnit) => ({
            id: storageUnit.id,
            name: storageUnit.number,
          })) ?? []
        }
        itemsTotalCount={storageUnitsResponse?.totalCount ?? 0}
        onFetchMoreData={() => {
          setQueryParams((state) => ({
            ...queryParams,
            offset: state.offset + state.limit,
          }));
        }}
        selectionRemovable
        onValueChange={(id) => onStorageUnitChange(id)}
        handleOpen={() => {
          setStorageUnitsResponse(null);
          getStorateUnits(initialQueryParams);
          setQueryParams(initialQueryParams);
        }}
      />
    </div>
  );
};

function ProcessOptions() {
  const [creationActive, setCreationActive] = useState(false);
  const [processTemplateId, setProcessTemplateId] = useState(null);
  const navigate = useNavigate();

  const goToCreate = useCallback(() => {
    navigate(`create/${processTemplateId}`);
  }, [processTemplateId]);

  const onProcessTemplateIdChange = (id) => {
    setProcessTemplateId(id);
    setCreationActive(true);
  };

  return (
    <>
      <ProcessTemplateSelector onValueChange={onProcessTemplateIdChange} />
      <Button
        className="btn-icon btn-3 d-inline-block my-2"
        color={creationActive ? "primary" : "light"}
        type="button"
        disabled={!creationActive}
        onClick={goToCreate}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-plus" />
        </span>
        <span className="btn-inner--text">Agregar</span>
      </Button>
    </>
  );
}

export const ProcessTemplateSelector = ({ onValueChange }) => {
  const [stackProcessTemplate, setStackProcessTemplate] = useState([]);
  const initialQueryParams = {
    offset: 0,
    limit: 10,
  };
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const dispatch = useDispatch();
  const processTemplateList = useSelector(
    (store) => store.processTemplate.processTemplateList
  );

  const getProcessTemplatesList = useCallback(
    async (specification) => {
      await dispatch(getProcessTemplateList(specification));
    },
    [dispatch]
  );

  const fetchMoreProcessTemplate = (e) => {
    setQueryParams((state) => ({
      ...queryParams,
      offset: state.offset + state.limit,
    }));
  };

  useEffect(() => {
    stackProcessTemplate !== null && getProcessTemplatesList(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.offset === 0) {
      setStackProcessTemplate(processTemplateList?.data);
      return;
    }
    setStackProcessTemplate((state) => [
      ...state,
      ...processTemplateList?.data,
    ]);
  }, [processTemplateList]);

  return (
    <Selector
      items={stackProcessTemplate ?? []}
      onValueChange={onValueChange}
      onFetchMoreData={fetchMoreProcessTemplate}
      itemsTotalCount={processTemplateList?.totalCount ?? 0}
      handleClose={() => {
        setStackProcessTemplate([]);
        setQueryParams(initialQueryParams);
      }}
      handleOpen={() => {
        getProcessTemplatesList(queryParams);
      }}
      boxStyle={{
        display: "inline-block",
        width: "340px",
        marginRight: "15px",
      }}
    />
  );
};
